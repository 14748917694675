import React from 'react';

import { TextField as RATextField } from 'react-admin';

import { Tabela } from '../../../common/Tabela';
import { Padrao } from '../../../common/filtros/Padrao';
import { Data } from '../../../common/filtros/Data';
import { Autocompletar } from '../../../common/filtros/Autocompletar';
import VinculoField from './VinculoField';
import DataField from './DataField';
import AcoesField from './AcoesField';
import PainelExpandivelEnderecoResponsavel from '../../../common/PainelExpandivelEnderecoResponsavel';
import PainelExpandivelObservacaoResponsavel from 'components/common/PainelExpandivelObservacaoResponsavel';

export const vinculoOpcoes = [
	{ id: 'P', name: 'Proprietário' },
	{ id: 'I', name: 'Inquilino' },
];

const PaineisExpandiveis = ({ record }) => {
	const { data_saida, motivo_saida } = record || {};

	return (
		<>
			<div>
				<PainelExpandivelEnderecoResponsavel record={record} />
			</div>
			{data_saida && motivo_saida && (
				<div>
					<PainelExpandivelObservacaoResponsavel record={record} />
				</div>
			)}
		</>
	);
};

const TabelaHistorico = ({ ...props }) => (
	<Tabela {...props} expand={<PaineisExpandiveis />}>
		<VinculoField source='tipo' label='Vínculo' filtro={<Autocompletar opcoes={vinculoOpcoes} />} />
		<RATextField source='nome_responsavel' label='Nome' filtro={<Padrao />} />
		<DataField source='data_entrada' label='Entrada' filtro={<Data custom='data_vigencia_after' />} />
		<DataField source='data_saida' label='Saída' filtro={<Data custom='data_vigencia_before' />} />
		<AcoesField source='acoes' label=' ' align='right' />
	</Tabela>
);

export default TabelaHistorico;
