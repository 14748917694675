import React, { useEffect, useState, useContext, createContext } from 'react';
import { useGetList, useDataProvider, useRedirect, useUpdate, useNotify } from 'react-admin';
import {
	Typography,
	TextField,
	FormLabel,
	FormControlLabel,
	Radio,
	RadioGroup,
	Box,
	FormControl,
	InputLabel,
	Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import WarningIcon from '@material-ui/icons/Warning';
import TreeView from '../../common/TreeView/TreeView';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTheme } from '@material-ui/core/styles';
import 'react-sortable-tree/style.css';

import './treeTableStyle.css';

import { ContasContext } from './contexts/ContasContext';
import { ModalContext, CustomDialogBody, ModalContextProvider, CustomDialog } from '../../common/ModalContext';
import { dataToTree } from './helpers/dataToTree';
import { TooltipIconButton } from '../../common/TooltipIconButton';
import { ContasInput } from './ContasInput';
import { Link } from 'react-router-dom';
import RenderTreeAdicionaConta from '../../common/TreeView/RenderTreeAdicionaConta';
import RenderTreeSelectConta from '../../common/TreeView/RenderTreeContaBase';

const ModalDetailContext = createContext();

export const SelectContaSimples = ({ tipo, filter, conta, setConta }) => {
	const [options, setOptions] = useState();
	const { contas } = useContext(ContasContext);
	const init = () => {
		if (contas[tipo]) {
			let contasArr = Object.keys(contas[tipo]).map((key) => contas[tipo][key]);
			if (filter) {
				contasArr = contasArr
					.filter((c) => {
						for (const v of filter.values) {
							if (c[filter.key] === v) {
								return true;
							}
						}
						return false;
					})
					.sort((a, b) => {
						if (a.tree_id > b.tree_id) {
							return 1;
						}
						if (a.tree_id < b.tree_id) {
							return -1;
						}
						return 0;
					});
			}
			setOptions(contasArr);
		}
	};

	useEffect(init, [contas[tipo]]);

	return (
		<Autocomplete
			id='select-multi-contas'
			options={options}
			renderInput={(params) => <TextField {...params} margin='dense' label='Selecionar Conta' />}
			getOptionLabel={(option) => (option && option.nome) || options.find((v) => v.id === option).nome}
			renderOption={(option) => (
				<span>
					{option &&
						('\xa0\xa0\xa0'.repeat(option.level) + option.nome ||
							'\xa0\xa0\xa0'.repeat(options.find((v) => v.id === option).level) +
								options.find((v) => v.id === option).nome)}
				</span>
			)}
			getOptionDisabled={(option) => option.classe_conta === 'T'}
			noOptionsText='Nenhum resultado encontrado'
			getOptionSelected={(option, v) => v === option.id}
			value={conta}
			onChange={(_, v) => setConta(v ? v.id : null)}
			clearText='Limpar'
		/>
	);
};

const ModalAddConta = ({ setSelectedConta, tipo, filter }) => {
	const [newConta, setNewConta] = useState();
	const { contas, dispatch, id } = useContext(ContasContext);
	const dataProvider = useDataProvider();
	const notify = useNotify();
	const [treeData, setTreeData] = useState([]);

	const init = () => {
		if (contas[tipo]) {
			let contasArr = Object.keys(contas[tipo]).map((key) => contas[tipo][key]);
			if (filter) {
				contasArr = contasArr.filter((c) => {
					for (const v of filter.values) {
						if (c[filter.key] === v) {
							return true;
						}
					}
					return false;
				});
			}
			setTreeData(dataToTree(contasArr));
		}
	};

	useEffect(init, [contas[tipo]]);

	const handleSubmit = () => {
		if (newConta?.id) {
			setSelectedConta(newConta);
		} else {
			dataProvider
				.createMany(`contas_${tipo}`, {
					data: { ...newConta, nomes: [newConta.nome] },
				})
				.then((response) => {
					const data = response?.data;
					if (data) {
						setSelectedConta(data[0]);
						notify('Conta cadastrada com sucesso');
						dispatch({
							type: 'addOne',
							tipoConta: tipo,
							data: data[0],
						});
					}
				});
		}
	};

	return (
		<CustomDialogBody
			Context={ModalDetailContext}
			title={`Selecionar conta de ${tipo}`}
			form={{
				valid: newConta && newConta.nome,
				handleSubmit,
				component: (
					<Box>
						{treeData.length > 0 ? (
							<Box>
								{newConta?.id_pai ? (
									<>
										<Typography variant='body2'>
											Na conta título <strong>{newConta?.nome_pai}</strong>
										</Typography>
										<Box display='flex' alignItems='center'>
											<TextField
												fullWidth
												margin='dense'
												label='Nome da Conta'
												onChange={(e) => {
													let value = e.target.value;
													setNewConta((v) => ({
														...v,
														nome: value,
													}));
												}}
											/>
											<TooltipIconButton
												style={{ color: '#CA3433' }}
												title='Cancelar'
												onClick={() => setNewConta({})}
											>
												<CloseIcon />
											</TooltipIconButton>
										</Box>
									</>
								) : (
									<Box maxHeight='300px' minHeight='200px' mb='15px' css={{ overflowY: 'auto' }}>
										<TreeView
											treeData={treeData}
											newConta={newConta}
											setNewConta={setNewConta}
											RenderTree={RenderTreeAdicionaConta}
										/>
									</Box>
								)}
							</Box>
						) : (
							<Typography style={{ height: 200 }}>
								Nenhuma conta título de {tipo} válida. Configure no{' '}
								<Link to={`/plano_condominio/${id}`} target='_blank'>
									Plano de Condomínio
								</Link>
								.
							</Typography>
						)}
					</Box>
				),
			}}
		/>
	);
};

const InputSelectContaPlano = ({ selectedConta, setSelectedConta, tipo, disabled, filter }) => {
	const theme = useTheme();
	const { setModalValue } = useContext(ModalDetailContext);

	return (
		<FormControl>
			<InputLabel
				htmlFor='botao-selecionar-conta'
				variant='outlined'
				shrink
				style={{
					background: theme.fundoMenu[100],
					borderRight: `6px solid ${theme.fundoMenu[100]}`,
					borderLeft: `6px solid ${theme.fundoMenu[100]}`,
					margin: '0 -6px -8px',
					borderRadius: '6px',
					top: 8,
				}}
				disabled={disabled}
			>
				Conta de {tipo}
			</InputLabel>
			<Button
				id='botao-selecionar-conta'
				variant='outlined'
				color='primary'
				fullWidth
				style={{ textTransform: 'none', margin: '8px 0 4px' }}
				onClick={() =>
					setModalValue((v) => ({
						...v,
						open: true,
						dialogBody: (
							<ModalAddConta
								{...{
									filter,
									tipo,
									selectedConta,
									setSelectedConta,
								}}
							/>
						),
					}))
				}
				disabled={disabled}
			>
				{selectedConta?.nome || 'Selecionar Conta'}
			</Button>
		</FormControl>
	);
};

export const SelectContaPlano = ({ selectedConta, setSelectedConta, tipo, disabled, filter }) => (
	<ModalContextProvider Context={ModalDetailContext}>
		<InputSelectContaPlano {...{ selectedConta, setSelectedConta, tipo, disabled, filter }} />
		<CustomDialog Context={ModalDetailContext} />
	</ModalContextProvider>
);

export const ContaSelect = ({ treeData, selectedConta, setSelectedConta, boxProps = {} }) => (
	<Box minHeight='200px' maxHeight='300px' css={{ overflowY: 'auto' }} {...boxProps}>
		<TreeView
			selectedConta={selectedConta}
			setSelectedConta={setSelectedConta}
			treeData={treeData}
			RenderTree={RenderTreeSelectConta}
		/>
	</Box>
);

export const VinculoForm = ({ conta, contaVinculo, despesa = false }) => {
	const { contas } = useContext(ContasContext);
	const [selectedContaVinculo, setSelectedContaVinculo] = useState();
	const [treeData, setTreeData] = useState(new Array(0));

	const initialSetup = () => {
		let recs = JSON.parse(JSON.stringify(despesa ? contas.despesa : contas.receita));
		Object.keys(recs).forEach((key) => {
			if (conta.tipo === 'B') {
				if (recs[key].tipo !== 'E' && recs[key].tipo !== 'O') delete recs[key];
			} else {
				if (conta.tipo !== recs[key].tipo) delete recs[key];
			}
		});
		setTreeData(dataToTree(Object.keys(recs).map((key) => recs[key])));

		if (conta.conta_receita) {
			setSelectedContaVinculo({
				id: conta.conta_receita,
				nome: conta.conta_receita_nome,
			});
		} else {
			setSelectedContaVinculo(contaVinculo);
		}
	};

	useEffect(initialSetup, [contas.receita]);

	const handleSubmit = () => {
		if (conta.tipo === 'B') {
			contas.contaRequest(
				'update',
				{
					[despesa ? 'id_conta_despesa' : 'id_conta_receita']: selectedContaVinculo?.id || null,
				},
				'contas_arrecadacao',
				conta.id
			);
		} else {
			contas.contaRequest(
				'update',
				{ conta_receita: selectedContaVinculo?.id || null },
				'contas_despesa',
				conta.id
			);
		}
	};

	return (
		<CustomDialogBody
			title={`Vincular conta de ${despesa ? 'despesa' : 'receita'} a "${conta.nome}"`}
			form={{
				handleSubmit,
				valid: true,
				component: (
					<>
						<div
							style={{
								maxHeight: '400px',
								overflowY: 'auto',
							}}
						>
							<ContaSelect
								treeData={treeData}
								setSelectedConta={setSelectedContaVinculo}
								selectedConta={selectedContaVinculo}
							/>
						</div>
						<Typography style={{ marginTop: '20px', alignItems: 'center' }} variant='subtitle2'>
							{selectedContaVinculo &&
								selectedContaVinculo.nome &&
								'Conta selecionada: ' + selectedContaVinculo.nome}
							{selectedContaVinculo &&
								selectedContaVinculo.id &&
								conta[despesa ? 'id_conta_despesa' : 'conta_receita'] && (
									<TooltipIconButton
										title='Remover vínculo'
										style={{ marginBottom: 4 }}
										onClick={() =>
											setSelectedContaVinculo({
												id: null,
											})
										}
									>
										<DeleteIcon />
									</TooltipIconButton>
								)}
						</Typography>
					</>
				),
			}}
		/>
	);
};

export const CreateRootForm = ({ value }) => {
	const { contas } = useContext(ContasContext);
	const [data, setData] = useState({
		tipo: 'O',
		nomes: [],
		classe_conta: 'T',
		id_plano_condominio: value.id,
	});
	const [valid, setValid] = useState(false);

	const handleSubmit = () => {
		contas.contaRequest('createMany', data, value.endpoint);
	};

	const handleChange = (property, v) => {
		setData({ ...data, [property]: v });
		if (property === 'nomes') {
			setValid(v.length > 0);
		}
	};

	const setNomes = (v) => handleChange('nomes', v);

	return (
		value && (
			<CustomDialogBody
				title='Criar nova conta título'
				form={{
					valid,
					handleSubmit,
					component: (
						<>
							<RadioGroup
								aria-label='tipo'
								name='tipo'
								label='Tipo'
								value={data.tipo}
								onChange={(e) => handleChange('tipo', e.target.value)}
							>
								<FormLabel>Tipo</FormLabel>
								<FormControlLabel value='O' control={<Radio />} label='Ordinária' />
								<FormControlLabel value='E' control={<Radio />} label='Extraordinária' />
								{value.endpoint === 'contas_receita' && (
									<>
										<FormControlLabel value='L' control={<Radio />} label='Leitura' />
										<FormControlLabel value='F' control={<Radio />} label='Fundos' />
									</>
								)}
							</RadioGroup>
							<ContasInput handleSubmit={handleSubmit} setNomes={setNomes} />
						</>
					),
				}}
			/>
		)
	);
};

export const CreateChildForm = ({ conta, value }) => {
	const { contas } = useContext(ContasContext);
	const [data, setData] = useState({
		id_plano_condominio: value.id,
		nomes: [],
		tipo: conta.tipo,
		classe_conta: conta.children.length > 0 ? conta.children[0].classe_conta : 'T',
		id_pai: conta.id,
	});
	const [valid, setValid] = useState(false);
	const [choiceDisabled, setChoiceDisabled] = useState(false);

	const checkDisabled = () => {
		if (conta) {
			for (let key of Object.keys(contas[value.tipoConta])) {
				if (contas[value.tipoConta][key].id_pai === conta.id) {
					setChoiceDisabled(true);
				}
			}
		}
	};

	useEffect(checkDisabled, []);

	const handleSubmit = () => {
		contas.contaRequest('createMany', data, value.endpoint);
	};

	const handleChange = (property, v) => {
		setData({ ...data, [property]: v });
		if (property === 'nomes') {
			setValid(v.length > 0);
		}
	};

	const setNomes = (v) => handleChange('nomes', v);

	return (
		conta && (
			<CustomDialogBody
				title={`Cadastrar nova conta em "${conta.nome}"`}
				form={{
					valid,
					handleSubmit,
					component: (
						<>
							<RadioGroup
								aria-label='classe'
								name='classe'
								label='Classe da conta'
								value={data.classe_conta}
								onChange={(e) => handleChange('classe_conta', e.target.value)}
							>
								<FormLabel>Classe da Conta</FormLabel>
								<FormControlLabel
									value='T'
									control={<Radio />}
									label='Título'
									disabled={choiceDisabled}
								/>
								<FormControlLabel
									value='A'
									control={<Radio />}
									label='Analítica'
									disabled={choiceDisabled}
								/>
							</RadioGroup>
							<ContasInput handleSubmit={handleSubmit} setNomes={setNomes} />
						</>
					),
				}}
			/>
		)
	);
};

export const EditForm = ({ conta, value }) => {
	const { contas } = useContext(ContasContext);
	const { setModalValue } = useContext(ModalContext);
	const [data, setData] = useState({
		nome: conta.nome,
		apelido: conta.apelido,
		classe_conta: conta.classe_conta,
	});
	const [valid, setValid] = useState(false);
	const [choiceDisabled, setChoiceDisabled] = useState(false);

	const checkDisabled = () => {
		if (conta) {
			setChoiceDisabled(
				!!Object.values(contas[value.tipoConta]).find(
					(c) => c.id !== conta.id && (c.id_pai === conta.id || (conta.id_pai && c.id_pai === conta.id_pai))
				)
			);
		}
	};

	useEffect(checkDisabled, []);

	const handleSubmit = () => {
		contas.contaRequest('update', data, value.endpoint, conta.id);
	};

	const handleChange = (property, v) => {
		setData({ ...data, [property]: v });
		if (property === 'nome') {
			setValid(v.length > 1 && v.length <= 40);
		}
		if (property === 'apelido') {
			setValid(v.length > 0 && v.length <= 22);
		}
	};

	return (
		conta && (
			<CustomDialogBody
				title={`Editar conta "${conta.nome}"`}
				form={{
					valid,
					handleSubmit,
					component: (
						<>
							<RadioGroup
								aria-label='classe'
								name='classe'
								label='Classe da conta'
								value={data.classe_conta}
								onChange={(e) => handleChange('classe_conta', e.target.value)}
							>
								<FormLabel>Classe da Conta</FormLabel>
								<FormControlLabel
									value='T'
									control={<Radio />}
									label='Título'
									disabled={choiceDisabled}
								/>
								<FormControlLabel
									value='A'
									control={<Radio />}
									label='Analítica'
									disabled={choiceDisabled}
								/>
							</RadioGroup>
							<TextField
								fullWidth
								label='Nome'
								value={data.nome}
								onChange={(e) => handleChange('nome', e.target.value)}
								style={{ marginTop: 15 }}
								onKeyPress={(e) => {
									if (e.key === 'Enter') {
										e.stopPropagation();
										handleSubmit();
										setModalValue((v) => ({
											...v,
											open: false,
										}));
									}
								}}
							/>
							<TextField
								fullWidth
								label='Apelido para Exportação Contábi'
								value={data.apelido}
								disabled={data.classe_conta == 'T'}
								onChange={(e) => handleChange('apelido', e.target.value)}
								style={{ marginTop: 15 }}
							/>
						</>
					),
				}}
			/>
		)
	);
};

export const getDescendants = (conta, ids = []) => {
	let currIds = [...ids, conta.id];
	if (conta.children.length > 0) {
		conta.children.forEach((c) => (currIds = getDescendants(c, currIds)));
	}
	return currIds;
};

export const DeleteForm = ({ conta, value }) => {
	const { contas } = useContext(ContasContext);
	const [valid, setValid] = useState(true);
	const {
		palette: { syndikosRed },
	} = useTheme();

	const handleSubmit = () => {
		contas.contaRequest('delete', { children: conta.children }, value.endpoint, conta.id);
	};

	const initialSetup = () => {
		let descs = getDescendants(conta);
		for (let id of descs) {
			if (contas[value.tipoConta][id] && contas[value.tipoConta][id].motivos_para_nao_excluir) {
				setValid(false);
				break;
			}
		}
	};

	useEffect(initialSetup, [conta, contas[value.tipoConta]]);

	return (
		conta && (
			<CustomDialogBody
				title={
					valid
						? `Tem certeza que deseja remover a conta "${conta.nome}"?`
						: 'Esta conta não pode ser removida'
				}
				text={
					<Box display='flex' alignItems='center' gridGap='20px'>
						<Box>
							<WarningIcon style={{ color: syndikosRed.main }} fontSize='large' />
						</Box>
						<Box>
							{valid ? (
								<Typography
									style={{
										fontWeight: 'bold',
										color: syndikosRed.main,
									}}
								>
									Essa ação é irreversível.
									<br />
									Todo o histórico será excluído.
								</Typography>
							) : (
								<Typography style={{ fontWeight: 'bold' }}>
									<ul>
										{(conta.motivos_para_nao_excluir.tem_vinculo_com_lancamentos_receita ||
											conta.motivos_para_nao_excluir.tem_vinculo_com_lancamentos_despesa) && (
											<li>Esta conta está vinculada a ao menos um lançamento</li>
										)}
										{conta.motivos_para_nao_excluir.tem_vinculo_com_arrecadacoes && (
											<li>Esta conta está vinculada a ao menos uma arrecadação</li>
										)}
										{conta.motivos_para_nao_excluir.tem_vinculo_com_configuracoes_leitura && (
											<li>
												Esta conta está vinculada a ao menos uma configuração de leitura ativa
											</li>
										)}
										{conta.motivos_para_nao_excluir.tem_vinculo_com_despesas && (
											<li>Esta conta está vinculada a ao menos uma despesa de conta a pagar</li>
										)}
									</ul>
								</Typography>
							)}
						</Box>
					</Box>
				}
				form={{ handleSubmit, valid }}
				remove
			/>
		)
	);
};

export const EditPlanoForm = ({ descricao, id }) => {
	const { setModalValue } = useContext(ModalContext);
	const [valid, setValid] = useState(false);
	const [nome, setNome] = useState(descricao);
	const notify = useNotify();
	const [update] = useUpdate('plano_condominio', id, { descricao: nome });

	const handleSubmit = () => {
		update();
		notify('Plano de condomínio alterado com sucesso');
	};

	return (
		descricao && (
			<CustomDialogBody
				title={`Renomear plano "${descricao}"`}
				form={{
					valid,
					handleSubmit,
					component: (
						<>
							<TextField
								fullWidth
								label='Nome'
								value={nome}
								onChange={(e) => {
									setNome(e.target.value);
									setValid(e.target.value.length > 1 && e.target.value.length <= 40);
								}}
								onKeyPress={(e) => {
									if (e.key === 'Enter') {
										e.stopPropagation();
										handleSubmit();
										setModalValue((v) => ({
											...v,
											open: false,
										}));
									}
								}}
							/>
						</>
					),
				}}
			/>
		)
	);
};

export const DeletePlanoForm = ({ descricao, id, condominios }) => {
	const [valid, setValid] = useState(false);
	const redirect = useRedirect();
	const notify = useNotify();
	const {
		palette: { syndikosRed },
	} = useTheme();
	const dataProvider = useDataProvider();

	const handleSubmit = () => {
		dataProvider.delete('plano_condominio', { id }).then(() => {
			redirect('/plano_condominio');
			notify('Plano de condomínio removido com sucesso');
		});
	};

	useEffect(
		() => setValid(condominios.filter((condominio) => condominio.situacao !== 'E').length === 0),
		[condominios]
	);

	return (
		descricao && (
			<CustomDialogBody
				title={
					valid
						? `Tem certeza que deseja remover ${descricao}?`
						: 'Este plano não pode ser removido por possuir condomínios vinculados'
				}
				text={
					valid ? (
						<Box display='flex' alignItems='center' gridGap='20px'>
							<Box>
								<WarningIcon style={{ color: syndikosRed.main }} fontSize='large' />
							</Box>
							<Box>
								<Typography
									style={{
										fontWeight: 'bold',
										color: syndikosRed.main,
									}}
								>
									Essa ação é irreversível.
									<br />
									Todo o histórico será excluído.
								</Typography>
							</Box>
						</Box>
					) : null
				}
				form={{ handleSubmit, valid }}
				remove
			/>
		)
	);
};

export const ImportPlanoForm = ({ descricao, id }) => {
	const { contas } = useContext(ContasContext);
	const [canImport, setCanImport] = useState(false);
	const [valid, setValid] = useState(false);
	const [planos, setPlanos] = useState();
	const [plano, setPlano] = useState();

	const dataProvider = useDataProvider();

	const { data } = useGetList(
		'plano_condominio',
		{ page: 1, perPage: 10000 },
		{ field: 'descricao', order: 'ASC' },
		{ situacao: 'A' }
	);

	const handleSubmit = () => {
		dataProvider
			.copyPlan('contas_copia', {
				data: {
					id_plano_origem: plano.id,
					id_plano_destino: id,
				},
			})
			.then(() => {
				contas.contaRequest('refresh', {}, 'contas_despesa');
				contas.contaRequest('refresh', {}, 'contas_receita');
			});
	};

	useEffect(() => {
		setCanImport(Object.keys(contas.receita).length === 0 && Object.keys(contas.despesa).length === 0);
	}, [contas]);

	useEffect(() => {
		if (data && id) {
			let copy = JSON.parse(JSON.stringify(data));
			delete copy[id];
			setPlanos(
				Object.keys(copy)
					.map((key) => copy[key])
					.filter((d) => d.id !== id)
			);
		}
	}, [data, id]);

	const handleChange = (_, newValue) => {
		if (newValue) {
			setValid(newValue.id > 0 && canImport);
			setPlano(newValue);
		} else {
			setPlano(null);
			setValid(false);
		}
	};

	return descricao ? (
		<CustomDialogBody
			title={
				valid
					? `Copiar outro plano para "${descricao}"`
					: 'Este plano não pode importar contas por não estar vazio'
			}
			form={{
				handleSubmit,
				valid,
				component: canImport && planos && (
					<Autocomplete
						options={planos}
						onChange={handleChange}
						getOptionLabel={(option) => option.descricao}
						getOptionSelected={(option, value) => option.id === value.id}
						renderInput={(params) => (
							<TextField {...params} label='Selecione um plano de condomínio' variant='outlined' />
						)}
					/>
				),
			}}
		/>
	) : (
		<div />
	);
};
