import React from 'react';
import { useDataProvider, useNotify } from 'react-admin';

import { format } from 'date-fns';

import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import GroupsIcon from '@material-ui/icons/Group';
import ApartmentIcon from '@material-ui/icons/Apartment';
import PaymentIcon from '@material-ui/icons/Payment';

import {
	makeStyles,
	Box,
	Divider,
	FormControlLabel,
	Checkbox,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	TextField,
} from '@material-ui/core';

import { ConsultaDeReservasContext } from '../context/AgendamentoContext';
import { SelectHorarios } from '../inputs/SelectHorarios';
import { SelectOneUnidade } from '../inputs/SelectOneUnidade';
import { SelectOneUser } from '../inputs/SelectOneUser';
import { LoadingButton } from '../buttons/LoadingButton';

import { getTaxaDeUsoAmbiente, groupedHoursToObject } from './CardReservasEBloqueios/utils';
import JSTreeFromXML from './CardReservasEBloqueios/utils/JSTreeFromXML';
import weekDayToString from './CardReservasEBloqueios/utils/weekDaysToString';

const useStyles = makeStyles((theme) => ({
	cancelarButton: {
		backgroundColor: theme.palette.syndikosRed.main,
		color: '#fff',
		'&:hover': {
			backgroundColor: theme.palette.syndikosRed.main,
			color: '#fff',
		},
	},
	editarButton: {
		backgroundColor: theme.secondary[200],
		color: '#fff',
		'&:hover': {
			backgroundColor: theme.secondary[200],
			color: '#fff',
		},
	},
	styledInfo: {
		'align-self': 'baseline',
		display: 'flex',
		'flex-direction': 'column',
		gap: '5px',
		'& > div > span': {
			display: 'flex',
			gap: '10px',
			'align-items': 'center',
		},
	},
}));

export const ModalCriarAgendamento = () => {
	const {
		consulta: { ambienteSelecionado, diaSelecionado, condominioSelecionado, showModalCriarReservas },
		actionCallBackReloadReserva,
		setConsulta,
	} = React.useContext(ConsultaDeReservasContext);

	const [configCriarReserva, setConfigCriarReserva] = React.useState();
	const [unidadeSelecionada, setUnidadeSelecionada] = React.useState();
	const [pessoaSelecionada, setPessoaSelecionada] = React.useState();
	const [selectedHorarios, setSelectedHorarios] = React.useState([]);
	const [aceitouTermos, setAceitouTermos] = React.useState(false);
	const [loadingCriar, setLoadingCriar] = React.useState(false);
	const [observacoes, setObservacoes] = React.useState('');

	const dp = useDataProvider();
	const notify = useNotify();
	const classes = useStyles();

	const onSubmit = () => {
		if (!unidadeSelecionada || !ambienteSelecionado || !pessoaSelecionada) return;
		setLoadingCriar(true);
		const reservasIntervalosTempo = groupedHoursToObject(selectedHorarios);
		const diaSemana = weekDayToString(diaSelecionado);
		const diaReservadoFormated = format(diaSelecionado, 'yyyy-MM-dd');

		dp.create('reserva_agendada_syndkos', {
			data: {
				unidade: unidadeSelecionada.id,
				responsavel: pessoaSelecionada.pessoa_id,
				ambiente: ambienteSelecionado.id,
				dia_semana: diaSemana,
				dia_reservado: diaReservadoFormated,
				reservas_intervalos_tempo: reservasIntervalosTempo,
				observacoes: observacoes,
			},
		})
			.then(() => {
				notify('Agendamento concluído. Sua reserva foi realizada com sucesso!', { type: 'success' });
				actionCallBackReloadReserva();
			})
			.catch(({ response }) => {
				const errorMessage = response?.data || null;
				const hasJsonResponse = response?.headers['content-type'] === 'application/json';

				if (!errorMessage || !hasJsonResponse) {
					return notify('Tente novamente. Erro ao agendar reserva.', 'warning');
				}

				Object.keys(errorMessage).forEach((keyName) => {
					notify(`Ação inválida. ${errorMessage[keyName][0]}`, 'warning');
				});
			})
			.finally(() => setLoadingCriar(false));
	};

	const fetchDataSelectedDay = () => {
		if (!diaSelecionado || !ambienteSelecionado || !condominioSelecionado) return;
		Promise.allSettled([
			dp.getSimple('reserva_dia', {
				filter: {
					data: format(diaSelecionado, 'yyyy-MM-dd'),
					condominio_id: condominioSelecionado?.id,
					dia: weekDayToString(diaSelecionado),
					ambiente_id: ambienteSelecionado?.id,
				},
			}),
			dp.getSimple('bloqueio_reservas_syndkos', {
				filter: {
					dia_bloqueado: format(diaSelecionado, 'yyyy-MM-dd'),
					condominio_id: condominioSelecionado?.id,
					ambiente_id: ambienteSelecionado?.id,
				},
			}),
		])
			.then(([responsesReservasDias, responseBloqueios]) => {
				const [reservaDia] = responsesReservasDias.value?.data?.results ?? [];
				if (responseBloqueios.value?.data?.results.length > 0) {
					const bloqueios = responseBloqueios.value.data.results.reduce((intervalos, bloqueio) => {
						if (bloqueio.bloqueios_intervalos_tempo && bloqueio.bloqueios_intervalos_tempo.length > 0) {
							intervalos = intervalos.concat(bloqueio.bloqueios_intervalos_tempo);
						}
						return intervalos;
					}, []);

					if (reservaDia.reservas.length === 0) {
						reservaDia.reservas[0] = { reservas_intervalos_tempo: [...bloqueios] };
					}
					const intervalosBloqueados = [...reservaDia.reservas[0].reservas_intervalos_tempo, ...bloqueios];
					reservaDia.reservas[0].reservas_intervalos_tempo = intervalosBloqueados;
				}
				setConfigCriarReserva(reservaDia);
			})
			.catch((e) => ([401, 403].includes(e?.response?.status) ? Promise.reject(e) : []));
	};

	React.useEffect(
		fetchDataSelectedDay,
		// eslint-disable-next-line
		[diaSelecionado]
	);

	React.useEffect(
		() => setPessoaSelecionada(null),
		// eslint-disable-next-line
		[unidadeSelecionada]
	);

	return (
		<Dialog
			open={showModalCriarReservas}
			onClose={() =>
				setConsulta((prevState) => ({
					...prevState,
					showModalCriarReservas: false,
				}))
			}
			fullWidth
			PaperProps={{
				style: {
					minWidth: '61vw',
					position: 'relative',
				},
			}}
		>
			<DialogTitle>Criar Reserva Agendada</DialogTitle>
			<DialogContent>
				<Box className={classes.styledInfo}>
					<div>
						<span role='img' aria-label='Data da reserva'>
							<CalendarTodayIcon /> Data da reserva:{' '}
							<strong>{format(diaSelecionado, 'dd/MM/yyyy')}</strong>
						</span>
					</div>
					<div>
						<span>
							<ApartmentIcon /> Condomínio: <strong>{condominioSelecionado.nome}</strong>
						</span>
					</div>
					<div>
						<span role='img' aria-label='Custo da reserva'>
							<PaymentIcon /> Taxa de uso:{' '}
							<strong>
								{ambienteSelecionado && getTaxaDeUsoAmbiente(ambienteSelecionado, diaSelecionado)}
							</strong>
						</span>
					</div>
					{!!ambienteSelecionado?.capacidade_pessoas && (
						<div>
							<span>
								<GroupsIcon /> Capacidade máxima:{' '}
								<strong>{ambienteSelecionado.capacidade_pessoas} pessoas</strong>
							</span>
						</div>
					)}
				</Box>
				<Divider style={{ margin: '10px 10px' }} />
				<Box
					sx={{
						display: 'flex',
						alignItems: 'flex-start',
						gap: 10,
					}}
				>
					<SelectOneUnidade
						setValue={setUnidadeSelecionada}
						value={unidadeSelecionada}
						// style={{ width: "48%", marginRight: "10px" }}
					/>
					{unidadeSelecionada && (
						<SelectOneUser
							setValue={setPessoaSelecionada}
							value={pessoaSelecionada}
							unidadeSelecionada={unidadeSelecionada}
							// style={{ width: "48%" }}
						/>
					)}
				</Box>
				<Divider style={{ margin: '10px 10px' }} />
				<Box>
					{configCriarReserva && (
						<SelectHorarios
							configCriarReserva={configCriarReserva}
							setSelectedHorarios={setSelectedHorarios}
							selectedHorarios={selectedHorarios}
							// style={{ width: "48%" }}
						/>
					)}
				</Box>
				<Divider style={{ margin: '10px 10px' }} />
				<Box>
					<h3>Descrição</h3>
					<span>
						<JSTreeFromXML
							XMLString={ambienteSelecionado?.descricao || '<p>Nenhuma descrição disponível</p>'}
						/>
					</span>
					<Divider style={{ margin: '10px 10px' }} />
					<h3>Regulamento</h3>
					<span>
						<JSTreeFromXML
							XMLString={ambienteSelecionado?.regulamento || '<p>Nenhum regulamento disponível</p>'}
						/>
					</span>
					<Divider style={{ margin: '10px 10px' }} />
					<div>
						<h3>Observações</h3>
						<TextField
							fullWidth
							size='small'
							variant='outlined'
							placeholder='Observações'
							value={observacoes}
							multiline
							onChange={(e) => {
								if (e.target.value.length <= 500) {
									return setObservacoes(e.target.value);
								}
							}}
						></TextField>
						<Box margin={1}>{observacoes?.length || 0}/500 caracteres</Box>
					</div>
					<Divider style={{ margin: '20px 10px 10px 10px' }} />
					<div>
						<FormControlLabel
							label='Aceito os termos de uso desse
                                        ambiente'
							aria-required
							control={<Checkbox />}
							value={aceitouTermos}
							onChange={(_, c) => setAceitouTermos(c)}
						/>
					</div>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() =>
						setConsulta((prevState) => ({
							...prevState,
							showModalCriarReservas: false,
						}))
					}
					className={classes.cancelarButton}
					variant='contained'
				>
					Cancelar
				</Button>
				<LoadingButton
					onClick={onSubmit}
					className={classes.editarButton}
					loading={loadingCriar}
					disabled={!aceitouTermos || !selectedHorarios.length || !unidadeSelecionada || !pessoaSelecionada}
					size='medium'
				>
					Criar reserva
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};
