import React, { useState, useEffect, useContext, createContext } from 'react';
import {
	TextField,
	Box,
	Button,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	makeStyles,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useGetList, useRefresh } from 'react-admin';
import { FormlessInputDateReferencia } from 'components/common/InputDate';
import { TooltipIconButton } from 'components/common/TooltipIconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SettingsIcon from '@material-ui/icons/Settings';
import { ModalContextProvider, CustomDialog } from 'components/common/ModalContext';
import ModalConfiguraIdentificacao from 'components/modais/ModalConfiguraIdentificacao';
import { ButtonConfirmV2 } from 'components/common/buttons/ButtonConfirmV2';
import { AcordosParcelasFluxoModaisContext } from '../contexts/AcordosParcelasFluxoModaisContext';
import { BotaoVoltar } from 'components/common/buttons/BotaoVoltar';
import ModalEdicaoAcordoParcelas from 'components/modais/ModalEdicaoParcelas';
import { useDataProvider, useNotify } from 'react-admin';
import Tooltip from '@material-ui/core/Tooltip';
import { ButtonCancel } from 'components/common/commonComponentsTSX';
import { ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';

const useStyles = makeStyles((theme) => ({
	inputRowStyle: {
		display: 'flex',
		alignItems: 'center',
		gap: '10px',
		width: '100%',
		flexWrap: 'wrap',
	},
	inputBoxStyle: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	inputStyle: {
		minWidth: '34vw',
		[theme.breakpoints.up(2400)]: {
			minWidth: '35vw',
		},
		[theme.breakpoints.down(1600)]: {
			minWidth: '30vw',
		},
		[theme.breakpoints.down('sm')]: {
			minWidth: '26vw',
		},
		[theme.breakpoints.down('xs')]: {
			minWidth: '22vw',
		},
	},
	buttonGerarStyle: {
		display: 'flex',
		justifyContent: 'flex-end',
		gridGap: '1em',
		marginTop: 2,
		marginRight: '2.2vw',
		[theme.breakpoints.up(2400)]: {
			marginRight: '3vw',
		},
	},
	tableHeaderCellStyle: {
		width: '10%',
		fontWeight: 'bold',
	},
}));

const ModalEdicaoParcelas = createContext(undefined);

const ModalEdicaoAcordoParcelasButton = ({ parcelaSelecionada, acordoParcelas, valorTotal, setAcordoParcelas }) => {
	const { setModalValue } = useContext(ModalEdicaoParcelas);
	return (
		<Tooltip title='Editar parcela' arrow>
			<Button
				disabled={acordoParcelas.length == 1}
				onClick={() => {
					setModalValue((v) => ({
						...v,
						open: true,
						dialogBody: (
							<ModalEdicaoAcordoParcelas
								parcelaSelecionada={parcelaSelecionada}
								acordoParcelas={acordoParcelas}
								valorTotal={valorTotal}
								setAcordoParcelas={setAcordoParcelas}
								setModalValue={setModalValue}
							/>
						),
					}));
				}}
			>
				<SettingsIcon />
			</Button>
		</Tooltip>
	);
};

const ModalDetailContext = createContext(undefined);

const IdentificacaoButton = ({
	setSelectedIdentificacao,
	condominioSelecionado,
	identificacoes,
	setIdentificacoes,
	setIdentificacoesAtivas,
	selectedIdentificacao,
	loadingIdentificacao,
}) => {
	const { setModalValue } = useContext(ModalDetailContext);
	return (
		<>
			{!loadingIdentificacao && (
				<TooltipIconButton title='Cadastrar Identificação' size='small'>
					<AddCircleOutlineIcon
						color={'primary'}
						onClick={() => {
							setModalValue((v) => ({
								...v,
								open: true,
								dialogBody: (
									<ModalContextProvider>
										<ModalConfiguraIdentificacao
											ModalContext={ModalDetailContext}
											condominioSelecionado={condominioSelecionado}
											onChange={setSelectedIdentificacao}
											value={selectedIdentificacao}
											setIdentificacoes={setIdentificacoes}
											identificacoes={identificacoes}
											setIdentificacoesAtivas={setIdentificacoesAtivas}
										/>
									</ModalContextProvider>
								),
							}));
						}}
					/>
				</TooltipIconButton>
			)}
		</>
	);
};

const criaAcordoAcrescimosDetalhamentosRecebimentosManuais = (data) => {
	const acordo_acrescimos_detalhamentos_recebimentos_manuais = [];
	data?.forEach((recebimento_manual) => {
		if (recebimento_manual.juros > 0) {
			acordo_acrescimos_detalhamentos_recebimentos_manuais.push({
				valor: parseFloat(recebimento_manual.juros),
				recebimento_manual_id: recebimento_manual.recebimento_manual_id,
				tipo_acrescimo: 'J',
			});
		}

		if (recebimento_manual.multa > 0) {
			acordo_acrescimos_detalhamentos_recebimentos_manuais.push({
				valor: parseFloat(recebimento_manual.multa),
				recebimento_manual_id: recebimento_manual.recebimento_manual_id,
				tipo_acrescimo: 'M',
			});
		}

		if (recebimento_manual.correcaoMonetaria > 0) {
			acordo_acrescimos_detalhamentos_recebimentos_manuais.push({
				valor: parseFloat(recebimento_manual.correcaoMonetaria),
				recebimento_manual_id: recebimento_manual.recebimento_manual_id,
				tipo_acrescimo: 'C',
			});
		}
	});

	return acordo_acrescimos_detalhamentos_recebimentos_manuais;
};

const criaArrayRecebimentosManuaisId = (data) => {
	const recebimentos_manuais_ids = data.map((recebimento_manual) => {
		return recebimento_manual.recebimento_manual_id;
	});
	return recebimentos_manuais_ids;
};

const calcularTotaisAcrescimosEDescontos = (dados) => {
	const totalDescontos = (dados?.acrescimos_e_descontos || []).reduce((total, item) => {
		return item.tipo === 'desconto' ? total + item.valor : total;
	}, 0);

	const totalAcrescimos = (dados?.acrescimos_e_descontos || []).reduce((total, item) => {
		return item.tipo === 'acrescimo' ? total + item.valor : total;
	}, 0);

	return {
		totalAcrescimos,
		totalDescontos,
	};
};

const separaArraysAcrescimosEDescontos = (dados) => {
	const descontosArray = (dados?.acrescimos_e_descontos || []).filter((item) => {
		return item?.tipo === 'desconto';
	}, 0);

	const acrescimosArray = (dados?.acrescimos_e_descontos || []).filter((item) => {
		return item?.tipo === 'acrescimo';
	}, 0);

	return {
		descontosArray,
		acrescimosArray,
	};
};
const ConfiguracaoParcelasModal = ({ setStep }) => {
	const dp = useDataProvider();
	const refresh = useRefresh();
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const [arraysAcresDesc, setArraysAcresDesc] = useState([]);
	const [responsavel, setResponsavel] = useState([]);
	const [respOptions, setRespOptions] = useState([]);
	const [identificacoes, setIdentificacoes] = useState([]);
	const [selectedIdentificacao, setSelectedIdentificacao] = useState(null);
	const [identificacoesAtivas, setIdentificacoesAtivas] = useState([]);
	const [referenciaInicial, setReferenciaInicial] = useState(new Date());
	const [parcelas, setParcelas] = useState(1);
	const [acordoParcelas, setAcordoParcelas] = useState([]);
	const [TotalAcresEDesc, setTotalAcresEDesc] = useState([]);
	const [botaoHabilitado, setBotaoHabilitado] = useState(false);
	const notify = useNotify();
	let {
		valoresTotais,
		selectedArrecadacoesVencidas,
		detalhamentoAcrescimosEDescontos,
		condominioSelecionado,
		unidadeSelecionada,
		dataAcordo,
		dataEncargos,
		correcaoMonetaria,
		tipoJuros,
		juros,
		resetAllStates,
	} = useContext(AcordosParcelasFluxoModaisContext);

	useEffect(() => {
		const { totalAcrescimos, totalDescontos } = calcularTotaisAcrescimosEDescontos(
			detalhamentoAcrescimosEDescontos
		);
		const { descontosArray, acrescimosArray } = separaArraysAcrescimosEDescontos(detalhamentoAcrescimosEDescontos);

		setTotalAcresEDesc({ acrescimos: totalAcrescimos, descontos: totalDescontos });
		setArraysAcresDesc({ acrescimos: acrescimosArray, descontos: descontosArray });
	}, [detalhamentoAcrescimosEDescontos]);

	const classes = useStyles();

	const FormataResponvavel = (tipo_responsavel) => {
		const dict_responsavel = { I: 'Inquilino(a)', P: 'Proprietário(a)' };
		return dict_responsavel[tipo_responsavel];
	};

	useEffect(() => {
		Promise.allSettled([
			dp.getSimple('pessoa_unidade', {
				pagination: { perPage: 1, page: 1 },
				sort: { order: 'DESC', field: 'data_entrada' },
				filter: {
					tipo: 'P',
					id_unidade: unidadeSelecionada?.id,
				},
			}),
			dp.getSimple('pessoa_unidade', {
				pagination: { perPage: 1, page: 1 },
				sort: { order: 'DESC', field: 'data_entrada' },
				filter: {
					tipo: 'I',
					id_unidade: unidadeSelecionada?.id,
				},
			}),
		]).then((results) => {
			const [proprietarioResult, inquilinoResult] = results;

			const isBothFulfilled = proprietarioResult.status === 'fulfilled' && inquilinoResult.status === 'fulfilled';

			if (isBothFulfilled) {
				const proprietarioData = proprietarioResult.value?.data?.results || [];
				const inquilinoData = inquilinoResult.value?.data?.results || [];

				setResponsavel(proprietarioData[0]);
				setRespOptions([...proprietarioData, ...inquilinoData]);
			}
		});
	}, [unidadeSelecionada]);

	const { data: identificacoesData, loading: loadingIdentificacao } = useGetList(
		'identificacao_arrecadacao',
		{ perPage: 100, page: 1 },
		{ field: 'id_condominio', order: 'ASC' },
		{ id_condominio: condominioSelecionado?.id }
	);

	useEffect(() => {
		setIdentificacoes(Object.values(identificacoesData || {}).filter((v) => Boolean(v?.id)));
	}, [loadingIdentificacao]);

	useEffect(() => {
		if (identificacoes.length > 0) {
			setIdentificacoesAtivas(identificacoes.filter((identificacao) => identificacao.situacao === 'A'));
			setSelectedIdentificacao(identificacoes.find((obj) => obj?.nome === 'Principal')?.id);
		}
	}, [identificacoes]);

	const calcularAcordoParcelas = () => {
		if (!parcelas || !referenciaInicial) return;

		const total = valoresTotais.total + TotalAcresEDesc.acrescimos - TotalAcresEDesc.descontos;
		const novaReferencia = new Date(referenciaInicial);
		const percentualBase = Math.floor((100 / parcelas) * 100) / 100;

		let somaPercentual = 0;
		let somaValores = 0;

		const parcelasGeradas = Array.from({ length: parcelas }, (_, index) => {
			const parcelaReferencia = new Date(novaReferencia);
			parcelaReferencia.setMonth(parcelaReferencia.getMonth() + index);

			let percentualParcela = percentualBase;
			let valorParcela = parseFloat((total / parcelas).toFixed(2));

			somaPercentual += percentualBase;
			somaValores += valorParcela;

			return {
				valor: valorParcela,
				numero_parcela: index + 1,
				referencia: parcelaReferencia.toLocaleDateString('pt-BR', { month: '2-digit', year: 'numeric' }),
				percentual: percentualParcela,
			};
		});

		const valorTotalGerado = somaValores;
		const valorRestante = parseFloat((total - valorTotalGerado).toFixed(2));

		if (valorRestante > 0) {
			let centavosRedistribuiveis = Math.floor(valorRestante * 100);
			const novaParcelasGeradas = [...parcelasGeradas];

			for (let index = 0; index < parcelas && centavosRedistribuiveis > 0; index++) {
				const valorAdicional = Math.floor(centavosRedistribuiveis / (parcelas - index));
				novaParcelasGeradas[index].valor = parseFloat(
					(novaParcelasGeradas[index].valor + valorAdicional / 100).toFixed(2)
				);

				centavosRedistribuiveis -= valorAdicional;
			}

			setAcordoParcelas(novaParcelasGeradas);
		} else {
			setAcordoParcelas(parcelasGeradas);
		}

		setBotaoHabilitado(true);
	};

	const createAcordos = () => {
		const data = {
			condominio: condominioSelecionado?.id,
			unidade: unidadeSelecionada?.id,
			situacao: 'A',
			tipo_responsavel: responsavel.tipo,
			identificacao: selectedIdentificacao,
			data_acordo: dataAcordo,
			corrigir_encargos_ate: dataEncargos,
			referencia_inicial: referenciaInicial,
			aplicar_correcao_monetaria: correcaoMonetaria,
			valor_indice_correcao_monetaria: valoresTotais.correcaoMonetaria,
			aplicar_juros: juros,
			percentual_juros: valoresTotais.juros,
			tipo_juros: tipoJuros,
			aplicar_multa: valoresTotais.multa,
			percentual_multa: valoresTotais.multa,
			recebimentos_manuais_ids: criaArrayRecebimentosManuaisId(selectedArrecadacoesVencidas),
			acordo_acrescimos_detalhamentos_recebimentos_manuais:
				criaAcordoAcrescimosDetalhamentosRecebimentosManuais(selectedArrecadacoesVencidas),
			acordo_acrescimos_detalhamentos_descontos: arraysAcresDesc.descontos,
			acordo_acrescimos_detalhamentos_avulsos: arraysAcresDesc.acrescimos,
			acordo_parcelas: acordoParcelas,
		};

		dp.safeCreate('acordos', { data: data })
			.then(() => {
				notify('Acordo cadastrado com sucesso', 'success');
				setModalValue({ open: false });
				resetAllStates();
				refresh();
				return;
			})
			.catch((error) => {
				if ([401, 403].includes(error?.response?.status)) return Promise.reject(error);
				notify(
					Object.values(error?.response?.data || {})[0] || ['Erro inesperado, tente recarregar a página'],
					'warning'
				);
				resetAllStates();
				return;
			});
	};

	const handleLastStep = () => {
		setStep('1B');
	};

	useEffect(() => {
		calcularAcordoParcelas();
	}, [TotalAcresEDesc]);
	useEffect(() => {
		setBotaoHabilitado(false);
	}, [parcelas, referenciaInicial]);
	return (
		<Box
			style={{
				display: 'flex',
				flexDirection: 'column',
				gap: '10px',
				padding: '16px 16px 16px 16px',
			}}
		>
			<Box marginLeft={'1vw'}>
				<Typography variant='h6' style={{ marginBottom: '10px' }}>
					Parcelas
				</Typography>
				<Box display={'flex'} flexDirection={'row'}>
					<Box
						fontWeight={'bold'}
						component='span'
						sx={{
							typography: 'subtitle1',
							marginRight: '5px',
						}}
					>
						Total do Acordo:{' '}
					</Box>
					<Box
						component='span'
						sx={{
							typography: 'subtitle1',
						}}
					>
						R${' '}
						{parseFloat(valoresTotais.total + TotalAcresEDesc.acrescimos - TotalAcresEDesc.descontos)
							.toFixed(2)
							.replace('.', ',')
							.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
					</Box>
				</Box>
			</Box>
			<Box
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '10px',
					padding: '0 16px 16px 16px',
				}}
			>
				<Box className={classes.inputRowStyle}>
					{respOptions.length > 0 && (
						<Autocomplete
							options={respOptions}
							renderInput={(params) => (
								<TextField
									{...params}
									className={classes.inputStyle}
									margin='normal'
									label='Responsável pela cobrança'
								/>
							)}
							getOptionLabel={(option) => {
								return option?.tipo
									? `${FormataResponvavel(option?.tipo)} - ${option?.nome_responsavel}`
									: option?.nome_responsavel || '';
							}}
							value={responsavel}
							onChange={(_, v) => {
								setResponsavel(v || null);
							}}
							size='small'
							disableClearable
						/>
					)}
					{identificacoesAtivas.length > 0 && (
						<Box className={classes.inputBoxStyle}>
							<Autocomplete
								options={identificacoesAtivas}
								renderInput={(params) => (
									<TextField
										{...params}
										className={classes.inputStyle}
										margin='normal'
										label='Identificação'
									/>
								)}
								getOptionLabel={(option) => option.nome}
								onChange={(_, v) => setSelectedIdentificacao(v?.id || null)}
								value={
									selectedIdentificacao
										? identificacoesAtivas.find((obj) => obj.id === selectedIdentificacao)
										: identificacoesAtivas.find((obj) => obj.nome === 'Principal')
								}
								disableClearable
								defaultValue={identificacoesAtivas.find((obj) => obj.nome === 'Principal')}
								size='small'
							/>
							<ModalContextProvider Context={ModalDetailContext}>
								<IdentificacaoButton
									condominioSelecionado={condominioSelecionado}
									setSelectedIdentificacao={setSelectedIdentificacao}
									identificacoes={identificacoes}
									setIdentificacoes={setIdentificacoes}
									setIdentificacoesAtivas={setIdentificacoesAtivas}
									selectedIdentificacao={selectedIdentificacao}
									loadingIdentificacao={loadingIdentificacao}
								/>
								<CustomDialog Context={ModalDetailContext} />
							</ModalContextProvider>
						</Box>
					)}
				</Box>

				<Box className={classes.inputRowStyle}>
					<FormlessInputDateReferencia
						label='Referência Inicial'
						value={referenciaInicial ? new Date(referenciaInicial) : new Date()}
						onChange={(date) => setReferenciaInicial(date)}
						autoOk
						allowEmpty
						format='MM/yyyy'
						helperText={''}
						className={classes.inputStyle}
						size='small'
					/>
					<TextField
						inputProps={{ min: 1 }}
						label='Parcelas'
						value={parcelas}
						onChange={(e) => setParcelas(e.target.value)}
						type='number'
						margin='dense'
						className={classes.inputStyle}
						size='small'
					/>
					<ButtonConfirmV2
						disabled={!selectedIdentificacao || !referenciaInicial || !parcelas || parcelas > 360}
						onClick={calcularAcordoParcelas}
					>
						Calcular
					</ButtonConfirmV2>
				</Box>
			</Box>

			{acordoParcelas.length > 0 && (
				<ModalContextProvider Context={ModalEdicaoParcelas}>
					<>
						<TableContainer style={{ width: '75vw', marginLeft: '16px' }} component={Paper}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell className={classes.tableHeaderCellStyle}>Acordo</TableCell>
										<TableCell className={classes.tableHeaderCellStyle}>Parcela</TableCell>
										<TableCell style={{ width: '5%', fontWeight: 'bold' }}>Referência</TableCell>
										<TableCell style={{ width: '5%', fontWeight: 'bold', textAlign: 'right' }}>
											Valor
										</TableCell>
										<TableCell
											style={{ width: '5%', fontWeight: 'bold', textAlign: 'right' }}
										></TableCell>
										<TableCell style={{ width: '5%', fontWeight: 'bold' }}>Percentual</TableCell>
										<TableCell
											style={{ textAlign: 'right', whiteSpace: 'nowrap', width: '1%' }}
										></TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{acordoParcelas.map((data, index) => (
										<TableRow key={index}>
											<TableCell style={{ textTransform: 'none' }}>Não Gerado</TableCell>
											<TableCell style={{ textTransform: 'none' }}>
												{`${data.numero_parcela
													.toString()
													.padStart(3, '0')}/${acordoParcelas.length
													.toString()
													.padStart(3, '0')}`}
											</TableCell>
											<TableCell style={{ textTransform: 'none' }}>{data.referencia}</TableCell>
											<TableCell style={{ textTransform: 'none', textAlign: 'right' }}>
												R${' '}
												{parseFloat(data.valor)
													.toFixed(2)
													.replace('.', ',')
													.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
											</TableCell>
											<TableCell style={{ textTransform: 'none' }}></TableCell>
											<TableCell style={{ textTransform: 'none' }}>
												{parseFloat(data.percentual).toFixed(2).replace('.', ',')}%
											</TableCell>
											<TableCell
												style={{ textAlign: 'right', whiteSpace: 'nowrap', width: '1%' }}
											>
												<ModalEdicaoAcordoParcelasButton
													parcelaSelecionada={data}
													acordoParcelas={acordoParcelas}
													valorTotal={
														valoresTotais.total +
														TotalAcresEDesc.acrescimos -
														TotalAcresEDesc.descontos
													}
													setAcordoParcelas={setAcordoParcelas}
												/>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
						<Box className={classes.buttonGerarStyle}>
							<ButtonCancel
								onClick={() => {
									setModalValue((v) => ({ ...v, open: false }));
									resetAllStates();
									return;
								}}
								size='small'
							/>
							<BotaoVoltar onClick={handleLastStep} />
							<ButtonConfirmV2 disabled={!botaoHabilitado} onClick={createAcordos}>
								<>Gerar Acordo</>
							</ButtonConfirmV2>
						</Box>
					</>
					<CustomDialog Context={ModalEdicaoParcelas} />
				</ModalContextProvider>
			)}
		</Box>
	);
};

export default ConfiguracaoParcelasModal;
