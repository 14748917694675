import React, { useState, useEffect } from 'react';
import { useListContext } from 'react-admin';
import CloseIcon from '@material-ui/icons/Close';
import { RawKBDatePicker } from '../../common/InputDate';

export const Data = ({
	source = undefined,
	custom = undefined,
	mask = '__/__/____',
	format = 'dd/MM/yyyy',
	views = [],
	listControllerProps = undefined,
}) => {
	const [value, setValue] = useState(null);
	const { setFilters, filterValues } = useListContext(listControllerProps);
	useEffect(() => {
		const oldValue = filterValues[custom || source];
		if (oldValue) setValue(new Date(`${oldValue}T10:00`));
	}, [filterValues, custom, source]);

	const handleChange = (newValue) => {
		setValue(newValue);
		let newValueFilter =
			newValue && newValue.toString && newValue.toString() !== 'Invalid Date'
				? newValue.toISOString().split('T')[0]
				: undefined;
		if (views?.length === 2 && newValueFilter) {
			newValueFilter = newValueFilter.substr(0, 8) + '01';
		}
		setFilters({ ...filterValues, [custom || source]: newValueFilter });
	};

	return (
		<RawKBDatePicker
			cancelLabel='Cancelar'
			clearable={true}
			clearLabel={<CloseIcon />}
			inputVariant='standard'
			id={`${custom || source}-filtro-padrao`}
			onChange={(e) => handleChange(e)}
			initialFocusedDate={value}
			value={value}
			label='Filtrar'
			style={{ width: '100%' }}
			error={false}
			helperText={null}
			mask={mask}
			format={format}
			{...{ views }}
		/>
	);
};

export const DataReferencia = ({
	source,
	custom,
	mask = '__/____',
	format = 'MM/yyyy',
	views = ['year', 'month'],
	listControllerProps,
}) => {
	const [value, setValue] = useState(null);
	const { setFilters, filterValues } = useListContext(listControllerProps);
	useEffect(() => {
		const oldValue = filterValues[custom || source];
		if (oldValue) setValue(new Date(`${oldValue}T10:00`));
	}, [filterValues, custom, source]);

	const handleChange = (newValue) => {
		setValue(newValue);
		let newValueFilter =
			newValue && newValue.toString && newValue.toString() !== 'Invalid Date'
				? newValue.toISOString().split('T')[0]
				: undefined;
		if (views?.length === 2 && newValueFilter) {
			newValueFilter = newValueFilter.substr(0, 8) + '01';
		}
		setFilters({ ...filterValues, [custom || source]: newValueFilter });
	};

	return (
		<RawKBDatePicker
			cancelLabel='Cancelar'
			clearable={true}
			clearLabel={<CloseIcon />}
			inputVariant='standard'
			id={`${custom || source}-filtro-padrao`}
			onChange={(e) => handleChange(e)}
			initialFocusedDate={value}
			value={value}
			label='Filtrar'
			style={{ width: '100%' }}
			error={false}
			helperText={null}
			mask={mask}
			format={format}
			{...{ views }}
		/>
	);
};
