import React from 'react';

import { format, parseISO } from 'date-fns';

import { List, Pagination, ReferenceField, TextField, useListContext } from 'react-admin';

import { Autocompletar } from 'components/common/filtros/Autocompletar';
import { Data } from 'components/common/filtros/Data';
import ModalConfirmarExclusaoComunicadoGeral from './modal/modalDeleteComunicados';
import { Tabela } from 'components/common/Tabela';
import { Padrao } from 'components/common/filtros/Padrao';
import { ComunicadoFieldProps, CustomPaginationProps, CustomTextFieldProps } from './types';

import { TooltipIconButtonRemove } from '../../common/buttons/ButtonRemove';

const CustomPagination: React.FC<CustomPaginationProps> = (props: object) => (
	<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 500]} {...props} />
);

const ResponderField = (record: CustomTextFieldProps) => (
	<span>
		{record.record.participantes === 'I'
			? 'Inquilino'
			: record.record.participantes === 'P'
			? 'Proprietário'
			: record.record.participantes === 'A'
			? 'Proprietário e Inquilino'
			: record.record.participantes === 'R'
			? 'Responsável pela Cobrança'
			: 'Valor Desconhecido'}
	</span>
);

const tipoResponder: CustomTextFieldProps = [
	{ id: 'I', name: 'Inquilino' },
	{ id: 'P', name: 'Proprietário' },
	{ id: 'A', name: 'Proprietário e Inquilino' },
	{ id: 'R', name: 'Responsável pela Cobrança' },
];

const CustomTextField = (props: CustomTextFieldProps) => <TextField {...props} />;

const ReferenceFieldV2: React.FC<CustomTextFieldProps> = ({ children, ...rest }) => {
	return <ReferenceField {...rest}>{children}</ReferenceField>;
};

const SearchFieldDataInicial: React.FC<ComunicadoFieldProps> = ({ record }) => {
	if (!record?.data_notificacao) {
		return <></>;
	}
	const formatada = format(parseISO(record?.data_notificacao), 'dd/MM/yyyy');
	return <span>{formatada}</span>;
};

const TabelaComunicadosGerais = (props: object) => {
	return (
		<Tabela {...props}>
			<ReferenceFieldV2
				id='reference-field-condominio'
				source='condominio'
				label='Condomínio'
				reference='condominios'
				link={false}
				sortBy='nome_condominio'
				filtro={
					<Autocompletar
						refName='nome'
						refResource='condominios'
						mostrarInativos={false}
						id='filtro-nome-condominio'
						label={undefined}
						source={undefined}
						opcoes={undefined}
					/>
				}
				minWidth='200px'
			>
				<TextField source='nome' />
			</ReferenceFieldV2>
			<CustomTextField source='titulo' label='Assunto' filtro={<Padrao />} minWidth='200px' />
			<SearchFieldDataInicial source='data_notificacao' label='Data' filtro={<Data />} minWidth='200px' />
			<ResponderField
				source='participantes'
				label='Enviar Para'
				filtro={
					<Autocompletar
						opcoes={tipoResponder}
						id={undefined}
						label={undefined}
						source={undefined}
						refResource={undefined}
						refName={undefined}
						mostrarInativos={undefined}
					/>
				}
			/>
		</Tabela>
	);
};

const HandleDeleteComunicado = () => {
	const { selectedIds, onUnselectItems } = useListContext();
	const [openModalExcluir, setOpenModalExcluir] = React.useState<boolean>(false);
	const [confirmDeleteComunicado, setConfirmDeleteComunicado] = React.useState<boolean>(false);
	const [closeModalExcluir, setCloseModalExcluir] = React.useState<boolean>(false);

	const handleDelete = () => {
		setOpenModalExcluir(true);
	};
	React.useEffect(() => {
		if (closeModalExcluir) {
			onUnselectItems();
		}
		if (selectedIds[0] === 0) {
			onUnselectItems();
		}
	}, [selectedIds, closeModalExcluir]);

	return (
		<>
			<TooltipIconButtonRemove
				title='Excluir'
				disabled={false}
				onClick={() => {
					handleDelete(), setConfirmDeleteComunicado(true);
				}}
				size='small'
			/>
			<>
				{confirmDeleteComunicado ? (
					<ModalConfirmarExclusaoComunicadoGeral
						comunicado_ids={selectedIds}
						open={openModalExcluir}
						onClose={() => setOpenModalExcluir(false)}
						setCloseModalExcluir={setCloseModalExcluir}
					/>
				) : (
					<></>
				)}
			</>
		</>
	);
};

export const ComunicadoGeralList: React.FC<ComunicadoFieldProps> = (props) => {
	return (
		<List
			{...props}
			empty={false}
			bulkActionButtons={<HandleDeleteComunicado />}
			title='Comunicados Gerais'
			sort={{ field: 'condominio', order: 'ASC' }}
			perPage={10}
			pagination={<CustomPagination labelRowsPerPage='Comunicados por página' />}
		>
			<TabelaComunicadosGerais />
		</List>
	);
};
