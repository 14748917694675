import React, { MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { List, Pagination, TextField, ReferenceField, FunctionField } from 'react-admin';

import { Box, IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';

import { format, parseISO } from 'date-fns';

import { Tabela } from '../../common/Tabela';
import { Padrao } from '../../common/filtros/PadraoV2';
import { Data } from '../../common/filtros/Data';
import { Autocompletar } from 'components/common/filtros/Autocompletar';
import ModalConfirmarExclusaoAssembleia from './modal/ModalConfirmarExclusaoAssembleia';

import { SearchFieldsProps, AssembleiaListProps, ReferenceFieldV2Props, ActionsFieldProps } from './types';

const SearchFieldDataInicial: React.FC<SearchFieldsProps> = ({ record }) => {
	let formatada = '';
	try {
		formatada = format(parseISO(record?.data_inicio as string), 'dd/MM/yyyy');
	} catch (e) {}
	return <span>{formatada}</span>;
};

const SearchFieldDataFinal: React.FC<SearchFieldsProps> = ({ record }) => {
	let formatada = '';
	try {
		formatada = format(parseISO(record?.data_fim as string), 'dd/MM/yyyy');
	} catch (e) {}
	return <span>{formatada}</span>;
};

const CustomTextField = (props: SearchFieldsProps) => <TextField {...props} />;

const ReferenceFieldV2: React.FC<ReferenceFieldV2Props> = ({ children, ...rest }) => {
	return <ReferenceField {...rest}>{children}</ReferenceField>;
};

const ActionsField: React.FC<ActionsFieldProps> = ({ id }) => {
	const router = useHistory();
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
	const [modalExcluir, setOpenModalExcluir] = React.useState(false);

	const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleRedirectToEdit = () => {
		router.push(`/assembleias_virtuais/${id}`);
	};

	const handleRedirectToView = () => {
		router.push(`/assembleias_virtuais/${id}/visualizar`);
	};

	const handleRedirectToExclude = () => {
		setOpenModalExcluir(true);
	};

	return (
		<Box>
			<IconButton onClick={handleClick}>
				<ArrowDropDownIcon fontSize='medium' />
			</IconButton>
			<Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
				{/* <MenuItem onClick={handleRedirectToView}>
					<Box display='flex' gridGap='0.7em'>
						<VisibilityIcon color='primary' />
						<Typography>Visualizar</Typography>
					</Box>
				</MenuItem> */}
				<MenuItem onClick={handleRedirectToEdit}>
					<Box display='flex' gridGap='0.7em'>
						<CreateIcon color='primary' />
						<Typography>Editar</Typography>
					</Box>
				</MenuItem>
				<MenuItem onClick={handleRedirectToExclude}>
					<Box display='flex' gridGap='0.7em'>
						<DeleteIcon color='error' />
						<Typography>Excluir</Typography>
					</Box>
				</MenuItem>
			</Menu>
			<ModalConfirmarExclusaoAssembleia
				assembleiaId={id}
				open={modalExcluir}
				onClose={() => setOpenModalExcluir(false)}
			/>
		</Box>
	);
};

const TabelaAssembleias: React.FC<AssembleiaListProps> = (props) => {
	return (
		<Tabela rowClick={false} disableClickRow={true} {...props}>
			<FunctionField render={(record) => <ActionsField id={Number(record?.id)} />} label='Ações' />
			<ReferenceFieldV2
				id='reference-field-condominio'
				source='condominio'
				label='Condomínio'
				reference='condominios'
				link={false}
				sortBy='nome_condominio'
				filtro={
					<Autocompletar
						refName='nome'
						refResource='condominios'
						mostrarInativos={false}
						id='filtro-nome-condominio'
						label={undefined}
						source={undefined}
						opcoes={undefined}
					/>
				}
				minWidth='200px'
			>
				<TextField source='nome' />
			</ReferenceFieldV2>
			<CustomTextField source='assunto' label='ASSUNTO' filtro={<Padrao />} />
			<SearchFieldDataInicial source='data_inicio' label='DATA INÍCIO' filtro={<Data />} />
			<SearchFieldDataFinal source='data_fim' label='DATA FIM' filtro={<Data />} />
			<CustomTextField source='horario_inicio' label='HORÁRIO INICIAL' />
			<CustomTextField source='horario_final' label='HORÁRIO FINAL' />
		</Tabela>
	);
};

const CustomPagination = (props: AssembleiaListProps) => (
	<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 500]} {...props} />
);

export const AssembleiaList: React.FC<AssembleiaListProps> = (props) => {
	return (
		<List
			{...props}
			empty={false}
			bulkActionButtons={false}
			title='Assembleias'
			sort={{ field: 'condominio', order: 'ASC' }}
			perPage={10}
			pagination={<CustomPagination labelRowsPerPage='Assembleias por página' />}
		>
			<TabelaAssembleias />
		</List>
	);
};
