import React, { memo, useCallback, useContext, useEffect, useState } from 'react';
import {
	TextField,
	List,
	Pagination,
	ReferenceField,
	useListContext,
	TopToolbar,
	Button,
	ListBase,
	useDataProvider,
	useNotify,
} from 'react-admin';

import { useSelector } from 'react-redux';

import { TextField as TextFieldMUI } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import { sub, add, format, parseISO, set } from 'date-fns';

import { DateRangePicker } from 'materialui-daterange-picker-pt';

import { Autocompletar, SimplesAutocompletar } from '../../common/filtros/Autocompletar';
import { CurrencyField } from '../../common/CurrencyInput';
import { Decimal } from '../../common/filtros/Decimal';
import { ModalPagamentoLote, ModalPagamentoSimples } from './ModaisRealizarPagamento';
import { ModalContext, ModalContextProvider, CustomDialog } from '../../common/ModalContext';
import { ModalSizeEditableContext, ModalSizeEditableContextProvider } from '../../common/ModalSizeEditableContext';
import { Padrao } from '../../common/filtros/Padrao';
import { Tabela, TabelaRowContext } from '../../common/Tabela';

import { ListContasPagarContext, ListContasPagarContextProvider } from './ListContasPagarContext';
import ModalDesfazerPagamentos from './ModalDesfazerPagamento';
import ModalEditarContaAPagar from './ModalEditarContaAPagar';
import ModalLancamentoContaAPagar from './ModalLancamentoContaAPagar';
import { CondominiosContext } from '../../../context/CondominioContextProvider';
import { ModalPeriodoPickerWrapper } from '../../common/PeriodoSelect';

const useStyles = makeStyles((theme) => ({
	warningColor: {
		color: theme.palette.syndikosRed.main,
	},
}));

const TODAY = new Date();
TODAY.setHours(10, 0, 0);
const MINDATE = new Date(1990, 0, 1).setHours(10, 0, 0);

const formatDate = (date) => {
	if (!date || date.toString() === 'Invalid Date') return '';
	return format(date, 'dd/MM/yyyy');
};

const periodoOptions = [
	{
		label: 'Limpar',
		startDate: undefined,
		endDate: undefined,
	},
	{
		label: 'Mês atual',
		startDate: set(TODAY, { date: 1 }),
		endDate: sub(add(set(TODAY, { date: 1 }), { months: 1 }), { days: 1 }),
	},
	{
		label: 'Hoje',
		startDate: TODAY,
		endDate: TODAY,
	},
	{
		label: 'Ontem',
		startDate: sub(TODAY, { days: 1 }),
		endDate: sub(TODAY, { days: 1 }),
	},
	{
		label: 'Últimos 3 dias',
		startDate: sub(TODAY, { days: 3 }),
		endDate: TODAY,
	},
	{
		label: 'Últimos 5 dias',
		startDate: sub(TODAY, { days: 5 }),
		endDate: TODAY,
	},
	{
		label: 'Últimos 7 dias',
		startDate: sub(TODAY, { days: 7 }),
		endDate: TODAY,
	},
	{
		label: 'Últimos 15 dias',
		startDate: sub(TODAY, { days: 15 }),
		endDate: TODAY,
	},
	{
		label: 'Últimos 30 dias',
		startDate: sub(TODAY, { days: 30 }),
		endDate: TODAY,
	},
	{
		label: 'Últimos 60 dias',
		startDate: sub(TODAY, { days: 60 }),
		endDate: TODAY,
	},
	{
		label: 'Próximos 15 dias',
		startDate: TODAY,
		endDate: add(TODAY, { days: 15 }),
	},
	{
		label: 'Próximos 30 dias',
		startDate: TODAY,
		endDate: add(TODAY, { days: 30 }),
	},
	{
		label: 'Próximos 60 dias',
		startDate: TODAY,
		endDate: add(TODAY, { days: 60 }),
	},
];

const PeriodoPicker = memo(({ dateRange, open, setOpen, setDateRange }) => {
	const toggle = () => setOpen(!open);
	return (
		<ModalPeriodoPickerWrapper {...{ open, setOpen }}>
			<DateRangePicker
				open={true}
				toggle={toggle}
				onChange={(range) => setDateRange(range)}
				initialDateRange={
					dateRange?.startDate &&
					typeof dateRange.startDate !== 'number' &&
					dateRange.startDate.toString() === 'Invalid Date'
						? {
								label: 'custom',
								startDate: dateRange.startDate.setHours(10, 0, 0) && dateRange.startDate,
								endDate: dateRange.endDate.setHours(10, 0, 0) && dateRange.endDate,
						  }
						: dateRange
				}
				minDate={MINDATE}
				definedRanges={periodoOptions}
			/>
		</ModalPeriodoPickerWrapper>
	);
});

const PeriodoFilter = memo(({ source }) => {
	const { setFilters, filterValues } = useListContext();
	const [open, setOpen] = useState(false);
	const [dateRange, setDateRange] = useState(
		filterValues && filterValues[`${source}_after`] && filterValues[`${source}_before`]
			? {
					label: 'custom',
					startDate: parseISO(filterValues[`${source}_after`]).setHours(10, 0, 0),
					endDate: parseISO(filterValues[`${source}_before`]).setHours(10, 0, 0),
			  }
			: periodoOptions[0]
	);

	const handleRangeChange = useCallback(() => {
		const startDate = dateRange?.startDate ? format(dateRange.startDate, 'yyyy-MM-dd') : undefined;
		const endDate = dateRange?.endDate ? format(dateRange.endDate, 'yyyy-MM-dd') : undefined;
		if (filterValues[`${source}_after`] !== startDate || filterValues[`${source}_before`] !== endDate) {
			setFilters({
				...filterValues,
				[`${source}_after`]: startDate,
				[`${source}_before`]: endDate,
			});
		}
	}, [setFilters, filterValues, source, dateRange]);

	useEffect(handleRangeChange, [dateRange]);

	return (
		<>
			<TextFieldMUI
				onClick={() => setOpen(true)}
				fullWidth
				inputProps={{
					value: `${dateRange?.endDate ? `de ${formatDate(dateRange?.startDate)}` : ''} ${
						dateRange?.endDate ? `até ${formatDate(dateRange?.endDate)}` : ''
					}`,
				}}
				variant='standard'
				margin='normal'
				style={{ marginBottom: 0 }}
			/>
			<PeriodoPicker {...{ dateRange, open, setOpen, setDateRange }} />
		</>
	);
});

const DateField = (props) => (
	<span>{props?.record[props?.source] ? format(parseISO(props?.record[props?.source]), 'dd/MM/yyyy') : ''}</span>
);

const ValorField = ({ record, source, ...props }) =>
	record && (
		<CurrencyField
			{...props}
			prefix='R$ '
			minimumFractionDigits={2}
			value={record[source]}
			id={`${source}${record.id}`}
		/>
	);

const ValorPagoField = ({ record, source, ...props }) =>
	record?.situacao === 'PG' && <ValorField {...{ record, source, ...props }} />;

const SituacaoChip = ({ value, textColor, ...rest }) => (
	<Chip
		label={
			<Typography variant='caption' style={{ color: textColor }}>
				{value}
			</Typography>
		}
		size='small'
		clickable={false}
		style={{ background: '#f1f1f1' }}
		{...rest}
	/>
);

const opcoesSituacao = [
	{ id: 'PG', name: 'Paga' },
	{ id: 'EA', name: 'Em aberto' },
	{ id: 'VH', name: 'Vencendo hoje' },
	{ id: 'VN', name: 'Vencida' },
];

const SituacaoField = ({ record: { situacao } }) => {
	const theme = useTheme();
	const { data } = useListContext();
	const { rowRecord, setRowRecord } = useContext(TabelaRowContext);

	const update = () => {
		if (data && data[rowRecord.id]) {
			setRowRecord(data[rowRecord.id]);
		}
	};

	useEffect(update, [data]);

	switch (situacao) {
		case 'EA':
			return <SituacaoChip value='Em aberto' textColor={theme.palette.success.dark} />;
		case 'VH':
			return <SituacaoChip value='Vencendo hoje' textColor={theme.palette.warning.dark} />;
		case 'VN':
			return <SituacaoChip value='Vencida' textColor={theme.palette.syndikosRed.dark} />;
		case 'PG':
			return <SituacaoChip value='Paga' textColor={theme.palette.primary.dark} />;
		default:
			return <SituacaoChip value={situacao} />;
	}
};

const TextParcelaField = ({ record }) => {
	if (record) {
		if (record.quantidade_parcelas && record.quantidade_parcelas > 1) {
			const numero_parcela = `${record.numero_parcela}`.padStart(3, '0');
			const quantidade_parcelas = `${record.quantidade_parcelas}`.padStart(3, '0');
			return <span>{`${numero_parcela}/${quantidade_parcelas}`}</span>;
		} else {
			return <span>Única</span>;
		}
	} else {
		return <span></span>;
	}
};

const BotaoRealizarPagamentos = () => {
	const { parcelasPagas, parcelasEmAberto } = useContext(ListContasPagarContext);
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const disabled =
		!!parcelasPagas.length ||
		!parcelasEmAberto.length ||
		(parcelasEmAberto.length > 1
			? !parcelasEmAberto.reduce((result, next) => {
					if (result === false) return false;
					if (result.condominio_id !== next.condominio_id) return false;
					return next;
			  })
			: false);
	const dP = useDataProvider();
	const notify = useNotify();

	return (
		<Tooltip title={disabled ? 'Selecione somente parcelas não pagas de um mesmo condomínio' : ''}>
			<span>
				<Button
					label='Pagar Manualmente'
					disabled={disabled}
					onClick={() => {
						if (parcelasEmAberto.length === 1) {
							dP.getSimple('parcela', {
								id: parcelasEmAberto[0]['id'],
							})
								.then((response) => {
									const data = response?.data;
									if (data) {
										setModalValue((v) => ({
											...v,
											open: true,
											dialogBody: <ModalPagamentoSimples parcela={data} />,
										}));
									} else {
										throw new Error('Response retornado sem data');
									}
								})
								.catch((e) => {
									if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
									notify('Erro inesperado, tente recarregar a página', 'warning');
								});
						} else {
							setModalValue((v) => ({
								...v,
								open: true,
								dialogBody: <ModalPagamentoLote lote={parcelasEmAberto} />,
							}));
						}
					}}
				/>
			</span>
		</Tooltip>
	);
};

const BotaoRealizarPagamentosAutomaticos = () => {
	const { parcelasPagas, parcelasEmAberto } = useContext(ListContasPagarContext);
	const { setModalValue } = useContext(ModalSizeEditableContext);

	const parcelasIntegracao = parcelasEmAberto.filter((parcela) =>
		['364', '450'].includes(parcela.codigo_inst_financeira)
	);

	const parcelasPagarViaSistema = parcelasIntegracao.filter((parcela) => parcela.pagamento_via_sistema === true);

	const disabled =
		!!parcelasPagas.length ||
		!parcelasPagarViaSistema.length ||
		parcelasEmAberto.length !== parcelasPagarViaSistema.length ||
		(parcelasPagarViaSistema.length > 1
			? !parcelasPagarViaSistema.reduce((result, next) => {
					if (!result) return false;
					if (result.condominio_id !== next.condominio_id) return false;
					return next;
			  })
			: false);

	const dP = useDataProvider();
	const notify = useNotify();
	return (
		<Tooltip title={disabled ? 'Selecione somente parcelas não pagas de um mesmo condomínio' : ''}>
			<span>
				<Button
					label='Pagar Via Banco'
					disabled={disabled}
					onClick={() => {
						if (parcelasPagarViaSistema.length > 0) {
							parcelasPagarViaSistema.forEach((parcela) => {
								dP.updateOnAction('parcela', {
									id: parcela.id,
									data: {
										...parcela,
										action: 'pagamento_automatico',
										detail: true,
									},
								})
									.then((response) => {
										const status = response?.data.status;
										if (status) {
											notify(`${status}`, 'sucess');
										} else {
											throw new Error('Response retornado sem data');
										}
									})
									.catch((e) => {
										if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
										if (e?.response?.data?.id) return notify(`${e?.response?.data?.id}`, 'warning');
										if (e?.response?.data?.status)
											return notify(`${e?.response?.data?.status}`, 'warning');
										if (e?.response?.data?.error)
											return notify(`${e?.response?.data?.error}`, 'warning');
										notify(
											'Ocorreu algum erro ao processar sua requisição, por favor contate o suporte',
											'warning'
										);
									});
							});
						}
					}}
				/>
			</span>
		</Tooltip>
	);
};

const BotaoDesfazerPagamento = () => {
	const { parcelasPagas, parcelasEmAberto } = useContext(ListContasPagarContext);
	const { setModalValue } = useContext(ModalContext);
	const classes = useStyles();
	const disabled = !!parcelasEmAberto.length || !parcelasPagas.length;
	return (
		<Tooltip title={disabled ? 'Selecione somente parcelas pagas' : ''}>
			<span>
				<Button
					className={classes.warningColor}
					label='Desfazer pagamento(s)'
					disabled={disabled}
					onClick={() => {
						if (parcelasPagas.length)
							setModalValue((v) => ({
								...v,
								open: true,
								dialogBody: <ModalDesfazerPagamentos selectedIds={parcelasPagas} />,
							}));
					}}
				/>
			</span>
		</Tooltip>
	);
};

const BotaoEditarContaAPagar = ({ context, record }) => {
	const { setModalValue } = useContext(context);
	const dP = useDataProvider();
	const notify = useNotify();
	return (
		<Tooltip title=''>
			<span>
				<Button
					label='Editar Conta a Pagar'
					onClick={() => {
						dP.getOne('contaapagar', {
							id: record?.conta_a_pagar_id,
						})
							.then((response) => {
								const data = response?.data;
								if (data) {
									setModalValue((v) => ({
										...v,
										open: true,
										dialogBody: <ModalEditarContaAPagar context={context} record={data} />,
									}));
								} else {
									throw new Error('Response retornado sem data');
								}
							})
							.catch((e) => {
								if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
								notify('Erro inesperado, tente recarregar a página', 'warning');
							});
					}}
				/>
			</span>
		</Tooltip>
	);
};

const BulkActions = () => {
	const { data, selectedIds, onUnselectItems } = useListContext();
	const { parcelasSelecionadas, setParcelasSelecionadas, setParcelasPagas, setParcelasEmAberto } =
		useContext(ListContasPagarContext);

	useEffect(() => {
		if (selectedIds && selectedIds.length && selectedIds[0]) {
			setParcelasSelecionadas((v) => {
				const registros = selectedIds.reduce((registros, id) => {
					const registro = data[id];
					if (registro?.id) registros.push(registro);
					return registros;
				}, []);
				return registros;
			});
		} else {
			setParcelasSelecionadas([]);
			onUnselectItems();
		}
	}, [selectedIds, data, setParcelasSelecionadas, onUnselectItems]);

	useEffect(() => {
		const [listaCPG, listaCEA] = parcelasSelecionadas.reduce(
			([listaCPG, listaCEA], parcela) => {
				if (parcela.situacao === 'PG') {
					listaCPG.push(parcela);
				} else {
					listaCEA.push(parcela);
				}
				return [listaCPG, listaCEA];
			},
			[[], []]
		);
		setParcelasPagas(listaCPG);
		setParcelasEmAberto(listaCEA);
	}, [parcelasSelecionadas, setParcelasPagas, setParcelasEmAberto]);

	return (
		<>
			{parcelasSelecionadas.length === 1 && (
				<ModalSizeEditableContextProvider customDialogProps={{ minWidth: '80vw' }}>
					<BotaoEditarContaAPagar context={ModalSizeEditableContext} record={parcelasSelecionadas[0]} />
				</ModalSizeEditableContextProvider>
			)}
			<ModalContextProvider>
				<BotaoDesfazerPagamento />
				<CustomDialog />
			</ModalContextProvider>
			<ModalSizeEditableContextProvider customDialogProps={{ minWidth: '75vw' }}>
				<BotaoRealizarPagamentos />
			</ModalSizeEditableContextProvider>
			<BotaoRealizarPagamentosAutomaticos />
		</>
	);
};

const TabelaContasPagar = (props) => {
	const {
		basePath,
		filterValues: { condominio_id },
		onUnselectItems,
	} = useListContext();
	const sidebarOpen = useSelector((state) => state.admin.ui.sidebarOpen);
	const { setLastCondominioId } = useContext(ListContasPagarContext);
	const { setCondominioPorId } = useContext(CondominiosContext);

	useEffect(onUnselectItems, [basePath]);

	const updateLastCondominioId = useCallback(() => {
		if (condominio_id) {
			setCondominioPorId(condominio_id);
			setLastCondominioId(condominio_id);
		}
	}, [condominio_id, setLastCondominioId, setCondominioPorId]);
	useEffect(updateLastCondominioId, [condominio_id]);

	return (
		<div
			style={{
				overflowY: 'hidden',
				overflowX: 'scroll',
				width: 'auto',
				maxWidth: `calc(100vw - ${sidebarOpen ? '300px' : '95px'})`,
			}}
		>
			<Tabela {...props} disableClickRow>
				<ReferenceField
					source='condominio_id'
					reference='condominios'
					label='Condomínio'
					link={false}
					sortBy='condominio_nome'
					filter={{ situacao: 'A' }}
					filtro={<Autocompletar refName='nome' refResource='condominios' mostrarInativos={false} />}
					minWidth='200px'
				>
					<TextField source='nome' />
				</ReferenceField>
				<DateField source='data_vencimento' label='Vencimento' filtro={<PeriodoFilter />} minWidth='200px' />
				<DateField source='data_movimentacao' label='Pagamento' filtro={<PeriodoFilter />} minWidth='200px' />
				<SituacaoField
					source='situacao'
					label='Situação'
					filtro={<SimplesAutocompletar opcoes={opcoesSituacao} />}
					minWidth='165px'
				/>
				<TextField source='fornecedor_nome' label='Fornecedor' filtro={<Padrao />} minWidth='200px' />
				<ValorField source='valor_pendente' align='right' label='Valor' filtro={<Decimal />} minWidth='130px' />
				<ValorPagoField align='right' source='multa' label='Multa' filtro={<Decimal />} minWidth='130px' />
				<ValorPagoField align='right' source='juros' label='Juros' filtro={<Decimal />} minWidth='130px' />
				<ValorPagoField align='right' source='valor' label='Pago' filtro={<Decimal />} minWidth='130px' />
				<TextParcelaField source='parcela' label='Parcelas' filtro={<Padrao />} minWidth='120px' />
			</Tabela>
		</div>
	);
};

export const ListComponent = (props) => {
	const { setModalValue } = useContext(ModalSizeEditableContext);

	return (
		<List
			{...props}
			empty={false}
			bulkActionButtons={<BulkActions />}
			actions={
				<TopToolbar>
					<Button
						label='Nova conta a pagar'
						onClick={() => {
							setModalValue((v) => ({
								...v,
								open: true,
								dialogBody: <ModalLancamentoContaAPagar context={ModalSizeEditableContext} />,
							}));
						}}
						startIcon={<AddIcon />}
					/>
				</TopToolbar>
			}
			perPage={10}
			pagination={
				<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 500]} labelRowsPerPage='Contas por página' />
			}
			filterDefaultValues={{
				data_vencimento_after: format(periodoOptions[1].startDate, 'yyyy-MM-dd'),
				data_vencimento_before: format(periodoOptions[1].endDate, 'yyyy-MM-dd'),
				condominio_ativo: true,
			}}
		>
			<TabelaContasPagar />
		</List>
	);
};

export const ContasPagarList = (props) => {
	return (
		<ListContasPagarContextProvider>
			<ListBase
				{...props}
				resource='parcela'
				basePath='/parcela'
				match={{
					...props?.match,
					path: '/parcela',
					url: '/parcela',
				}}
			>
				<ModalSizeEditableContextProvider
					customDialogProps={{
						disableBackdropClick: true,
						disableEscapeKeyDown: true,
						minWidth: '80vw',
					}}
				>
					<ListComponent
						resource='parcela'
						basePath='/parcela'
						match={{
							...props?.match,
							path: '/parcela',
							url: '/parcela',
						}}
					/>
				</ModalSizeEditableContextProvider>
			</ListBase>
		</ListContasPagarContextProvider>
	);
};
