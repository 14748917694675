import React, { useContext } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { TextField, List, Pagination } from 'react-admin';
import { Tabela } from '../../../../common/Tabela';
import { CustomDialog, CustomDialogBody, ModalContext, ModalContextProvider } from '../../../../common/ModalContext';
import SearchIcon from '@material-ui/icons/Search';
import { NumberFormatBRL } from '../../../../common/CurrencyInput';
import { TooltipIconButton } from '../../../../common/TooltipIconButton';
import { format, parseISO } from 'date-fns';
import { ButtonClose } from '../../../../common/buttons/ButtonClose';
import { ArrecadacoesSimpleList } from '../Arrecadacoes/list';
import { ArrecadacoesContext } from '../../ArrecadacoesContext';
import { CondominiosContext } from '../../../../../context/CondominioContextProvider';
import { Padrao } from '../../../../common/filtros/Padrao';
import { Data } from '../../../../common/filtros/Data';
import { Autocompletar } from '../../../../common/filtros/Autocompletar';

const VencimentoField = ({ record }) => {
	let formatada = '';
	try {
		formatada = format(parseISO(record.vencimento), 'dd/MM/yyyy');
	} catch (e) {}
	return <span>{formatada}</span>;
};

const ValorField = ({ record, prefix = 'R$ ' }) => (
	<NumberFormatBRL
		displayType='text'
		value={record?.valor_total || 0}
		fixedDecimalScale
		onChange={() => {}}
		decimalScale={2}
		prefix={`${prefix}${(record?.valor_total || 0) < 0 ? '-' : ''}`}
	/>
);

const useStyles = makeStyles((theme) => ({
	warningColor: {
		color: theme.palette.syndikosRed.main,
	},
}));

const ModalVisualização = ({ record }) => {
	const { setModalValue } = useContext(ModalContext);
	const { condominioSelecionado } = useContext(CondominiosContext);
	const { id_identificacao_arrecadacao, referencia } = useContext(ArrecadacoesContext);
	const classes = useStyles();
	const responsavel_nome = record?.responsavel_nome ? (
		record.responsavel_nome
	) : (
		<span className={classes.warningColor}>Nenhum Responsável Registrado Nessa Data</span>
	);
	return (
		<CustomDialogBody
			title={
				<>
					<Typography variant='h6'>{'Detalhamento da Arrecadação'}</Typography>
					<Typography variant='h6'>
						{`${record?.tipo_unidade} ${record?.nome_unidade} - ${record?.responsavel}`} {responsavel_nome}
					</Typography>
				</>
			}
			customActions={<ButtonClose onClick={() => setModalValue((v) => ({ ...v, open: false }))} />}
			form={{
				component: (
					<ArrecadacoesSimpleList
						showActions={false}
						basePath='/arrecadacao_unidades'
						resource='arrecadacao_unidades'
						filter={{
							condominio_id: condominioSelecionado?.id,
							id_unidade_id: record?.unidade_id,
							id_identificacao_arrecadacao_id: id_identificacao_arrecadacao,
							referencia__month: referencia.substr(5, 2),
							referencia__year: referencia.substr(0, 4),
							responsavel: !record ? '' : record.responsavel.substr(0, 1),
							_separar_ext_ord: record?.separar_ext_ord || '',
						}}
					/>
				),
			}}
		/>
	);
};

const BotaoVisualizar = ({ record }) => {
	const { setModalValue } = useContext(ModalContext);
	const handleClick = () => {
		setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: <ModalVisualização record={record} />,
		}));
	};
	return (
		<TooltipIconButton onClick={handleClick} size='small' title='Consultar'>
			<SearchIcon />
		</TooltipIconButton>
	);
};

const AcoesField = ({ record }) => (
	<ModalContextProvider>
		<BotaoVisualizar {...{ record }} />
		<CustomDialog onClose={() => {}} />
	</ModalContextProvider>
);

const TabelaArrecadacaoUnidades = (props) => (
	<Tabela {...props} disableClickRow updateRowRecord>
		<AcoesField sortable={false} label='Ações' />
		<TextField source='tipo_unidade' label='Tipo' filtro={<Padrao />} />
		<TextField source='nome_grupo_unidade' label='Grupo' filtro={<Padrao />} />
		<TextField source='nome_unidade' label='Unidade' filtro={<Padrao />} />
		<TextField source='responsavel_nome' label='Nome' filtro={<Padrao />} />
		<TextField
			source='responsavel'
			label='Cobrança'
			filtro={
				<Autocompletar
					opcoes={[
						{ id: 'P', name: 'Proprietário' },
						{ id: 'I', name: 'Inquilino' },
					]}
				/>
			}
		/>
		<ValorField label='Total' source='valor_total' align='right' filtro={<Padrao />} />
		<VencimentoField label='Vencimento' source='vencimento' filtro={<Data />} />
	</Tabela>
);

export const ArrecadacaoUnidadeList = (props) => (
	<List
		{...props}
		empty={false}
		bulkActionButtons={false}
		filterDefaultValues={{ situacao: 'A' }}
		sort={{ field: 'nome_unidade', order: 'ASC' }}
		actions={false}
		perPage={10}
		pagination={
			<Pagination
				rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 500]}
				labelRowsPerPage='Arrecadações por página'
			/>
		}
	>
		<TabelaArrecadacaoUnidades />
	</List>
);
