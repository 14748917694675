import { ModalSizeEditableContext, ModalSizeEditableContextProvider } from 'components/common/ModalSizeEditableContext';
import { periodoOptions } from 'components/common/PeriodoSelect';
import { Tabela, TabelaRowContext } from 'components/common/Tabela';
import { SimplesAutocompletar } from 'components/common/filtros/Autocompletar';
import { Decimal } from 'components/common/filtros/Decimal';
import { Padrao } from 'components/common/filtros/Padrao';
import { ModalContextProvider, CustomDialog } from 'components/common/ModalContext';
import { NumberFormatBRL } from 'components/common/CurrencyInput';
import { format } from 'date-fns';
import React, { useContext, useEffect, createContext, useState } from 'react';
import { DialogActions, DialogContent, DialogTitle, Chip, Typography } from '@material-ui/core';
import { DataReferencia } from 'components/common/filtros/Data';
import {
	useListContext,
	TextField,
	List,
	ListBase,
	Pagination,
	TopToolbar,
	Button,
	Filter,
	useDataProvider,
	useNotify,
	useRefresh,
} from 'react-admin';
import { SyndikosSelect } from 'components/common/SyndikosSelect';
import { useSelector } from 'react-redux';
import { AcordosParcelasFluxoModaisContextProvider } from '../contexts/AcordosParcelasFluxoModaisContextProvider';
import AddIcon from '@material-ui/icons/Add';
import AcordosConfiguracaoModal from '../modais/AcordoParcelaConfiguracaoModal';
import AcordoValoresConfiguracaoModal from '../modais/AcordoConfiguracaoValoresModal';
import AcrescimosEDescontosModal from '../modais/AcrescimosEDescontosModal';
import ConfiguracaoParcelasModal from '../modais/ConfiguracaoParcelasModal';
import { InputDateReferencia } from 'components/common/InputDate';
import VeryHighLimitReferenceInput from 'components/common/VeryHighLimitReferenceInput';
import { CondominiosContext } from 'context/CondominioContextProvider';
import { ButtonRemove } from 'components/common/buttons/ButtonRemove';
import { useTheme } from '@material-ui/core/styles';

const excluirAcordosContext = createContext(undefined);

const TextParcelaField = ({ record }) => {
	if (record) {
		if (record?.quantidade_parcelas && record?.quantidade_parcelas > 1) {
			const numero_parcela = `${record.numero_parcela}`.padStart(3, '0');
			const quantidade_parcelas = `${record.quantidade_parcelas}`.padStart(3, '0');
			return <span>{`${numero_parcela}/${quantidade_parcelas}`}</span>;
		} else {
			return <span>Única</span>;
		}
	} else {
		return <span></span>;
	}
};

const CobrancaField = ({ record: { tipo_responsavel } }) => (
	<span>{tipo_responsavel === 'P' ? 'Proprietário' : 'Inquilino'}</span>
);

const opcoesSituacao = [
	{ id: 'PG', name: 'Pago' },
	{ id: 'NP', name: 'Não Provisionado' },
	{ id: 'PR', name: 'Provisionado' },
];

const opcoesResponsavel = [
	{ id: 'p', name: 'Proprietário' },
	{ id: 'i', name: 'Inquilino' },
];

const ValorField = ({ record }) => (
	<NumberFormatBRL
		decimalScale={2}
		displayType='text'
		fixedDecimalScale
		onChange={() => {}}
		prefix={`${record?.tipo_lancamento === 'PRC' ? '' : 'R$ '}${(parseFloat(record?.valor) || 0) < 0 ? '-' : ''}`}
		suffix={record?.tipo_lancamento === 'PRC' ? '%' : ''}
		value={record?.valor || 0}
	/>
);

const SelectInputWithoutEmpty = ({ allowEmpty, ...props }) => (
	<SyndikosSelect optionText='nome' translateChoice={false} disableClearable fullWidth {...props} />
);

const Filters = (props) => {
	const { onUnselectItems } = useListContext();
	const { setCondominioPorId } = useContext(CondominiosContext);
	const getMinWidth = () => {
		return window.innerWidth < 1500 ? '200px' : '23vw';
	};
	return (
		<Filter {...props}>
			<VeryHighLimitReferenceInput
				reference='condominios'
				label='Condomínio'
				source='id_condominio'
				variant='outlined'
				filter={{ situacao: 'A' }}
				style={{ 'min-width': getMinWidth() }}
				onChange={(newValue) => {
					setCondominioPorId(newValue);
					onUnselectItems();
				}}
				alwaysOn
			>
				<SelectInputWithoutEmpty />
			</VeryHighLimitReferenceInput>
			<InputDateReferencia
				style={{ 'min-width': getMinWidth() }}
				label='Referência Inicial'
				source='referencia_range_after'
				alwaysOn
				handleChange={(value, onChange) => {
					if (value && value.toString && value.toString() !== 'Invalid Date') {
						onChange(format(value, 'yyyy-MM-dd'));
					} else if (!value) {
						onChange(null);
					}
				}}
			/>
			<InputDateReferencia
				style={{ 'min-width': getMinWidth() }}
				label='Referência Final'
				source='referencia_range_before'
				alwaysOn
				handleChange={(value, onChange) => {
					if (value && value.toString && value.toString() !== 'Invalid Date') {
						onChange(format(value, 'yyyy-MM-dd'));
					} else if (!value) {
						onChange(null);
					}
				}}
			/>
		</Filter>
	);
};

const DateTextFieldReferencia = ({ source, record }) => (
	<span>
		{record[source]
			? new Date(`${record[source]}T10:00`).toLocaleDateString('pt-br', {
					year: 'numeric',
					month: '2-digit',
			  })
			: ''}
	</span>
);

const ModalExcluirAcordos = ({ setModalValue, registrosSelecionados }) => {
	const { onUnselectItems } = useListContext();
	const dp = useDataProvider();
	const [isLoading, setIsLoading] = useState(false);
	const notify = useNotify();
	const refresh = useRefresh();
	const handleExcluir = async () => {
		setIsLoading(true);
		try {
			const registrosComSituacaoProibida = Object.values(registrosSelecionados).filter(
				(registro) => registro.situacao === 'PV' || registro.situacao === 'PG'
			);

			if (registrosComSituacaoProibida.length > 0) {
				throw new Error('Parcelas pagas ou provisionadas não podem ser excluídas.');
			}

			const array_ids = Object.values(registrosSelecionados).map((registro) => registro.id);
			await dp.deleteMany('acordos', { ids: array_ids }).then((response) => {
				const responses = response?.data || [];
				const ExclusoesSucedidas = responses.filter((r) => r?.status === 'fulfilled');
				if (ExclusoesSucedidas?.length) {
					const msg =
						ExclusoesSucedidas.length > 1
							? `${ExclusoesSucedidas.length} arrecadações excluídas com sucesso`
							: '1 arrecadação excluída com sucesso';
				}
			});

			notify('Registros excluídos com sucesso', 'success');
			onUnselectItems();
			refresh();
			setModalValue((v) => ({ ...v, open: false }));
			setIsLoading(false);
			return;
		} catch (error) {
			notify(error.message || 'Erro ao excluir registros', 'warning');
			setModalValue((v) => ({ ...v, open: false }));
			setIsLoading(false);
			return;
		}
	};

	const handleCancelar = () => {
		setModalValue((v) => ({ ...v, open: false }));
	};
	return (
		<>
			<DialogTitle>Excluir Acordos</DialogTitle>
			<DialogContent>
				<p>Você tem certeza que deseja excluir os seguintes acordos?</p>
				<ul>
					{Object.values(registrosSelecionados).map((registro) => (
						<li>{registro.numero_sequencial_acordo}</li>
					))}
				</ul>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCancelar} disabled={isLoading}>
					<>Cancelar</>
				</Button>
				<ButtonRemove
					onClick={handleExcluir}
					disabled={isLoading || !Object.keys(registrosSelecionados).length}
				>
					<>{isLoading ? 'Excluindo...' : 'Excluir'}</>
				</ButtonRemove>
			</DialogActions>
		</>
	);
};

const BotaoExcluirAcordos = ({ registrosSelecionados }) => {
	const { setModalValue } = useContext(excluirAcordosContext);
	const theme = useTheme();
	return (
		<Button
			style={{ color: theme.red[200] }}
			onClick={() => {
				setModalValue((v) => ({
					...v,
					open: true,
					dialogBody: (
						<ModalExcluirAcordos
							registrosSelecionados={registrosSelecionados}
							setModalValue={setModalValue}
						/>
					),
				}));
			}}
		>
			{Object.keys(registrosSelecionados).length > 1 ? <>Excluir Acordos</> : <>Excluir Acordo</>}
		</Button>
	);
};

const BulkActions = () => {
	const { data, selectedIds, onUnselectItems } = useListContext();
	const [registrosSelecionados, setRegistrosSelecionados] = useState({});

	useEffect(() => {
		if (selectedIds.length && selectedIds[0]) {
			setRegistrosSelecionados((v) => {
				return selectedIds.reduce((registros, id) => {
					const registro = data[id] || v[id];
					if (registro?.id) registros[id] = registro;
					return registros;
				}, {});
			});
		} else {
			setRegistrosSelecionados({});
			onUnselectItems();
		}
	}, [selectedIds, data, setRegistrosSelecionados, onUnselectItems]);

	return (
		<>
			<ModalContextProvider Context={excluirAcordosContext} customDialogProps={{ minWidth: '45%' }}>
				<BotaoExcluirAcordos registrosSelecionados={registrosSelecionados} />
				<CustomDialog Context={excluirAcordosContext} />
			</ModalContextProvider>
		</>
	);
};

const SituacaoChip = ({ value, textColor, ...rest }) => (
	<Chip
		label={
			<Typography variant='caption' style={{ color: textColor }}>
				{value}
			</Typography>
		}
		size='small'
		clickable={false}
		style={{ background: '#f1f1f1' }}
		{...rest}
	/>
);

const SituacaoField = ({ record }) => {
	const theme = useTheme();
	const { data } = useListContext();
	const { rowRecord, setRowRecord } = useContext(TabelaRowContext);
	const situacao = record?.situacao;

	const update = () => {
		if (data && data[rowRecord.id]) {
			setRowRecord(data[rowRecord.id]);
		}
	};

	useEffect(update, [data]);

	switch (situacao) {
		case 'PG':
			return <SituacaoChip value='Pago' textColor={theme.blue[300]} />;
		case 'PV':
			return <SituacaoChip value='Provisionado' textColor={theme.palette.success.dark} />;
		case 'NP':
			return <SituacaoChip value='Não Provisionado' textColor={theme.yellow[300]} />;
		case 'CA':
			return <SituacaoChip value='Cancelada' textColor={theme.palette.syndikosRed.dark} />;
		default:
			return <SituacaoChip value={situacao} />;
	}
};

const TabelaAcordosParcelas = (props) => {
	const sidebarOpen = useSelector((state) => state.admin.ui.sidebarOpen);

	return (
		<div
			style={{
				overflowY: 'hidden',
				overflowX: 'scroll',
				width: 'auto',
				maxWidth: `calc(99.8vw - ${sidebarOpen ? '300px' : '95px'})`,
			}}
		>
			<Tabela {...props}>
				<TextField source='numero_sequencial_acordo' label='Número' filtro={<Padrao />} minWidth='200px' />
				<TextField source='nome_grupo_unidade' label='Grupo' filtro={<Padrao />} minWidth='200px' />
				<TextField source='nome_unidade' label='Unidade' filtro={<Padrao />} minWidth='200px' />
				<DateTextFieldReferencia
					source='referencia'
					label='referência'
					filtro={<DataReferencia />}
					minWidth='200px'
				/>
				<TextField source='identificacao_id' label='Identificação' filtro={<Padrao />} minWidth='200px' />
				<ValorField source='valor' align='right' label='Valor' filtro={<Decimal />} minWidth='130px' />
				<CobrancaField
					source='tipo_responsavel'
					label='Cobrança'
					filtro={<SimplesAutocompletar opcoes={opcoesResponsavel} />}
					minWidth='200px'
				/>
				<TextField source='responsavel' label='Responsável' filtro={<Padrao />} minWidth='200px' />
				<SituacaoField
					source='situacao'
					label='Status'
					filtro={<SimplesAutocompletar opcoes={opcoesSituacao} />}
					minWidth='165px'
				/>
				<TextParcelaField source='numero_parcela' label='Parcelas' filtro={<Padrao />} minWidth='120px' />
			</Tabela>
		</div>
	);
};

const ListComponent = (props) => {
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const [step, setStep] = useState('1A');

	const stepComponent = {
		'1A': (setStep) => <AcordosConfiguracaoModal setStep={setStep} />,
		'1B': (setStep) => <AcordoValoresConfiguracaoModal setStep={setStep} />,
		'1D': (setStep) => <ConfiguracaoParcelasModal setStep={setStep} />,
		'1C': (setStep) => <AcrescimosEDescontosModal setStep={setStep} />,
	};

	const handleOpenModal = () => {
		setStep('1A');
		setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: stepComponent[step](setStep),
		}));
	};

	useEffect(() => {
		setModalValue((v) => ({
			...v,
			dialogBody: stepComponent[step](setStep),
		}));
	}, [step]);

	return (
		<List
			{...props}
			empty={false}
			bulkActionButtons={<BulkActions />}
			actions={
				<TopToolbar>
					<Button label='Novo Acordo' onClick={handleOpenModal} startIcon={<AddIcon />} />
				</TopToolbar>
			}
			perPage={10}
			pagination={
				<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 500]} labelRowsPerPage='Acordos por página' />
			}
			filterDefaultValues={{
				data_vencimento_after: format(periodoOptions[1].startDate, 'yyyy-MM-dd'),
				data_vencimento_before: format(periodoOptions[1].endDate, 'yyyy-MM-dd'),
				condominio_ativo: true,
			}}
			filters={<Filters />}
		>
			<TabelaAcordosParcelas {...props} />
		</List>
	);
};

export const AcordoParcelasList = (props) => {
	return (
		<AcordosParcelasFluxoModaisContextProvider>
			<ListBase
				{...props}
				resource='acordos_parcelas'
				basePath='/acordos_parcelas'
				match={{
					...props?.match,
					path: '/acordos_parcelas',
					url: '/acordos_parcelas',
				}}
			>
				<ModalSizeEditableContextProvider
					customDialogProps={{
						disableBackdropClick: true,
						disableEscapeKeyDown: true,
						minWidth: '80vw',
					}}
				>
					<ListComponent
						{...props}
						resource='acordos_parcelas'
						basePath='/acordos_parcelas'
						match={{
							...props?.match,
							path: '/acordos_parcelas',
							url: '/acordos_parcelas',
						}}
					/>
				</ModalSizeEditableContextProvider>
			</ListBase>
		</AcordosParcelasFluxoModaisContextProvider>
	);
};
