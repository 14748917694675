import { addHours, eachMinuteOfInterval, format, parse } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import weekDayToString from '../utils/weekDaysToString';

const parseHourToDate = (hour) => parse(hour, 'H:mm:ss', new Date());

const dateToHourString = (date) => format(date, 'H:mm', { locale: ptBR });

export const isDateInPast = (groupedHour, date) => {
	try {
		/**
		 * Corta a string e pega o primeiro horario
		 * adiciona ':00' para tornar uma hora válida para conversão
		 * **/
		const firstHour = groupedHour.split(' - ')[0] + ':00';
		const _date = parse(firstHour, 'H:mm:ss', date);
		if (_date < new Date()) return true;

		return false;
	} catch {
		return false;
	}
};

export const getTaxaDeUsoAmbiente = (ambienteObject, diaSelecionado) => {
	const diaSemana = weekDayToString(diaSelecionado);

	if (!ambienteObject) return '';

	if (!ambienteObject.taxa_por_uso) return 'Isento';

	const dia = ambienteObject.dias.find((dia) => dia.dia === diaSemana);
	if (!dia || !dia.valor) return 'Isento';

	const valor = parseFloat(dia.valor);
	if (isNaN(valor)) return 'Isento';

	return (valor || 'R$ 0,00').toLocaleString('pt-br', {
		style: 'currency',
		currency: 'BRL',
	});
};

export const groupedHoursToObject = (horarios) => {
	return horarios.map((horario) => {
		if (horario.includes('/')) {
			const [inicio, fim] = horario.split(' / ')[0].split(' - ');
			return {
				inicio: `${inicio}:00`,
				fim: `${fim}:00`,
			};
		}
		const [inicio, fim] = horario.split(' - ');
		return {
			inicio: `${inicio}:00`,
			fim: `${fim}:00`,
		};
	});
};

export const fracaoToInterval = {
	HH: (hours) => _getGroupedIntervalFromHours(hours),
	DI: (hours) => _transformHoursToGroupedHour(hours),
	MP: (hours) => _transformHoursToGroupedHours(hours),
};

function _transformHoursToGroupedHours(hours) {
	if (hours.length === 2) {
		return [`${dateToHourString(hours[0])} - ${dateToHourString(hours[1])}`];
	}

	return [
		`${dateToHourString(hours[0])} - ${dateToHourString(hours[1])}`,
		`${dateToHourString(hours[2])} - ${dateToHourString(hours[3])}`,
	];
}

function _transformHoursToGroupedHour(hours) {
	if (hours.length === 2) {
		return [`${dateToHourString(hours[0])} - ${dateToHourString(hours[1])}`];
	}

	return [
		`${dateToHourString(hours[0])} - ${dateToHourString(hours[1])} / ${dateToHourString(
			hours[2]
		)} - ${dateToHourString(hours[3])}`,
	];
}

function _getGroupedIntervalFromHours(hours) {
	const primeiraConjuntoDeHoras = eachMinuteOfInterval(
		{
			start: hours[0],
			end: hours[1],
		},
		{ step: 60 }
	).map((hora) => {
		return `${dateToHourString(hora)} - ${addHoursThenFormatToHourString(hora)}`;
	});

	primeiraConjuntoDeHoras.pop();

	if (hours.length === 2) {
		return primeiraConjuntoDeHoras;
	}

	const segundoConjuntoDeHoras = eachMinuteOfInterval(
		{
			start: hours[2],
			end: hours[3],
		},
		{ step: 60 }
	).map((hora) => {
		return `${dateToHourString(hora)} - ${addHoursThenFormatToHourString(hora)}`;
	});

	segundoConjuntoDeHoras.pop();

	return [...primeiraConjuntoDeHoras, ...segundoConjuntoDeHoras];
}

function formatFracoes(datas) {
	return datas.reduce((previousValue, currentValue) => {
		return [...previousValue, parseHourToDate(currentValue.inicio), parseHourToDate(currentValue.fim)];
	}, []);
}

function formatHHReservados(datas) {
	return datas.reduce((previousValue, currentValue) => {
		return [
			...previousValue,
			`${dateToHourString(parseHourToDate(currentValue.inicio))} - ${dateToHourString(
				parseHourToDate(currentValue.fim)
			)}`,
		];
	}, []);
}

const addHoursThenFormatToHourString = (date, amountToAdd = 1) =>
	format(addHours(date, amountToAdd), 'H:mm', { locale: ptBR });

export const formatFracoesThenGetInterval = {
	HH: (datas) => {
		return formatHHReservados(datas);
	},
	MP: (datas) => {
		return fracaoToInterval['MP'](formatFracoes(datas));
	},
	DI: (datas) => {
		return fracaoToInterval['DI'](formatFracoes(datas));
	},
};

export const DIAS_DA_SEMANA = {
	SEG: 'Segunda-feira',
	TER: 'Terça-feita',
	QUA: 'Quarta-feira',
	QUI: 'Quinta-feira',
	SEX: 'Sexta-feira',
	SAB: 'Sábado',
	DOM: 'Domingo',
};

export const TIPO_RESERVA = {
	HH: 'Hora em hora',
	MP: 'Meio período',
	DI: 'Dia inteiro',
};
