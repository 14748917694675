import React from 'react';
import { ResourceContextProvider, List, Pagination, TextField } from 'react-admin';
import { Tabela } from '../../common/Tabela';
import { Padrao } from '../../common/filtros/Padrao';
import { Autocompletar } from '../../common/filtros/Autocompletar';
import { Data } from '../../common/filtros/Data';
import PainelExpandivelEnderecoResponsavel from '../../common/PainelExpandivelEnderecoResponsavel';

const DataField = ({ record, source }) => {
	const data = new Date(`${record && record[source]}T10:00`);
	return (
		<span>
			{`${data}` !== 'Invalid Date'
				? data.toLocaleDateString('pt-br', {
						year: 'numeric',
						month: '2-digit',
						day: '2-digit',
				  })
				: ''}
		</span>
	);
};

const TipoField = ({ record }) => {
	switch (record?.tipo) {
		case 'P':
			return <span>Proprietário</span>;
		case 'I':
			return <span>Inquilino</span>;
		default:
			return <span>Não Informado</span>;
	}
};

const TabelaHistorico = ({ ...props }) => (
	<Tabela {...props} expand={<PainelExpandivelEnderecoResponsavel />}>
		<TextField source='nome_condominio' label='Condomínio' filtro={<Padrao />} />
		<TipoField
			source='tipo'
			label='Vínculo'
			filtro={
				<Autocompletar
					opcoes={[
						{ id: 'I', name: 'Inquilino' },
						{ id: 'P', name: 'Proprietário' },
					]}
				/>
			}
		/>
		<TextField source='nome_grupo_unidade' label='Grupo' filtro={<Padrao />} />
		<TextField source='nome_unidade' label='Unidade' filtro={<Padrao />} />
		<DataField source='data_entrada' label='Entrada' filtro={<Data custom='data_vigencia_after' />} />
		<DataField source='data_saida' label='Saída' filtro={<Data custom='data_vigencia_before' />} />
	</Tabela>
);

export const ListaHistoricoPessoa = (props) => {
	return (
		<ResourceContextProvider resource='historico_pessoa'>
			<List
				{...props}
				basePath='/historico_pessoa'
				resource='historico_pessoa'
				title=' '
				bulkActionButtons={false}
				empty={false}
				perPage={10}
				pagination={
					<Pagination
						rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 500]}
						labelRowsPerPage='Pessoas por página'
					/>
				}
				actions={null}
				filters={false}
				filter={props.edit ? { id_pessoa: props.id } : { data_vigencia_before: '1900-01-01' }}
				sort={{ field: 'data_entrada', order: 'DESC' }}
			>
				<TabelaHistorico {...props} disableClickRow />
			</List>
		</ResourceContextProvider>
	);
};
