import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useDataProvider, useNotify } from 'react-admin';

import { Box, TextField } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { BotaoSalvar } from '../../../common/buttons/BotaoSalvar';
import { ButtonCancel } from '../../../common/buttons/ButtonCancel';
import { ModalSizeEditableContext, CustomDialogBodySizeEditable } from '../../../common/ModalSizeEditableContext';
import { NumberFormatBRL, sanitizeListNumberFormatBRLProps } from '../../../common/CurrencyInput';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Checkbox,
	Button,
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { SimplesSyndikosSelect } from '../../../common/SyndikosSelect';
import { LancamentoContaPagarContext } from '../LancamentoContaPagarContext';
import ModalConfirmacao from 'components/common/ModalConfirmacao';
import { BoxAddContaDespesa } from './BoxAddContaDespesa';
import { getImpostos } from '../../configuracoes/ConfiguracaoDosImpostos/utils/index';
import decimalAdjust from '../../../common/decimalAdjust';

export const FormDespesaContext = createContext();

const FormDespesaContextProvider = ({ children, context, record, edit = false }) => {
	const { selectedCondominio, despesas, setDespesas } = useContext(LancamentoContaPagarContext);
	const notify = useNotify();
	const { setModalValue } = useContext(context);
	const [contasDespesaData, setContasDespesaData] = useState([]);
	const [contaDespesa, setContaDespesa] = useState(record.conta_despesa);
	const [quantidade, setQuantidade] = useState(record.quantidade);
	const [valorUnitario, setValorUnitario] = useState(record.valor_unitario || 0);
	const [valorTotalBruto, setValorTotalBruto] = useState(record.valor_total_bruto);
	const [tiposLancamentoData] = useState([
		{ id: 'C', nome: 'Condomínio' },
		{ id: 'G', nome: 'Grupo' },
	]);
	const [tipoLancamento, setTipoLancamento] = useState(record.tipo_lancamento);
	const [gruposData, setGruposData] = useState([]);
	const [grupo, setGrupo] = useState(record.grupo);
	const [impostosSelecionados, setImpostosSelecionados] = useState(record?.impostos || []);
	const [erros, setErros] = useState({});
	const [disabledLancar, setDisabledLancar] = useState(false);
	const closeModal = useCallback(() => {
		setModalValue((v) => ({ ...v, open: false }));
	}, [setModalValue]);
	const dP = useDataProvider();

	const handleSave = useCallback(
		(incluirOutro = false) => {
			if (erros && Object.values(erros).length) {
				notify(Object.values(erros)[0], 'warning');
			} else {
				setDisabledLancar(true);
				if (edit) {
					setDespesas((v) =>
						v.map((d) => {
							if (d.codigo !== record.codigo) return d;
							const impostos = impostosSelecionados.map((imposto) => {
								return {
									nome: imposto.nome,
									aliquota: imposto.aliquota,
									conta_despesa: imposto.conta_despesa,
									fornecedor: imposto.fornecedor,
									despesa: d.id,
									dia_vencimento: imposto.dia_vencimento,
									valor: parseFloat((valorTotalBruto * (imposto.aliquota / 100)).toFixed(2)),
								};
							});

							return {
								...d,
								grupo: tipoLancamento?.id === 'G' ? grupo : undefined,
								tipo_lancamento: tipoLancamento,
								quantidade: quantidade,
								valor_unitario: valorUnitario,
								valor_total_bruto: valorTotalBruto,
								valor_total: parseFloat(
									valorTotalBruto -
										decimalAdjust(
											'round',
											impostos.reduce((total, imposto) => total + imposto.valor, 0),
											-2
										).toFixed(2)
								),
								conta_despesa: contaDespesa,
								impostos: impostos,
							};
						})
					);
					notify('Despesa alterada com sucesso');
				} else {
					setDespesas((v) => {
						const impostos = impostosSelecionados.map((imposto) => {
							return {
								nome: imposto.nome_display,
								aliquota: imposto?.aliquota,
								dia_vencimento: imposto?.dia_vencimento,
								conta_despesa: imposto?.conta_despesa,
								fornecedor: imposto?.fornecedor,
								valor: parseFloat(
									(valorTotalBruto * (imposto ? imposto.aliquota / 100 : 0)).toFixed(2)
								),
							};
						});

						return [
							...v,
							{
								condominio: selectedCondominio?.id,
								codigo: despesas.length + 1,
								grupo: tipoLancamento?.id === 'G' ? grupo : undefined,
								tipo_lancamento: tipoLancamento,
								quantidade: quantidade,
								valor_unitario: valorUnitario,
								valor_total_bruto: valorTotalBruto,
								valor_total:
									valorTotalBruto -
									decimalAdjust(
										'round',
										impostos.reduce((total, imposto) => total + imposto.valor, 0),
										-2
									),
								conta_despesa: contaDespesa,
								impostos: impostos,
							},
						];
					});
					notify('Despesa cadastrada com sucesso');
				}
				if (!incluirOutro) {
					setModalValue((v) => ({ ...v, open: false }));
				} else {
					setValorUnitario(0);
					setDisabledLancar(false);
				}
			}
		},
		[
			selectedCondominio,
			despesas.length,
			tipoLancamento,
			grupo,
			quantidade,
			valorUnitario,
			valorTotalBruto,
			contaDespesa,
			impostosSelecionados,
			erros,
			notify,
			setDespesas,
			setModalValue,
			edit,
			record.codigo,
			setDisabledLancar,
		]
	);

	const init = () => {
		dP.getList('grupo_unidade_condominio', {
			pagination: { perPage: 10000, page: 1 },
			sort: { field: 'id', order: 'ASC' },
			filter: { situacao: 'A', id_condominio: selectedCondominio?.id },
		})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				return {};
			})
			.then((response) => {
				const data = response?.data;
				if (data && data.length && data[0]?.id) {
					setGrupo((defaultG) => data.find((g) => g.id === defaultG?.id) || data[0]);
					setGruposData(data);
				}
			});
	};

	const updateValorTotalBruto = () => {
		setValorTotalBruto((valorUnitario || 0) * (quantidade || 0));
	};

	useEffect(init, [dP]);
	useEffect(updateValorTotalBruto, [valorUnitario, quantidade]);

	const FormDespesaProviderValue = useMemo(
		() => ({
			contasDespesaData,
			contaDespesa,
			setContaDespesa,
			quantidade,
			setQuantidade,
			valorUnitario,
			setValorUnitario,
			valorTotalBruto,

			tiposLancamentoData,
			tipoLancamento,
			setTipoLancamento,
			gruposData,
			grupo,
			setGrupo,
			impostosSelecionados,
			setImpostosSelecionados,

			erros,
			setErros,
			handleSave,
			closeModal,
			selectedCondominio,
			disabledLancar,
		}),
		[
			contasDespesaData,
			contaDespesa,
			setContaDespesa,
			quantidade,
			setQuantidade,
			valorUnitario,
			setValorUnitario,
			valorTotalBruto,

			tiposLancamentoData,
			tipoLancamento,
			setTipoLancamento,
			gruposData,
			grupo,
			setGrupo,
			impostosSelecionados,
			setImpostosSelecionados,

			erros,
			setErros,
			handleSave,
			closeModal,
			selectedCondominio,
			disabledLancar,
		]
	);

	return <FormDespesaContext.Provider value={FormDespesaProviderValue}>{children}</FormDespesaContext.Provider>;
};

export const BaseSelect = ({
	optionText = 'nome',
	label = '',
	isRequired = true,
	value = null,
	data = [],
	setValue = () => {},
	disabled = false,
	disableClearable = true,
	error,
}) => (
	<SimplesSyndikosSelect
		variant='outlined'
		size='small'
		optionText={optionText}
		label={label}
		isRequired={isRequired}
		value={value}
		choices={data}
		getOptionSelected={(option, newValue) => option.id === newValue?.id}
		onChange={(newValueID) => {
			setValue((data || []).find((op) => op.id === newValueID) || null);
		}}
		disabled={disabled}
		error={error}
		disableClearable={disableClearable}
		style={{ marginTop: '0px' }}
		touched={true}
		InputLabelProps={{
			shrink: true,
		}}
		fullWidth
	/>
);

const TipoLancamentoSelect = () => {
	const { tiposLancamentoData, tipoLancamento, setTipoLancamento, erros, setErros } = useContext(FormDespesaContext);
	const validate = () => {
		if (!tipoLancamento) {
			setErros((e) => ({
				...e,
				tipo_lancamento: 'Selecione um tipo de lançamento',
			}));
		} else if (tipoLancamento && !tiposLancamentoData.find((c) => c.id === tipoLancamento?.id)) {
			setErros((e) => ({
				...e,
				tipo_lancamento: 'Selecione um tipo de lançamento válido',
			}));
		} else if (erros?.tipo_lancamento) {
			setErros((e) => {
				const { tipo_lancamento, ...rest } = e;
				return rest;
			});
		}
	};
	useEffect(validate, [tipoLancamento]);

	return (
		<BaseSelect
			label='Tipo de Lançamento'
			value={tipoLancamento}
			data={tiposLancamentoData}
			setValue={setTipoLancamento}
			error={erros?.tipo_lancamento}
		/>
	);
};

const GrupoSelect = () => {
	const { gruposData, grupo, setGrupo, tipoLancamento, erros, setErros, selectedCondominio } =
		useContext(FormDespesaContext);
	const validate = () => {
		if (tipoLancamento && tipoLancamento?.id === 'G') {
			if (!grupo) {
				setErros((e) => ({
					...e,
					grupo: 'Selecione um grupo',
				}));
			} else if (grupo && selectedCondominio.id !== grupo.id_condominio) {
				setErros((e) => ({
					...e,
					grupo: 'Selecione um grupo que pertença ao condomínio selecionado',
				}));
			} else if (grupo && !gruposData.find((g) => g.id === grupo.id)) {
				setErros((e) => ({
					...e,
					grupo: 'Selecione um grupo válido',
				}));
			} else if (erros?.grupo) {
				setErros((e) => {
					const { grupo, ...rest } = e;
					return rest;
				});
			}
		} else if (erros?.grupo) {
			setErros((e) => {
				const { grupo, ...rest } = e;
				return rest;
			});
		}
	};
	useEffect(validate, [tipoLancamento, gruposData, grupo]);

	return (
		<BaseSelect
			label='Grupo'
			value={tipoLancamento?.id === 'G' ? grupo : null}
			data={gruposData}
			setValue={setGrupo}
			error={erros?.grupo}
			disabled={tipoLancamento?.id !== 'G'}
		/>
	);
};

const QuantidadeField = () => {
	const { quantidade, setQuantidade, erros, setErros } = useContext(FormDespesaContext);

	const validate = () => {
		if (typeof quantidade !== 'number' && !quantidade) {
			setErros((e) => ({
				...e,
				quantidade: 'Forneça a quantidade adquirida da despesa',
			}));
		} else if (!quantidade) {
			setErros((e) => ({
				...e,
				quantidade: 'A quantidade deve ser maior que zero',
			}));
		} else if (erros?.quantidade) {
			setErros((e) => {
				const { quantidade, ...rest } = e;
				return rest;
			});
		}
	};
	useEffect(validate, [quantidade]);

	return (
		<TextField
			id='despesa-quantidade'
			size='small'
			margin='dense'
			label='Quantidade'
			type='number'
			value={quantidade}
			onChange={(event) => {
				const newValue = parseInt(event?.target?.value);
				setQuantidade(isNaN(newValue) || newValue < 1 ? 1 : newValue);
			}}
			error={erros?.quantidade}
			helperText={erros?.quantidade}
			isRequired={true}
			fullWidth
		/>
	);
};

const ValorField = ({
	label = 'Valor',
	value = 0,
	onChange = () => {},
	disabled = false,
	isRequired = false,
	error,
}) => (
	<TextField
		size='small'
		margin='dense'
		{...{
			label,
			value,
			disabled,
			onChange,
			isRequired,
		}}
		inputProps={{
			...sanitizeListNumberFormatBRLProps({
				prefix: 'R$ ',
			}),
			style: { textAlign: 'right' },
		}}
		InputProps={{
			inputComponent: NumberFormatBRL,
		}}
		error={!!error}
		helperText={error}
		align='right'
		fullWidth
	/>
);

const ValorUnitarioField = () => {
	const { valorUnitario, setValorUnitario, erros, setErros } = useContext(FormDespesaContext);

	const validate = () => {
		if (typeof valorUnitario !== 'number' && !valorUnitario) {
			setErros((e) => ({
				...e,
				valor_unitario: 'Forneça o valor unitário da despesa',
			}));
		} else if (valorUnitario <= 0) {
			setErros((e) => ({
				...e,
				valor_unitario: 'O valor unitário da despesa deve ser maior que zero',
			}));
		} else if (erros?.valor_unitario) {
			setErros((e) => {
				const { valor_unitario, ...rest } = e;
				return rest;
			});
		}
	};
	useEffect(validate, [valorUnitario]);

	return (
		<ValorField
			label='Valor Unitário'
			value={valorUnitario}
			onChange={(event) => {
				const newValue = event?.target?.value;
				setValorUnitario(newValue);
			}}
			isRequired={true}
		/>
	);
};

const ValorTotalBrutoField = () => {
	const { valorTotalBruto } = useContext(FormDespesaContext);
	return <ValorField label='Valor Bruto' value={valorTotalBruto} disabled={true} />;
};

const FormNovaDespesa = () => {
	return (
		<Box display='flex'>
			<Box flex={1}>
				<Box display='flex' style={{ marginTop: '0px' }}>
					<Box flex={1} mr='1em'>
						<BoxAddContaDespesa />
					</Box>
					<Box flex={1} mr='1em'>
						<QuantidadeField />
					</Box>
					<Box flex={1} mr='1em'>
						<ValorUnitarioField />
					</Box>
					<Box flex={1} mr='1em'>
						<ValorTotalBrutoField />
					</Box>
				</Box>
				<Box display='flex' mt='5px'>
					<Box flex={1} mr='1em'>
						<TipoLancamentoSelect />
					</Box>
					<Box flex={1} mr='1em'>
						<GrupoSelect />
					</Box>
					<Box flex={1} mr='1em'></Box>
					<Box flex={1} mr='1em'></Box>
				</Box>
				<Box display='flex' mt='10px'></Box>
			</Box>
		</Box>
	);
};

const BotaoSalvarEIncluirOutro = ({ onClick, disabled }) => (
	<BotaoSalvar startIcon={<AddCircleOutlineIcon />} onClick={() => onClick()} disabled={disabled}>
		Lançar e Nova
	</BotaoSalvar>
);

const CustomActions = ({ edit, setStep }) => {
	const { closeModal, handleSave, disabledLancar, impostosSelecionados } = useContext(FormDespesaContext);

	return (
		<>
			{!edit && impostosSelecionados?.length > 0 && (
				<Button startIcon={<ChevronLeftIcon />} size='small' variant='text' onClick={() => setStep('1A')}>
					Voltar
				</Button>
			)}
			<ButtonCancel onClick={closeModal} />
			{!edit && <BotaoSalvarEIncluirOutro onClick={() => handleSave(true)} disabled={disabledLancar} />}
			<BotaoSalvar onClick={() => handleSave()} disabled={disabledLancar}>
				{edit ? 'Salvar' : 'Lançar'}
			</BotaoSalvar>
		</>
	);
};

const CustomActionsImposto = ({ setStep }) => {
	const { closeModal } = useContext(FormDespesaContext);
	return (
		<>
			<ButtonCancel
				onClick={() => {
					closeModal();
				}}
			/>
			<Button startIcon={<ChevronRightIcon />} size='small' variant='text' onClick={() => setStep('1B')}>
				Avançar
			</Button>
		</>
	);
};
const ModalIncluirNovaDespesa = ({ context, setStep }) => {
	return (
		<CustomDialogBodySizeEditable
			title='Incluir Nova Despesa'
			form={{ component: <FormNovaDespesa /> }}
			customActions={<CustomActions setStep={setStep} />}
		/>
	);
};

export const ModalEditarDespesa = ({ context, record }) => {
	return (
		<FormDespesaContextProvider context={context} record={record} edit={true}>
			<CustomDialogBodySizeEditable
				title='Editar Despesa'
				form={{ component: <FormNovaDespesa /> }}
				customActions={<CustomActions edit={true} />}
			/>
		</FormDespesaContextProvider>
	);
};

export const ModalIncluirNovaDespesaImpostoForm = ({ setStep }) => {
	const { selectedCondominio, fornecedor } = useContext(LancamentoContaPagarContext);
	const { impostosSelecionados, setImpostosSelecionados } = useContext(FormDespesaContext);
	const [impostos, setImpostos] = useState([]);
	const [selectAll, setSelectAll] = useState(true);
	const dp = useDataProvider();

	useEffect(() => {
		setImpostos([]);
		getImpostos(dp, setImpostos, selectedCondominio.id, fornecedor.id);
	}, [selectedCondominio]);

	const handleCheckboxChange = (imposto) => {
		setImpostosSelecionados((prevSelectedItems) => {
			const isSelected = prevSelectedItems.find((item) => item.id === imposto.id);
			if (isSelected) {
				return prevSelectedItems.filter((item) => item.id !== imposto.id);
			} else {
				return [...prevSelectedItems, imposto];
			}
		});
	};

	const handleSelectAllChange = () => {
		if (!selectAll) {
			setImpostosSelecionados(impostos);
		} else {
			setImpostosSelecionados([]);
		}
		setSelectAll(!selectAll);
	};

	useEffect(() => {
		setImpostosSelecionados(impostos);
	}, [impostos]);

	const isSelected = (id) => impostosSelecionados.some((item) => item.id === id);
	return impostosSelecionados.length > 0 ? (
		<CustomDialogBodySizeEditable
			title='Deseja atribuir a retenção de imposto?'
			form={{
				component: (
					<TableContainer component={Paper}>
						<Table aria-label='simple table'>
							<TableHead>
								<TableRow>
									<TableCell>
										<Checkbox checked={selectAll} onChange={handleSelectAllChange} />
									</TableCell>
									<TableCell>Imposto</TableCell>
									<TableCell>Aliquota</TableCell>
									<TableCell>Vencimento</TableCell>
									<TableCell>Conta</TableCell>
									<TableCell>Fornecedor</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{impostos.map((row) => (
									<TableRow key={row.id}>
										<TableCell>
											<Checkbox
												checked={isSelected(row.id)}
												onChange={() => handleCheckboxChange(row)}
											/>
										</TableCell>
										<TableCell style={{ textTransform: 'none' }}>{row.nome_display}</TableCell>
										<TableCell style={{ textTransform: 'none' }}>{row.aliquota} %</TableCell>
										<TableCell style={{ textTransform: 'none' }}>
											Dia {row.dia_vencimento}
										</TableCell>
										<TableCell style={{ textTransform: 'none' }}>
											{row.conta_despesa_nome}
										</TableCell>
										<TableCell style={{ textTransform: 'none' }}>{row.fornecedor_nome}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				),
			}}
			customActions={<CustomActionsImposto setStep={setStep} />}
		/>
	) : (
		<CustomDialogBodySizeEditable
			title='Incluir Nova Despesa'
			form={{ component: <FormNovaDespesa /> }}
			customActions={<CustomActions setStep={setStep} />}
		/>
	);
};

export const ModalExcluirDespesa = ({ record }) => {
	const { setDespesas } = useContext(LancamentoContaPagarContext);
	const notify = useNotify();
	const { setModalValue } = useContext(ModalSizeEditableContext);
	return (
		<ModalConfirmacao
			Context={ModalSizeEditableContext}
			title='Tem certeza que deseja excluir esta despesa?'
			handleSubmit={() => {
				setDespesas((despesas) =>
					despesas.filter((d) => d.codigo !== record?.codigo).map((d, i) => ({ ...d, codigo: i + 1 }))
				);
				notify('Despesa excluída da tabela com sucesso');
				setModalValue((v) => ({
					...v,
					open: false,
				}));
			}}
		/>
	);
};

export const ModalIncluirNovaDespesaImposto = ({ context }) => {
	const [step, setStep] = useState('1A');

	const currentModal = {
		'1A': <ModalIncluirNovaDespesaImpostoForm setStep={setStep} />,
		'1B': <ModalIncluirNovaDespesa setStep={setStep} />,
	};

	return (
		<FormDespesaContextProvider
			context={context}
			record={{
				conta_despesa: null,
				quantidade: 1,
				valor_unitario: 0,
				valor_total_bruto: 0,
				tipo_lancamento: { id: 'C', nome: 'Condomínio' },
				grupo: null,
			}}
		>
			{currentModal[step]}
		</FormDespesaContextProvider>
	);
};
