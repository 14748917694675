import React from 'react';

import { TextField as RATextField, useListContext } from 'react-admin';

import { CondominiosContext } from 'context/CondominioContextProvider';
import { Tabela } from 'components/common/Tabela';
import { Autocompletar } from 'components/common/filtros/Autocompletar';
import { Data } from 'components/common/filtros/Data';
import { Padrao } from 'components/common/filtros/Padrao';
import { CustomDialog, ModalContextProvider } from 'components/common/ModalContext';
import { ArrecadacoesContext } from '../../../ArrecadacoesContext';
import {
	ContaReceberNumberField,
	DateField,
	TipoResponsavelField,
	ResponsavelField,
} from '../../../ContasReceberArrecadacoesList';
import {
	TipoSituacaoField,
	SituacaoBoletoField,
	MeioEntregaBoletoField,
	opcoesFiltroDisponibilizacaoBoleto,
	DisponivelKondomynosField,
	ResponsavelEmailField,
	VisualizarField,
} from './colunas';

const TabelaContasReceberBoletos = () => {
	const { onUnselectItems } = useListContext();
	const { referencia, id_identificacao_arrecadacao } = React.useContext(ArrecadacoesContext);
	const { condominioSelecionado } = React.useContext(CondominiosContext);

	React.useEffect(onUnselectItems, [condominioSelecionado, referencia, id_identificacao_arrecadacao]);

	return (
		<ModalContextProvider>
			<Tabela hasBulkActions disableClickRow>
				<TipoSituacaoField
					source='tipo_situacao'
					label='Situação E-mail'
					filtro={
						<Autocompletar
							opcoes={[
								{ id: null, name: 'Envio Pendente' },
								{ id: 1, name: 'Enviado' },
								{ id: 2, name: 'Falha ao Enviar' },
								{ id: 3, name: 'Sem E-mail' },
								{ id: 4, name: 'Na fila de envio' },
							]}
						/>
					}
				/>
				<SituacaoBoletoField
					source='situacao_boleto'
					label='Situação Boleto'
					filtro={
						<Autocompletar
							opcoes={[
								{ id: 'NG', name: 'Não Gerado' },
								{ id: 'SG', name: 'Processando' },
								{ id: 'G', name: 'Gerado' },
								{ id: 'I', name: 'Indisponível' },
							]}
						/>
					}
				/>
				<MeioEntregaBoletoField
					source='meio_de_disponibilizacao_de_boleto'
					label='Entrega de boletos'
					filtro={<Autocompletar opcoes={opcoesFiltroDisponibilizacaoBoleto} />}
				/>
				<DisponivelKondomynosField
					source='disponivel_kondomynos'
					label='Disponível'
					align='center'
					filtro={
						<Autocompletar
							opcoes={[
								{ id: '0', name: 'Não disponível' },
								{ id: '1', name: 'Disponível' },
							]}
						/>
					}
				/>
				<DateField source='data_envio' label='Data de envio' filtro={<Data />} />
				<RATextField source='nome_grupo_unidade' label='Grupo' filtro={<Padrao />} />
				<RATextField source='nome_unidade' label='Unidade' filtro={<Padrao />} />
				<ResponsavelField source='pagador_nome' label='Responsável' filtro={<Padrao />} />
				<TipoResponsavelField
					source='tipo_responsavel'
					label='Cobrança'
					filtro={
						<Autocompletar
							opcoes={[
								{ id: 'P', name: 'Proprietário' },
								{ id: 'I', name: 'Inquilino' },
							]}
						/>
					}
				/>
				<RATextField source='nosso_numero' label='Nosso Número' filtro={<Padrao />} />
				<DateField source='data_vencimento' label='Vencimento' filtro={<Data />} />
				<ContaReceberNumberField source='valor_pendente' label='Valor' align='right' filtro={<Padrao />} />
				<ResponsavelEmailField source='pagador_email' label='E-mail' filtro={<Padrao />} />
				<VisualizarField label=' ' />
			</Tabela>
			<CustomDialog />
		</ModalContextProvider>
	);
};

export default TabelaContasReceberBoletos;
