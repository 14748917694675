import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
	TextField as RATextField,
	List,
	Pagination,
	useListContext,
	Filter,
	ReferenceField,
	useNotify,
	useDataProvider,
	TopToolbar,
	useRefresh,
} from 'react-admin';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { Field } from 'react-final-form';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
	Box,
	makeStyles,
	useTheme,
	Button,
	Chip,
	Paper,
	Typography,
	FormControlLabel,
	Switch,
	TextField,
	Tooltip,
} from '@material-ui/core';
import { Padrao } from '../../common/filtros/Padrao';
import { DataReferencia } from '../../common/filtros/Data';
import { Autocompletar } from '../../common/filtros/Autocompletar';
import { Decimal } from '../../common/filtros/Decimal';
import { Tabela, TabelaRowContext } from '../../common/Tabela';
import { CurrencyField } from '../../common/CurrencyInput';
import { SyndikosSelect } from '../../common/SyndikosSelect';
import { InputDateReferencia } from '../../common/InputDate';
import VeryHighLimitReferenceInput from '../../common/VeryHighLimitReferenceInput';
import ModalDesfazerBaixas from './ModalDesfazerBaixas';
import ModalDesfazerBaixasParciais from './ModalDesfazerBaixasParciais';
import { ModalPagamentoLote, ModalPagamentoSingle } from './modaisPagamento';
import {
	CustomDialogBodySizeEditable,
	ModalSizeEditableContext,
	ModalSizeEditableContextProvider,
} from '../../common/ModalSizeEditableContext';
import { CondominiosContext } from '../../../context/CondominioContextProvider';
import {
	ToolTipButtonsArquivosRetorno,
	ToolTipButtonsAutoSyncBaixas,
	ResultadosBox,
	BaixaAutomaticaContextProvider,
} from './baixaAutomatica';
import DataFiltro from './DataFiltro';
import { ConfirmPassword } from '../../common/ConfirmPassword';
import ToolTipSettingsButton from './botaoConfigurar/ToolTipSettingsButton';
import { ToolTipImportacaoInadimplentes } from './ImportacaoInadimplentes';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
	warningColor: {
		color: theme.palette.syndikosRed.main,
	},
}));

const ButtonCriarReciboPagamento = ({ listContasPrevistas, listContasPagas, listContasPagasParcialmente }) => {
	const notify = useNotify();
	const dp = useDataProvider();

	return (
		<Button
			disabled={!!listContasPrevistas.length || !listContasPagas.length || listContasPagasParcialmente.length}
			onClick={() => {
				const ids = listContasPagas;
				dp.gerarRecibosPagamentosContaAReceber({ ids })
					.then((response) => {
						notify('Recibo gerado com sucesso!');
					})
					.catch((e) => {
						if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
						const erroMsg = Object.values(e?.response?.data || {})[0] || [
							'Erro inesperado, tente recarregar a página',
						];
						notify(erroMsg, 'warning');
					});
			}}
		>
			Gerar Recibo
		</Button>
	);
};

const ButtonDesfazerPagamento = ({ listContasPrevistas, listContasPagas, listContasPagasParcialmente }) => {
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const classes = useStyles();
	const notify = useNotify();
	const dp = useDataProvider();
	return (
		<Button
			className={classes.warningColor}
			disabled={!!listContasPrevistas.length || (!listContasPagas.length && !listContasPagasParcialmente.length)}
			onClick={() => {
				const listContas = listContasPagas.concat(listContasPagasParcialmente);
				if (listContas.length > 1) {
					setModalValue((v) => ({
						...v,
						open: true,
						dialogBody: <ModalDesfazerBaixas selectedIds={listContas} />,
					}));
				} else {
					dp.getSimple('contas_receber', {
						filter: {
							id__in: listContas,
							desfazer_pagamento: true,
						},
						pagination: {
							page: 1,
							perPage: 1,
						},
					})
						.then((response) => {
							const contaAReceber = (response?.data?.results || [])[0];
							if (contaAReceber && contaAReceber?.id && contaAReceber?.recebimentos_manuais?.length) {
								setModalValue((v) => ({
									...v,
									open: true,
									dialogBody:
										contaAReceber.recebimentos_manuais.length === 1 ? (
											<ModalDesfazerBaixas selectedIds={[contaAReceber.id]} />
										) : (
											<ModalDesfazerBaixasParciais
												selectedId={contaAReceber.id}
												recebimentosManuais={contaAReceber.recebimentos_manuais}
											/>
										),
								}));
							}
						})
						.catch((e) => {
							if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
							const erroMsg = Object.values(e?.response?.data || {})[0] || [
								'Erro inesperado, tente recarregar a página',
							];
							notify(erroMsg, 'warning');
						});
				}
			}}
		>
			Desfazer baixas
		</Button>
	);
};

const ButtonDeletarContaPrevista = ({ listContasPrevistas, listContasPagas, listContasPagasParcialmente }) => {
	const refresh = useRefresh();
	const notify = useNotify();
	const dP = useDataProvider();
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const registroTemAlgumPagamento = !!listContasPagas.length || !!listContasPagasParcialmente.length;
	const registrosParaExcluir = registroTemAlgumPagamento ? null : listContasPrevistas;

	const closeModal = () => setModalValue(() => ({ open: false }));

	const deleteContaPrevista = () => {
		const saoMultiplosRegistros = listContasPrevistas.length > 1;
		try {
			dP.deleteMany('contas_receber', { ids: registrosParaExcluir })
				.then(
					() =>
						notify(
							saoMultiplosRegistros
								? 'Contas a receber excluidas com sucesso'
								: 'Conta a receber excluida com sucesso'
						),
					refresh(),
					closeModal()
				)
				.catch(() =>
					notify(
						saoMultiplosRegistros ? 'Erro ao excluir contas a receber' : 'Erro ao excluir conta a receber.'
					)
				);
		} catch {
			notify(saoMultiplosRegistros ? 'Erro ao excluir contas a receber' : 'Erro ao excluir conta a receber.');
		}
	};

	const openModal = () =>
		setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: (
				<CustomDialogBodySizeEditable
					customStyleTilte={{ paddingBottom: '5px' }}
					title={'Tem certeza que deseja excluir esse lançamento de conta a receber?'}
					text={<ConfirmPassword cancelAction={closeModal} successConfirmCallback={deleteContaPrevista} />}
					customActions={<></>}
				/>
			),
		}));

	return (
		<>
			{/* <Button disabled={registroTemAlgumPagamento} onClick={() => !registroTemAlgumPagamento && openModal()}>
				Excluir selecionadas
			</Button> */}
		</>
	);
};

const ButtonPagamento = ({ listContasPrevistas, listContasPagas, listContasPagasParcialmente }) => {
	const notify = useNotify();
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const dP = useDataProvider();
	return (
		<Button
			disabled={!!listContasPagas.length || (!listContasPrevistas.length && !listContasPagasParcialmente.length)}
			onClick={() => {
				const listaContas = listContasPrevistas.concat(listContasPagasParcialmente);
				(listaContas.length > 1
					? dP
							.getSimple('contas_receber', {
								filter: {
									pagamento: true,
									id__in: listaContas,
								},
								pagination: {
									page: 1,
									perPage: listaContas.length,
								},
							})
							.then((response) => ({
								lote: response?.data?.results,
							}))
					: dP
							.getSimple('contas_receber', { id: listaContas[0] })
							.then((response) => ({ single: response?.data }))
				)

					.then(({ lote, single }) => {
						if (lote) {
							setModalValue((v) => ({
								...v,
								open: true,
								dialogBody: <ModalPagamentoLote contas_receber={lote} />,
							}));
						} else if (single) {
							setModalValue((v) => ({
								...v,
								open: true,
								dialogBody: <ModalPagamentoSingle conta_receber={single} />,
							}));
						}
					})
					.catch((e) => {
						if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
						notify(`${e}`, 'warning');
					});
			}}
		>
			Pagar selecionadas
		</Button>
	);
};

const BulkActions = () => {
	const { data, selectedIds, onUnselectItems } = useListContext();
	const [registrosSelecionados, setRegistrosSelecionados] = useState({});
	const [listContasPagas, setListContasPagas] = useState([]);
	const [listContasPagasParcialmente, setListContasPagasParcialmente] = useState([]);
	const [listContasPrevistas, setListContasPrevistas] = useState([]);

	useEffect(() => {
		if (selectedIds.length && selectedIds[0]) {
			setRegistrosSelecionados((v) => {
				return selectedIds.reduce((registros, id) => {
					const registro = data[id] || v[id];
					if (registro?.id) registros[id] = registro;
					return registros;
				}, {});
			});
		} else {
			setRegistrosSelecionados({});
			onUnselectItems();
		}
	}, [selectedIds, data, setRegistrosSelecionados, onUnselectItems]);

	useEffect(() => {
		const [listaCPG, listaCPGP, listaCPV] = Object.values(registrosSelecionados).reduce(
			([listaCPG, listaCPGP, listaCPV], { situacao, id, numero_parcelas }) => {
				if (situacao === 'PG') {
					listaCPG.push(id);
				} else if (numero_parcelas > 1) {
					listaCPGP.push(id);
				} else {
					listaCPV.push(id);
				}
				return [listaCPG, listaCPGP, listaCPV];
			},
			[[], [], []]
		);
		setListContasPagas(listaCPG);
		setListContasPagasParcialmente(listaCPGP);
		setListContasPrevistas(listaCPV);
	}, [registrosSelecionados, setListContasPagas, setListContasPagasParcialmente, setListContasPrevistas]);

	return (
		<>
			<ModalSizeEditableContextProvider customDialogProps={{ minWidth: '90vw' }}>
				<ButtonCriarReciboPagamento
					{...{
						listContasPrevistas,
						listContasPagasParcialmente,
						listContasPagas,
					}}
				/>
			</ModalSizeEditableContextProvider>

			<ModalSizeEditableContextProvider customDialogProps={{ minWidth: '90vw' }}>
				<ButtonDesfazerPagamento
					{...{
						listContasPrevistas,
						listContasPagasParcialmente,
						listContasPagas,
					}}
				/>
			</ModalSizeEditableContextProvider>

			<ModalSizeEditableContextProvider customDialogProps={{ minWidth: '45%' }}>
				<ButtonDeletarContaPrevista
					{...{
						listContasPrevistas,
						listContasPagasParcialmente,
						listContasPagas,
					}}
				/>
			</ModalSizeEditableContextProvider>

			<ModalSizeEditableContextProvider customDialogProps={{ minWidth: '61vw' }}>
				<ButtonPagamento
					{...{
						listContasPrevistas,
						listContasPagasParcialmente,
						listContasPagas,
					}}
				/>
			</ModalSizeEditableContextProvider>
		</>
	);
};

const SelectInputWithoutEmpty = ({ allowEmpty, ...props }) => (
	<SyndikosSelect optionText='nome' translateChoice={false} disableClearable fullWidth {...props} />
);

const Filters = (props) => {
	const { onUnselectItems } = useListContext();
	const { setCondominioPorId } = useContext(CondominiosContext);

	return (
		<Filter {...props}>
			<VeryHighLimitReferenceInput
				reference='condominios'
				label='Condomínio'
				source='id_condominio'
				variant='outlined'
				filter={{ situacao: 'A' }}
				style={{ 'min-width': '200px' }}
				onChange={(newValue) => {
					setCondominioPorId(newValue);
					onUnselectItems();
				}}
				alwaysOn
			>
				<SelectInputWithoutEmpty />
			</VeryHighLimitReferenceInput>
			<InputDateReferencia
				label='Referência Inicial'
				source='referencia_range_after'
				alwaysOn
				handleChange={(value, onChange) => {
					if (value && value.toString && value.toString() !== 'Invalid Date') {
						onChange(format(value, 'yyyy-MM-dd'));
					} else if (!value) {
						onChange(null);
					}
				}}
			/>
			<InputDateReferencia
				label='Referência Final'
				source='referencia_range_before'
				alwaysOn
				handleChange={(value, onChange) => {
					if (value && value.toString && value.toString() !== 'Invalid Date') {
						onChange(format(value, 'yyyy-MM-dd'));
					} else if (!value) {
						onChange(null);
					}
				}}
			/>
			<Field name='ocultar_contas_recebidas' id='ocultar_contas_recebidas' type='checkbox' alwaysOn>
				{({ input }) => (
					<Tooltip title='Ocultar Contas Pagas'>
						<FormControlLabel
							control={
								<Switch
									color='primary'
									checked={input.checked}
									onChange={(e) => {
										input.onChange(e);
									}}
									name={input.name}
								/>
							}
						/>
					</Tooltip>
				)}
			</Field>
		</Filter>
	);
};

const AcoesLista = () => {
	return (
		<TopToolbar>
			<ModalSizeEditableContextProvider
				customDialogProps={{
					disableBackdropClick: true,
					disableEscapeKeyDown: true,
					minWidth: '95vw',
				}}
			>
				<ToolTipImportacaoInadimplentes />
				<ToolTipButtonsArquivosRetorno />
				<ToolTipButtonsAutoSyncBaixas />
				<ToolTipSettingsButton />
			</ModalSizeEditableContextProvider>
		</TopToolbar>
	);
};

const ContaReceberNumberField = ({ record, source, ...props }) => (
	<div style={{ 'text-align': 'end' }}>
		{record && record[source] !== null && record[source] !== undefined && (
			<CurrencyField
				{...props}
				prefix='R$ '
				minimumFractionDigits={2}
				value={record[source]}
				id={`${source}${record.id}`}
			/>
		)}
	</div>
);

const SituacaoChip = ({ value, textColor, ...rest }) => (
	<Chip
		label={
			<Typography variant='caption' style={{ color: textColor }}>
				{value}
			</Typography>
		}
		size='small'
		clickable={false}
		style={{ background: '#f1f1f1' }}
		{...rest}
	/>
);

export const opcoesSituacao = [
	{ id: 'PG', name: 'Paga' },
	{ id: 'EA', name: 'Em aberto' },
	{ id: 'VH', name: 'Vencendo hoje' },
	{ id: 'VN', name: 'Vencida' },
	{ id: 'AC', name: 'Acordo' },
];

export const SituacaoField = ({ record }) => {
	const theme = useTheme();
	const { data } = useListContext();
	const { rowRecord, setRowRecord } = useContext(TabelaRowContext);
	const situacao = record?.situacao;

	const update = () => {
		if (data && data[rowRecord.id]) {
			setRowRecord(data[rowRecord.id]);
		}
	};

	useEffect(update, [data]);

	switch (situacao) {
		case 'EA':
			return <SituacaoChip value='Em aberto' textColor={theme.palette.success.dark} />;
		case 'VH':
			return <SituacaoChip value='Vencendo hoje' textColor={theme.palette.warning.dark} />;
		case 'VN':
			return <SituacaoChip value='Vencida' textColor={theme.palette.syndikosRed.dark} />;
		case 'PG':
			return <SituacaoChip value='Paga' textColor={theme.palette.primary.dark} />;
		case 'P':
			return <SituacaoChip value='Previsto' textColor={theme.palette.success.dark} />;
		case 'AC':
			return <SituacaoChip value='Acordo' textColor={theme.black[500]} />;
		default:
			return <SituacaoChip value={situacao} />;
	}
};

const ResponsavelField = ({ record: { responsavel } }) => {
	const classes = useStyles();
	return typeof responsavel === 'string' ? (
		<span>{responsavel}</span>
	) : (
		<span className={classes.warningColor}>Nenhum Responsável Registrado Nessa Data</span>
	);
};

const opcoesTpoResponsavel = [
	{ id: 'P', name: 'Proprietário' },
	{ id: 'I', name: 'Inquilino' },
];

const CobrancaField = ({ record: { tipo_responsavel } }) => (
	<span>{tipo_responsavel === 'P' ? 'Proprietário' : 'Inquilino'}</span>
);

const DateTextField = ({ source, record }) => (
	<span>
		{record[source]
			? new Date(`${record[source]}T10:00`).toLocaleDateString('pt-br', {
					year: 'numeric',
					month: '2-digit',
					day: '2-digit',
			  })
			: ''}
	</span>
);

const DateTextFieldReferencia = ({ source, record }) => (
	<span>
		{record[source]
			? new Date(`${record[source]}T10:00`).toLocaleDateString('pt-br', {
					year: 'numeric',
					month: '2-digit',
			  })
			: ''}
	</span>
);

const IdentificacaoSelectFilter = ({ source }) => {
	const [value, setValue] = useState({ id: '0', name: 'Todas' });
	const {
		setFilters,
		filterValues: { id_condominio, identificacao, ...restFilterValues },
	} = useListContext();
	const [options, setOptions] = useState([{ id: '0', name: 'Todas' }]);
	const [lengthOption, setLengthOption] = useState(5);
	const dataProvider = useDataProvider();
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const { token: cancelToken, cancel: cancelRequest } = axios.CancelToken.source();
		const fetchData = async () => {
			setLoading(true);
			try {
				const response = await dataProvider.getSimple('identificacao_arrecadacao', {
					pagination: { page: 1, perPage: 20 },
					sort: { field: 'id_condominio', order: 'ASC' },
					filter: {
						situacao: 'A',
						condominio_ativo: true,
						...(id_condominio ? { id_condominio: id_condominio } : {}),
					},
					cancelToken,
				});
				setData(response?.data?.results);
			} catch (error) {
				console.error('Error fetching data:', error);
				setData([]);
			} finally {
				setLoading(false);
			}
		};

		cancelRequest();
		fetchData();
	}, [dataProvider, id_condominio]);

	const init = useCallback(() => {
		if (identificacao && options.length > 0 && value?.id !== identificacao) {
			setValue(options.find((o) => o.id === identificacao));
		}
	}, [identificacao, options, value, setValue]);
	useEffect(init, [identificacao, options]);

	const updateCondominio = useCallback(() => {
		if (identificacao && data && !loading && !data[identificacao]) {
			setValue({ id: '0', name: 'Todas' });
			setFilters({
				...restFilterValues,
				id_condominio,
			});
		}
	}, [identificacao, data, loading, setValue, setFilters, restFilterValues, id_condominio]);
	useEffect(updateCondominio, [loading]);

	const updatelengthOption = useCallback(() => {
		let aux = 0;
		options.forEach((v) => {
			if (v.name.length > aux) aux = v.name.length;
		});
		setLengthOption(aux);
	}, [options, setLengthOption]);

	useEffect(updatelengthOption, [options]);

	const updateOptions = () => {
		if (data) {
			setOptions([
				{ id: '0', name: 'Todas' },
				...Object.values(data)
					.filter((v) => v.id !== 0)
					.map((item) => ({
						id: item.id,
						name: item.nome,
					})),
			]);
		}
	};

	useEffect(updateOptions, [data]);

	return (
		<Autocomplete
			openOnFocus
			value={value || null}
			id={`${source}-filtro`}
			options={options}
			onChange={(e, newValue) => {
				const id = parseInt(newValue?.id);
				if (!newValue || !id) setValue({ id: '0', name: 'Todas' });
				setFilters(
					id
						? { ...restFilterValues, id_condominio, identificacao: id }
						: { ...restFilterValues, id_condominio }
				);
			}}
			renderOption={(option) => option.name}
			getOptionLabel={(option) => (option.name ? option.name : '')}
			getOptionSelected={(option, newValue) => newValue.id === option.id}
			renderInput={(params) => <TextField {...params} label='Filtrar' variant='standard' />}
			PaperComponent={(props) => (
				<Paper
					{...props}
					style={{
						...props.style,
						width: `${lengthOption * 10}pt`,
						maxWidth: '320px',
						minWidth: '60px',
					}}
				/>
			)}
			fullWidth
			noOptionsText='Nenhum resultado'
			disableListWrap
			disableClearable
		/>
	);
};

const TabelaContasReceber = (props) => {
	const {
		filterValues: { condominio },
		onUnselectItems,
	} = useListContext();
	const { condominioSelecionado } = useContext(CondominiosContext);
	const sidebarOpen = useSelector((state) => state.admin.ui.sidebarOpen);
	useEffect(onUnselectItems, [condominio]);

	return (
		<>
			{condominioSelecionado?.id ? (
				<div
					style={{
						overflowY: 'hidden',
						overflowX: 'scroll',
						width: 'auto',
						'max-width': `calc(100vw - ${sidebarOpen ? '320px' : '110px'})`,
					}}
				>
					<Tabela disableClickRow {...props}>
						<ReferenceField
							source='identificacao'
							reference='identificacao_arrecadacao'
							label='Identificação'
							link={false}
							sortBy='nome_identificacao'
							filter={{ situacao: 'A' }}
							filtro={<IdentificacaoSelectFilter />}
							minWidth='200px'
						>
							<RATextField source='nome' />
						</ReferenceField>
						<SituacaoField
							source='situacao'
							label='Situação'
							filtro={<Autocompletar opcoes={opcoesSituacao} />}
							minWidth='165px'
						/>
						<RATextField source='nome_grupo_unidade' label='Grupo' filtro={<Padrao />} minWidth='120px' />
						<RATextField source='nome_unidade' label='Unidade' filtro={<Padrao />} minWidth='100px' />
						<ResponsavelField
							source='responsavel'
							label='Responsável'
							filtro={<Padrao />}
							minWidth='150px'
						/>
						<CobrancaField
							source='tipo_responsavel'
							label='Cobrança'
							filtro={<Autocompletar opcoes={opcoesTpoResponsavel} />}
							minWidth='150px'
						/>
						<RATextField source='nome_conta' label='Conta Bancária' filtro={<Padrao />} minWidth='200px' />
						<DateTextField
							source='data_vencimento'
							label='Vencimento'
							filtro={<DataFiltro />}
							minWidth='150px'
						/>
						<DateTextFieldReferencia
							source='referencia'
							label='Referência'
							filtro={<DataReferencia />}
							minWidth='150px'
						/>
						<RATextField source='nosso_numero' label='Nosso Número' filtro={<Padrao />} minWidth='150px' />
						<DateTextField
							source='data_pagamento'
							label='Pagamento'
							filtro={<DataFiltro />}
							minWidth='150px'
						/>
						<ContaReceberNumberField
							source='valor_base'
							align='right'
							label='Valor'
							filtro={<Decimal />}
							minWidth='100px'
						/>
						<ContaReceberNumberField
							source='multa'
							align='right'
							label='Multa'
							filtro={<Decimal />}
							minWidth='90px'
						/>
						<ContaReceberNumberField
							source='juros'
							align='right'
							label='Juros'
							filtro={<Decimal />}
							minWidth='90px'
						/>
						<ContaReceberNumberField
							source='correcao_monetaria'
							align='right'
							label='Correção'
							filtro={<Decimal />}
							minWidth='130px'
						/>
						<ContaReceberNumberField
							source='desconto'
							align='right'
							label='Desconto'
							filtro={<Decimal />}
							minWidth='90px'
						/>
						<ContaReceberNumberField
							source='valor_recebido'
							align='right'
							label='Pago'
							filtro={<Decimal />}
							minWidth='110px'
						/>
						<ContaReceberNumberField
							source='valor_pendente'
							align='right'
							label='Pendente'
							filtro={<Decimal />}
							minWidth='100px'
						/>
					</Tabela>
				</div>
			) : (
				<Box display={'flex'} mt={4} mb={4} justifyContent={'center'}>
					<span>Selecione um condomínio para visualizar as Contas a Receber</span>
				</Box>
			)}
		</>
	);
};

export const ContasReceberList = (props) => {
	const { condominioSelecionado } = useContext(CondominiosContext);

	const condominioSelecionadoId = condominioSelecionado?.id;

	return (
		<BaixaAutomaticaContextProvider>
			<List
				bulkActionButtons={<BulkActions />}
				title='Contas a Receber'
				{...props}
				empty={false}
				perPage={10}
				pagination={
					!condominioSelecionadoId ? null : (
						<Pagination
							rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 500]}
							labelRowsPerPage='Contas a receber por página'
						/>
					)
				}
				actions={<AcoesLista />}
				filterDefaultValues={{ ocultar_contas_recebidas: false, id_condominio: condominioSelecionadoId }}
				sort={{ field: 'nome_unidade', order: 'ASC' }}
				filter={{
					condominio: condominioSelecionadoId,
				}}
				filters={<Filters />}
				syncWithLocation={false}
			>
				<TabelaContasReceber isRowSelectable={(record) => record.situacao !== 'AC'} />
			</List>
			<ResultadosBox />
		</BaixaAutomaticaContextProvider>
	);
};
