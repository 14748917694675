import React, { useContext } from 'react';

import { ListContext, getListControllerProps, TextField as RATextField } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import LinearProgress from '@material-ui/core/LinearProgress';

import { CurrencyField } from 'components/common/CurrencyInput';
import { SimplesAutocompletar } from 'components/common/filtros/Autocompletar';
import { Data } from 'components/common/filtros/Data';
import { Decimal } from 'components/common/filtros/Decimal';
import { Padrao } from 'components/common/filtros/Padrao';
import { ModalSizeEditableContextProvider } from 'components/common/ModalSizeEditableContext';
import { Tabela } from 'components/common/Tabela';
import TooltipBar from 'components/common/TooltipBar';

import { DateField, TipoResponsavelField, ResponsavelField } from '../../../ContasReceberArrecadacoesList';
import { ArrecadacoesContext } from '../../../ArrecadacoesContext';
import { ProvisionamentoPanelContext } from '../ProvisionamentoPanelContext';

import { ContaAReceberActionsContextProvider } from './ContaAReceberActionsContext';
import BotaoAlterarResponsavel from './BotaoAlterarResponsavel';
import BotaoAlterarSituacaoProvisionamento from './BotaoAlterarSituacaoProvisionamento';
import BotaoEditarArrecadacoesUnidades from './BotaoEditarArrecadacoesUnidades';
import BotaoAlterarVencimento from './BotaoAlterarVencimento';
import BotaoExcluirLancamentoContaAReceber from './BotaoExcluirLancamentoContaAReceber';
import SyndkosTablePagination from 'components/common/Table/SyndkosTablePagination';

const useWarningStyles = makeStyles((theme) => ({
	warningColor: {
		color: theme.palette.syndikosRed.main,
	},
}));

const SituacaoProvisionamentoField = ({ record }) => (record?.provisionada ? 'Provisionada' : 'Desprovisionada');

const ValorPendenteField = ({ record, source, ...props }) => {
	const classes = useWarningStyles();
	return (
		record && (
			<div
				{...(parseFloat(record?.valor_pendente) < 0 ? { className: classes.warningColor } : {})}
				style={{ 'text-align': 'end' }}
			>
				<CurrencyField
					{...props}
					styleCol={{ 'text-align': 'end' }}
					prefix={parseFloat(record?.valor_pendente) < 0 ? 'R$ -' : 'R$ '}
					record={record}
					minimumFractionDigits={2}
					value={record?.valor_pendente}
					id={`${source}${record?.id}`}
				/>
			</div>
		)
	);
};

const NomeResponsavelField = ({ record }) => (
	<ResponsavelField
		record={{
			...record,
			pagador_id: !!record?.pagador_nome,
		}}
	/>
);

const AcoesTooltip = ({ record }) =>
	record.provisionada ? (
		<BotaoAlterarSituacaoProvisionamento record={record} />
	) : (
		<TooltipBar>
			{/* <BotaoExcluirLancamentoContaAReceber record={record} /> */}
			{!record?.usa_valores_de_acordo && <BotaoAlterarResponsavel record={record} />}
			<BotaoAlterarVencimento record={record} />
			<BotaoEditarArrecadacoesUnidades record={record} />
			<BotaoAlterarSituacaoProvisionamento record={record} />
		</TooltipBar>
	);

const AcoesField = ({ record }) =>
	record && (
		<ContaAReceberActionsContextProvider record={record}>
			<ModalSizeEditableContextProvider
				customDialogProps={{
					disableBackdropClick: true,
					disableEscapeKeyDown: true,
					minWidth: '70vw',
				}}
			>
				<AcoesTooltip record={record} />
			</ModalSizeEditableContextProvider>
		</ContaAReceberActionsContextProvider>
	);

const ContasAReceberParaEditarTabela = () => {
	const { tem_arrecadacao_provisionada } = useContext(ArrecadacoesContext);
	const {
		currentSort,
		handlePageChange,
		data,
		filters: filterValues,
		ids,
		loaded,
		loading,
		onUnselectItems,
		onSelect,
		onToggleItem,
		page,
		perPage,
		selectedIds,
		setFilters,
		setPage,
		setPerPage,
		setSort,
		total,
	} = useContext(ProvisionamentoPanelContext);

	const props = {
		basePath: '',
		defaultTitle: '',
		displayedFilters: {},
		error: null, //
		exporter: (a, b, c) => {
			console.log('exporter', a, b, c);
		}, //
		filterValues: filterValues,
		hasCreate: false,
		hideFilter: (a, b, c) => {
			console.log('hideFilter', a, b, c);
		},
		showFilter: (a, b, c) => {
			console.log('showFilter', a, b, c);
		},
		resource: 'cld_recebimento_manual',

		hasBulkActions: false,
		disableClickRow: true,
		currentSort,
		data,
		loading,
		onUnselectItems,
		onSelect,
		onToggleItem,
		setFilters: (filters) => {
			setFilters(filters);
		},
		setPage: (newPage) => {
			setPage(newPage);
		},
		setPerPage: (newPerPage) => {
			setPerPage(newPerPage);
		},
		setSort: (field, order) => {
			setSort({ field, order });
		},
		ids,
		loaded,
		page,
		perPage,
		selectedIds,
		total,
	};
	return tem_arrecadacao_provisionada ? (
		<ListContext.Provider value={getListControllerProps(props)}>
			<Tabela {...props}>
				<SituacaoProvisionamentoField
					source='provisionada'
					label='Situação'
					filtro={
						<SimplesAutocompletar
							opcoes={[
								{ id: true, name: 'Provisionada' },
								{ id: false, name: 'Desprovisionada' },
							]}
						/>
					}
				/>
				<RATextField source='nome_grupo_unidade' label='Grupo' filtro={<Padrao />} />
				<RATextField source='nome_unidade' label='Unidade' filtro={<Padrao />} />
				<NomeResponsavelField source='pagador_nome' label='Responsável' filtro={<Padrao />} />
				<TipoResponsavelField
					source='tipo_responsavel'
					label='Cobrança'
					listControllerProps={{ data }}
					filtro={
						<SimplesAutocompletar
							opcoes={[
								{ id: 'P', name: 'Proprietário' },
								{ id: 'I', name: 'Inquilino' },
							]}
						/>
					}
				/>
				<ValorPendenteField source='valor_pendente' label='Valor' filtro={<Decimal />} align='right' />
				<DateField source='data_vencimento' label='Vencimento' filtro={<Data />} />
				<RATextField source='nosso_numero' label='Nosso Número' filtro={<Padrao />} />
				<AcoesField align='center' sortable={false} selectable={false} label='Ações' />
			</Tabela>
			<SyndkosTablePagination
				handlePageChange={handlePageChange}
				page={page}
				perPage={perPage}
				total={total}
				setPerPage={setPerPage}
				labelRowsPerPage='Arrecadações por página'
			/>
		</ListContext.Provider>
	) : null;
};

const ContasAReceberParaEditarList = () => {
	const { loading } = useContext(ProvisionamentoPanelContext);

	return loading ? (
		<div style={{ marginTop: 25 }}>
			<LinearProgress />
		</div>
	) : (
		<ContasAReceberParaEditarTabela />
	);
};

export default ContasAReceberParaEditarList;
