import React, { useCallback, useState } from 'react';

import Box from '@material-ui/core/Box';

import {
	Table,
	TableContainer,
	TableRow,
	TableBody,
	TableCell,
	TableHead,
	Checkbox,
	TableSortLabel,
} from '@material-ui/core';

import { format, isBefore, parseISO } from 'date-fns';

import { NumberFormatBRL } from '../../common/CurrencyInput';
import SyndkosTablePagination from 'components/common/Table/SyndkosTablePagination';

const columns = [
	{ field: 'valor_pendente', headerName: 'Valor', width: '15vw' },
	{ field: 'valor', headerName: 'Pago', width: '15vw' },
	{ field: 'multa', headerName: 'Multa', width: '15vw' },
	{ field: 'juros', headerName: 'Juros', width: '15vw' },
	{ field: 'correcao_monetaria', headerName: 'Correção Monetária', width: '20vw' },
	{ field: 'desconto', headerName: 'Desconto', width: '15vw' },
	{
		field: 'data_movimentacao',
		headerName: 'Pagamento',
		type: 'date',
		width: '10vw',
	},
];

const TabelaRecebimentosPagos = ({ recebimentosSelecionados, setRecebimentosSelecionados, selecionavel }) => {
	const [rows, setRows] = useState(recebimentosSelecionados);
	const [total] = useState(recebimentosSelecionados.length);
	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const handlePageChange = useCallback(
		(event, page) => {
			event && event.stopPropagation();
			setPage(page + 1);
		},
		[setPage]
	);
	const [sort, setSort] = useState({
		field: 'data_movimentacao',
		order: 'asc',
	});

	const handleSortClick = (field) => {
		const newOrder = sort.order === 'asc' ? 'desc' : 'asc';
		setSort({ field, order: newOrder });
		setRows((r) =>
			r.sort((a, b) => {
				if (['valor_pendente', 'valor', 'desconto', 'multa', 'juros', 'correcao_monetaria'].includes(field)) {
					a[field] = Number(a[field]);
					b[field] = Number(b[field]);
					if (a[field] > b[field]) return newOrder === 'asc' ? -1 : 1;
					if (b[field] > a[field]) return newOrder === 'asc' ? 1 : -1;
				}
				if (field === 'data_movimentacao') {
					if (isBefore(parseISO(a[field]), parseISO(b[field]))) return newOrder === 'asc' ? -1 : 1;
					if (isBefore(parseISO(b[field]), parseISO(a[field]))) return newOrder === 'asc' ? 1 : -1;
				}
				return 0;
			})
		);
	};

	const handleSelectAll = () => {
		recebimentosSelecionados?.some((r) => rows.includes(r))
			? setRecebimentosSelecionados([])
			: setRecebimentosSelecionados(rows);
	};

	const handleRowClick = (e, row, isItemSelected) => {
		if (isItemSelected) {
			setRecebimentosSelecionados((r) => r.filter((d) => d !== row));
		} else {
			setRecebimentosSelecionados((r) => [...r, row]);
		}
	};

	return (
		<Box css={{ transition: 'all 200ms ease' }} minHeight='60px' display='grid' alignItems='center' width='100%'>
			<TableContainer>
				<Table size='small'>
					<TableHead>
						<TableRow>
							{!!selecionavel && (
								<TableCell padding='checkbox'>
									<Checkbox
										indeterminate={
											recebimentosSelecionados?.length !== rows.length &&
											recebimentosSelecionados?.length !== 0
										}
										checked={recebimentosSelecionados?.length === rows.length}
										onChange={handleSelectAll}
									/>
								</TableCell>
							)}
							{columns.map((c, i) => (
								<TableCell
									key={c.field}
									sortDirection={sort.field === c.field ? sort.order : false}
									style={{
										...(i === 0 ? { paddingLeft: 0 } : {}),
										width: c.width,
									}}
								>
									<TableSortLabel
										active={sort.field === c.field}
										direction={sort.field === c.field ? sort.order : 'asc'}
										onClick={() => handleSortClick(c.field)}
									>
										{c.headerName}
									</TableSortLabel>
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.slice((page - 1) * perPage, page * perPage).map((row) => {
							const isItemSelected =
								!!selecionavel && Boolean(recebimentosSelecionados?.find((s) => s.id === row.id));
							return (
								<TableRow
									{...(selecionavel
										? {
												hover: true,
												onClick: (e) => {
													if (selecionavel) handleRowClick(e, row, isItemSelected);
												},
												role: 'checkbox',
												'aria-checked': isItemSelected,
												selected: isItemSelected,
										  }
										: {})}
									tabIndex={-1}
									key={row.id}
								>
									{!!selecionavel && (
										<TableCell padding='checkbox'>
											<Checkbox
												checked={isItemSelected}
												inputProps={{
													'aria-labelledby': row.id,
												}}
											/>
										</TableCell>
									)}
									<TableCell>
										<NumberFormatBRL
											displayType='text'
											value={row.valor_pendente}
											fixedDecimalScale
											onChange={() => {}}
											decimalScale={2}
											prefix='R$ '
										/>
									</TableCell>
									<TableCell>
										<NumberFormatBRL
											displayType='text'
											value={row.valor}
											fixedDecimalScale
											onChange={() => {}}
											decimalScale={2}
											prefix='R$ '
										/>
									</TableCell>
									<TableCell>
										<NumberFormatBRL
											displayType='text'
											value={row.multa}
											fixedDecimalScale
											onChange={() => {}}
											decimalScale={2}
											prefix='R$ '
										/>
									</TableCell>
									<TableCell>
										<NumberFormatBRL
											displayType='text'
											value={row.juros}
											fixedDecimalScale
											onChange={() => {}}
											decimalScale={2}
											prefix='R$ '
										/>
									</TableCell>
									<TableCell>
										<NumberFormatBRL
											displayType='text'
											value={row.correcao_monetaria}
											fixedDecimalScale
											onChange={() => {}}
											decimalScale={2}
											prefix='R$ '
										/>
									</TableCell>
									<TableCell>
										<NumberFormatBRL
											displayType='text'
											value={row.desconto}
											fixedDecimalScale
											onChange={() => {}}
											decimalScale={2}
											prefix='R$ '
										/>
									</TableCell>
									<TableCell>{format(parseISO(row.data_movimentacao), 'dd/MM/yyyy')}</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
				<SyndkosTablePagination
					{...{
						handlePageChange,
						page,
						perPage,
						total,
						setPerPage,
					}}
					labelRowsPerPage='Recebimentos por página'
				/>
			</TableContainer>
		</Box>
	);
};

export default TabelaRecebimentosPagos;
