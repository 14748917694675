import api from './services/api';
import axios from 'axios';

const apiUrl = '/api/v1';

const buscaCep = async (cep) => {
	const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
	if (data.erro || !data) {
		return (await axios.get(`https://brasilapi.com.br/api/cep/v1/${cep}`)).data;
	}
	return data;
};

const gerarEBaixarArquivo = (url, params) => {
	return api
		.post(`${apiUrl}/recibo_pagamento/gerar`, params, {
			responseType: 'blob',
		})
		.then((blob) => {
			if (params.tipo_saida === 'disponibilizar' || !blob) return { data: null };
			const url = window.URL.createObjectURL(new Blob([blob.data], { type: 'application/pdf' }));
			const link = document.createElement('a');
			link.href = url;
			link.target = '_blank';
			document.body.appendChild(link);
			link.click();
			link.parentNode.removeChild(link);
			return {
				data: blob.data,
			};
		});
};

const geraRelatorio = async (resource, { tipoRelatorio, ...params }) => {
	if (params.tipo_saida === 'enviar') {
		return api.post(`${apiUrl}/${resource}/${tipoRelatorio}_enviar`, params).then((response) => ({
			status: response?.status,
			data: response?.data,
		}));
	} else if (['pdf', 'pdf_unico'].includes(params.tipo_saida)) {
		return await api
			.post(`${apiUrl}/${resource}/${tipoRelatorio}/relatorio_controller`, params, {
				responseType: 'blob',
			})
			.then((blob) => {
				if (blob) {
					const filenameRe = /(\w)+(\.pdf|\.zip)/g;
					const filename = blob['headers']['content-disposition'].match(filenameRe);
					const url = window.URL.createObjectURL(
						new Blob([blob.data], {
							type: params.tipo_saida === 'pdf_unico' ? 'application/pdf' : 'application/force-download',
						})
					);
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', filename);
					document.body.appendChild(link);
					link.click();
					link.parentNode.removeChild(link);
					return {
						data: {
							filename: filename,
							blobData: blob.data,
						},
					};
				}
			});
	}
	return await api
		.post(`${apiUrl}/${resource}/${tipoRelatorio}/relatorio_controller`, params, {
			responseType: 'blob',
		})
		.then((blob) => {
			if (params.tipo_saida === 'disponibilizar' || !blob) return { data: null };
			const url = window.URL.createObjectURL(new Blob([blob.data], { type: 'application/pdf' }));
			const link = document.createElement('a');
			link.href = url;
			link.target = '_blank';
			document.body.appendChild(link);
			link.click();
			link.parentNode.removeChild(link);
			return {
				data: blob.data,
			};
		});
};

const enviaArquivosRetorno = (resource, { data, ...params }) => {
	// Para entender https://axios-http.com/docs/req_config
	return axios(`${apiUrl}/${resource}`, {
		...api.defaults,
		data,
		method: 'POST',
		...params,
	});
};

const enviaContasAReceber = (resource, { data, ...params }) => {
	return axios(`${apiUrl}/${resource}`, {
		...api.defaults,
		data,
		method: 'POST',
		...params,
	});
};

const mockData = [
	{
		id: 1,
		condominio_id: 1,
		numero_sequencial_acordo: 1001,
		nome_grupo_unidade: 'Grupo A',
		nome_unidade: 'Unidade 101',
		referencia: '2023-03-01',
		identificacao_id: 100,
		numero_parcela: '1',
		quantidade_parcelas: 1,
		valor: 500.0,
		tipo_responsavel: 'P',
		responsavel: 'João da Silva',
		situacao: 'NP',
	},
	{
		id: 2,
		condominio_id: 1,
		numero_sequencial_acordo: 1002,
		nome_grupo_unidade: 'Grupo B',
		nome_unidade: 'Unidade 102',
		referencia: '2023-04-01',
		identificacao_id: 101,
		numero_parcela: '2',
		quantidade_parcelas: 2,
		valor: 600.0,
		tipo_responsavel: 'I',
		responsavel: 'Maria Oliveira',
		situacao: 'PV',
	},
];

const mockRecebimentosManuaisVencidos = (unidadeSelecionada, condominioSelecionado) => ({
	count: 4,
	results: [
		{
			id: 1,
			conta_a_receber_id: 101,
			condominio_id: condominioSelecionado,
			unidade_id: unidadeSelecionada,
			identificacao_id: 2001,
			nome_identificacao: 'Principal',
			tipo_responsavel: 'I',
			referencia: '01/2023',
			data_vencimento: '2023-01-10',
			nosso_numero: '12345678901',
			valor_pendente: 400,
		},
		{
			id: 2,
			conta_a_receber_id: 102,
			condominio_id: condominioSelecionado,
			unidade_id: unidadeSelecionada + 1,
			identificacao_id: 2002,
			nome_identificacao: 'Secundária',
			tipo_responsavel: 'I',
			referencia: '01/2023',
			data_vencimento: '2023-01-15',
			nosso_numero: '12345678902',
			valor_pendente: 35,
		},
		{
			id: 3,
			conta_a_receber_id: 103,
			condominio_id: condominioSelecionado,
			unidade_id: unidadeSelecionada,
			identificacao_id: 2002,
			nome_identificacao: 'Principal',
			tipo_responsavel: 'I',
			referencia: '02/2023',
			data_vencimento: '2023-02-15',
			nosso_numero: '12345678902',
			valor_pendente: 423,
		},
		{
			id: 4,
			conta_a_receber_id: 104,
			condominio_id: condominioSelecionado,
			unidade_id: unidadeSelecionada,
			identificacao_id: 2002,
			nome_identificacao: 'Secundária',
			tipo_responsavel: 'P',
			referencia: '02/2023',
			data_vencimento: '2023-02-10',
			nosso_numero: '12345678902',
			valor_pendente: 40,
		},
	],
});

export default {
	getList: (resource, params) => {
		if (!resource) return Promise.resolve({ data: [], total: 0 });
		let { pagination } = params;

		let url = `${apiUrl}/${resource}?limit=${pagination.perPage}&offset=${
			pagination.perPage * pagination.page - pagination.perPage
		}&ordering=${params.sort.order === 'ASC' ? '' : '-'}${params.sort.field || 'nome'}`;

		let filter = params.filter || {};

		if (Object.keys(filter).length > 0) {
			let entries = Object.entries(filter);
			for (const key in entries) {
				url += `&${entries[key][0]}=${entries[key][1]}`;
			}
		}
		if (resource === 'acordos_parcelas') {
			let filteredData = mockData;

			if (filter.id_condominio) {
				filteredData = filteredData.filter((item) => item.condominio_id === filter.id_condominio);
			}
			if (filter.referencia_range_after) {
				const afterDate = new Date(filter.referencia_range_after);
				filteredData = filteredData.filter((item) => new Date(item.referencia) >= afterDate);
			}
			if (filter.referencia_range_before) {
				const beforeDate = new Date(filter.referencia_range_before);
				filteredData = filteredData.filter((item) => new Date(item.referencia) <= beforeDate);
			}

			const total = filteredData.length;
			return Promise.resolve(
				total > 0
					? { data: filteredData, total }
					: {
							data: [
								{
									id: 0,
								},
							],
							total: 1,
					  }
			);
		}
		if (resource === 'recebimentos_manuais_vencidos') {
			const data = mockRecebimentosManuaisVencidos(filter.unidadeSelecionada, filter.condominioSelecionado);
			return Promise.resolve({
				data: data.results,
				total: data.count,
			});
		}
		if (
			(resource === 'arrecadacao' &&
				(!filter || !filter.id_condominio || !filter.id_identificacao_arrecadacao || !filter.referencia)) ||
			(resource === 'movimentacao_bancaria' &&
				(!filter ||
					!filter.condominio ||
					!filter.datas_after ||
					!filter.datas_before ||
					!filter.id_conta_bancaria)) ||
			(resource === 'conta_condominio_movimentacoes' && !filter.id_condominio)
		) {
			return Promise.resolve({ data: [], total: 0 });
		}

		return api
			.get(url)
			.then((response) => {
				const data = response?.data;
				if (!data) return { data: [], total: 0 };
				if (data.movimentacoes) {
					return {
						data: [{ saldo_anterior: data.saldo_anterior, id: 0 }, ...data.movimentacoes],
						total: data.movimentacoes.length,
					};
				}
				if (
					data.count === 0 &&
					resource !== 'contas_despesa' &&
					resource !== 'contas_receita' &&
					resource !== 'grupo_unidade_condominio' &&
					resource !== 'leitura' &&
					resource !== 'tipo_condominio'
				) {
					return {
						data: [
							{
								id: 0,
							},
						],
						total: 1,
					};
				}
				return { data: data.results, total: data.count };
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				if (
					[
						'arrecadacao',
						'contas_despesa',
						'contas_receita',
						'conta_condominio_movimentacoes',
						'grupo_unidade_condominio',
						'leitura',
						'movimentacao_bancaria',
						'tipo_condominio',
					].includes(resource)
				)
					return Promise.resolve({ data: [], total: 0 });
				return Promise.resolve({ data: [{ id: 0 }], total: 1 });
			});
	},

	reorder: (resource, params) => api.post(`${apiUrl}/${resource}/reordena_contas`, params.data),

	getSimple: (resource, params = {}) => {
		const { cancelToken } = params ?? {};
		const config = cancelToken ? { cancelToken } : undefined;

		let url = `${apiUrl}/${resource}`;
		if (params.id) url += `/${params.id}`;

		let { pagination } = params;
		if (pagination)
			url += `?limit=${pagination.perPage}&offset=${pagination.perPage * pagination.page - pagination.perPage}`;

		let { sort } = params;
		if (sort) url += `${pagination ? '&' : '?'}ordering=${sort.order === 'ASC' ? '' : '-'}${sort.field || 'nome'}`;

		let filter = params.filter || {};
		let entries = Object.entries(filter).concat(params.filterExtras || []);
		if (entries.length > 0) {
			url += pagination || sort ? '&' : '?';
			for (const key in entries) {
				url += `&${entries[key][0]}=${entries[key][1]}`;
			}
		}
		return api.get(url, config);
	},

	copyPlan: (resource, params) => api.post(`${apiUrl}/${resource}/por_plano_cadastrado`, params.data),

	importarContas: (resource, params) => api.post(`${apiUrl}/${resource}/por_csv`, params.data),

	getOne: (resource, params) => {
		let url = `${apiUrl}/${resource}/${params.id}`;

		let filter = params.filter || {};

		if (Object.keys(filter).length > 0) {
			let entries = Object.entries(filter);
			url += '?';
			for (const key in entries) {
				url += `&${entries[key][0]}=${entries[key][1]}`;
			}
		}

		return api.get(url);
	},

	getOnePkMiddle: (resource, params) => {
		const endpoint = resource.replace('/', `/${params.id}/`);
		let url = `${apiUrl}/${endpoint}`;

		return api.get(url);
	},

	getMany: (resource, params) => {
		let url = `${apiUrl}/${resource}?id__in=${JSON.stringify(params.ids).replace(/[[\]]/g, '')}`;

		let filter = params.filter || {};

		if (Object.keys(filter).length > 0) {
			let entries = Object.entries(filter);
			for (const key in entries) {
				url += `&${entries[key][0]}=${entries[key][1]}`;
			}
		}

		return api.get(url).then((response) => ({
			status: response?.status,
			data: response?.data?.results,
		}));
	},

	update: (resource, params) =>
		(['tarefas_manutencoes', 'ambiente_reserva'].includes(resource) ? api.put : api.patch)(
			`${apiUrl}/${resource}/${params.id}`,
			params.data
		).then((response) => ({
			status: response?.status,
			data: response?.data,
		})),

	fullUpdate: (resource, params) =>
		api.put(`${apiUrl}/${resource}/${params.id}`, params.data).then((response) => ({
			status: response?.status,
			data: response?.data,
		})),

	updateMany: (resource, params) => {
		if (resource === 'atualizar_data_leitura') {
			return api.patch(`${apiUrl}/lancamento_leitura/atualizar_data_leitura`, params.data).then((response) => ({
				status: response?.status,
				data: response?.data,
			}));
		}

		if (resource === 'aprova_leituras_cadastradas_aplicativo') {
			return api
				.patch(`${apiUrl}/leituras_cadastradas_aplicativo/aprovar_lancamentos_leituras`, params.data)
				.then((response) => ({
					status: response?.status,
					data: response?.data,
				}));
		}

		if (resource === 'reprova_leituras_cadastradas_aplicativo') {
			return api
				.patch(`${apiUrl}/leituras_cadastradas_aplicativo/reprovar_lancamentos_leituras`, params.data)
				.then((response) => ({
					status: response?.status,
					data: response?.data,
				}));
		}

		return Promise.allSettled(
			params.map(async (param) =>
				api.patch(`${apiUrl}/${resource}/${param.id}`, param.data).then((response) => ({
					status: response?.status,
					data: response?.data,
				}))
			)
		).then((responses) => ({ data: responses }));
	},

	bulkUpdate: (resource, params) =>
		api.patch(`${apiUrl}/${resource}`, params).then((response) => ({
			status: response?.status,
			data: response?.data,
		})),

	updateOnAction: (resource, params) => {
		if (params.data.detail) {
			return api
				.patch(`${apiUrl}/${resource}/${params.id}/${params.data.action}`, params.data)
				.then((response) => ({
					status: response?.status,
					data: response?.data,
				}));
		} else
			return api.patch(`${apiUrl}/${resource}/${params.data.action}`, params.data).then((response) => ({
				status: response?.status,
				data: response?.data,
			}));
	},

	updateWithPut: (resource, params) =>
		api.put(`${apiUrl}/${resource}/${params.id}`, params.data).then((response) => ({
			status: response?.status,
			data: response?.data,
		})),

	create: (resource, params) => {
		if (resource === 'completar') {
			return api
				.patch(`${apiUrl}/usuarios/${params.data.token}/completar_cadastro`, params.data)
				.then((response) => ({
					status: response?.status,
					data: response?.data,
				}));
		}

		return api
			.post(`${apiUrl}/${resource}`, params.data)
			.then((response) => {
				return {
					status: response?.status,
					data: response?.data,
				};
			})
			.catch((error) => {
				return Promise.reject(error);
			});
	},

	safeCreate: (resource, params) => {
		if (resource === 'completar') {
			return api
				.patch(`${apiUrl}/usuarios/${params.data.token}/completar_cadastro`, params.data)
				.then((response) => ({
					status: response?.status,
					data: response?.data,
				}));
		}
		if (resource === 'acordos') {
			return new Promise((resolve) => {
				resolve({ data: params.data });
			});
		}

		return api.post(`${apiUrl}/${resource}`, params.data).then((response) => ({
			status: response?.status,
			data: response?.data,
		}));
	},

	updatePermissions: (resource, params) => {
		return api.patch(`${apiUrl}/${resource}/${params.id}`, params.data).then((response) => {
			return {
				status: response?.status,
				data: response?.data,
			};
		});
	},

	simplePost: (resource, params) => {
		return api.post(`${apiUrl}/${resource}`, params.data);
	},

	createMany: (resource, params) =>
		api.post(`${apiUrl}/${resource}`, params.data).then((response) => ({
			status: response?.status,
			data: response?.data,
		})),

	delete: (resource, params) =>
		api.delete(`${apiUrl}/${resource}/${params.id}`, { data: params.data }).then((response) => ({
			status: response?.status,
			data: response?.data,
		})),

	deleteMany: (resource, params) => {
		if (resource === '') {
			return Promise.resolve({ data: params.ids, total: params.ids.length });
		}
		return Promise.allSettled(
			params.ids.map((id) =>
				api.delete(`${apiUrl}/${resource}/${id}`, {
					data: params.data,
				})
			)
		).then((responses) => ({ data: responses }));
	},

	getEndereco: (resource, params) => buscaCep(params.cep).then((data) => ({ data })),

	gerarRelatorio: (resource, params) => geraRelatorio(resource, params),

	processarArquivosRetorno: (resource, params) => enviaArquivosRetorno(resource, params).then((response) => response),
	processaSincronizacaoContas: (resource, params) => enviaContasAReceber(resource, params),
	gerarRecibosPagamentosContaAReceber: (params) => gerarEBaixarArquivo('recibo_pagamento/gerar', params),
};
