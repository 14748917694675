import React, { memo } from 'react';

import { List, Pagination, TextField, ReferenceField } from 'react-admin';

import { format, parseISO } from 'date-fns';

import { Tabela } from '../../common/Tabela';
import { Data } from '../../common/filtros/Data';
import { Autocompletar } from 'components/common/filtros/Autocompletar';
import { Occurrence } from './types';

import { SearchFieldsProps, OccurrenceListProps, ReferenceFieldV2Props } from './types';
import { Padrao } from 'components/common/filtros/PadraoV2';
import { Typography } from '@material-ui/core';
import { useRecordContext } from 'ra-core';
import get from 'lodash/get';

const sanitizeFieldRestProps: (props: any) => any = ({
	addLabel,
	allowEmpty,
	basePath,
	cellClassName,
	className,
	emptyText,
	formClassName,
	fullWidth,
	headerClassName,
	label,
	linkType,
	link,
	locale,
	record,
	refetch,
	resource,
	sortable,
	sortBy,
	sortByOrder,
	source,
	textAlign,
	translateChoice,
	...props
}) => props;

const SearchFieldData: React.FC<SearchFieldsProps> = ({ record }) => {
	let formatada = '';
	try {
		formatada = format(parseISO(record?.data as string), 'dd/MM/yyyy');
	} catch (e) {}
	return <span>{formatada}</span>;
};

interface FieldProps {
	record?: Occurrence;
	source: string;
	label: string;
	filtro: React.ReactElement<any>;
}

const CustomTextField: React.FC<SearchFieldsProps> = memo((props) => {
	const { className, source, emptyText, ...rest } = props;
	const record = useRecordContext(props);
	const value = get(record, source);

	return (
		<Typography component='span' variant='body2' className={className} {...sanitizeFieldRestProps(rest)}>
			{value != null && typeof value !== 'string'
				? JSON.stringify(value)
				: value
				? value.substring(0, 30) + (value.length > 30 ? '...' : '')
				: emptyText}
		</Typography>
	);
});

const SituationField: React.FC<FieldProps> = ({ record }) => {
	let textSituation;
	switch (record?.situacao) {
		case 'A':
			textSituation = 'Em Andamento';
			break;
		case 'F':
			textSituation = 'Finalizada';
			break;
		case 'E':
			textSituation = 'Excluída';
			break;
		case 'I':
			textSituation = 'Inativa';
			break;
	}
	return <span>{textSituation}</span>;
};

const TypeField: React.FC<FieldProps> = ({ record }) => {
	let tipoTexto;
	switch (record?.tipo) {
		case 'OC':
			tipoTexto = 'Ocorrência no Condomínio';
			break;
		case 'DN':
			tipoTexto = 'Denúncia';
			break;
	}
	return <span>{tipoTexto}</span>;
};

export const occurrenceSituationOptions = [
	{ id: 'A', name: 'Em Andamento' },
	{ id: 'F', name: 'Finalizada' },
	{ id: 'I', name: 'Inativa' },
];

export const occurrenceTypeOptions = [
	{ id: 'OC', name: 'Ocorrência no Condomínio' },
	{ id: 'DN', name: 'Denúncia' },
];

const ReferenceFieldV2: React.FC<ReferenceFieldV2Props> = ({ children, ...rest }) => {
	return <ReferenceField {...rest}>{children}</ReferenceField>;
};

const OccurrencesTable: React.FC<OccurrenceListProps> = (props) => {
	return (
		<Tabela rowClick={true} disableClickRow={false} {...props}>
			<ReferenceFieldV2
				id='reference-field-condominio'
				source='condominio'
				label='Condomínio'
				reference='condominios'
				link={false}
				sortBy='nome_condominio'
				filtro={
					<Autocompletar
						refName='nome'
						refResource='condominios'
						mostrarInativos={false}
						id='filtro-nome-condominio'
						label={undefined}
						source={undefined}
						opcoes={undefined}
					/>
				}
				minWidth='200px'
			>
				<TextField source='nome' />
			</ReferenceFieldV2>
			<ReferenceFieldV2
				id='reference-field-unidade'
				source='unidade'
				label='Unidade'
				reference='unidades'
				link={false}
				sortBy='unidade'
				filtro={
					<Autocompletar
						refName='unidade'
						refResource='unidades'
						mostrarInativos={false}
						id='filtro-nome-condominio'
						label={undefined}
						source={undefined}
						opcoes={undefined}
					/>
				}
				minWidth='200px'
			>
				<TextField source='unidade' />
			</ReferenceFieldV2>
			<ReferenceFieldV2
				id='reference-field-pessoa-unidade'
				source='responsavel'
				label='Responsável'
				reference='pessoa_unidade'
				link={false}
				sortBy='nome_responsavel'
				filtro={
					<Autocompletar
						refName='nome_responsavel'
						refResource='pessoa_unidade'
						mostrarInativos={false}
						id='filtro-nome-condominio'
						label={undefined}
						source={undefined}
						opcoes={undefined}
					/>
				}
				minWidth='200px'
			>
				<TextField source='nome_responsavel' />
			</ReferenceFieldV2>
			<CustomTextField source='assunto' label='ASSUNTO' filtro={<Padrao />} />
			<TypeField
				source='tipo'
				label='Tipo'
				filtro={
					<Autocompletar
						opcoes={occurrenceTypeOptions}
						id={undefined}
						label={undefined}
						source={undefined}
						refResource={undefined}
						refName={undefined}
						mostrarInativos={undefined}
					/>
				}
			></TypeField>
			<ReferenceFieldV2
				id='reference-field-unidade'
				source='classificacao'
				label='Classificação'
				reference='classificacao_ocorrencia'
				link={false}
				sortBy='unidade'
				filtro={
					<Autocompletar
						refName='descricao'
						refResource='classificacao_ocorrencia'
						mostrarInativos={false}
						id='filtro-nome-condominio'
						label={undefined}
						source={undefined}
						opcoes={undefined}
					/>
				}
				minWidth='200px'
			>
				<TextField source='descricao' />
			</ReferenceFieldV2>
			<SearchFieldData source='data' label='DATA' filtro={<Data />} />
			<SituationField
				source='situacao'
				label='Situação'
				filtro={
					<Autocompletar
						opcoes={occurrenceSituationOptions}
						id={undefined}
						label={undefined}
						source={undefined}
						refResource={undefined}
						refName={undefined}
						mostrarInativos={undefined}
					/>
				}
				record={undefined}
			/>
		</Tabela>
	);
};

const CustomPagination = (props: OccurrenceListProps) => (
	<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 500]} {...props} />
);

const OccurrenceList: React.FC<OccurrenceListProps> = (props) => {
	return (
		<List
			{...props}
			empty={false}
			bulkActionButtons={false}
			title='Ocorrências'
			sort={{ field: 'data_registro', order: 'DESC' }}
			perPage={10}
			pagination={<CustomPagination labelRowsPerPage='Ocorrências por página' />}
			filterDefaultValues={{ situacao: 'A' }}
		>
			<OccurrencesTable />
		</List>
	);
};

export default OccurrenceList;
