import React, { createContext, useEffect, useState, useMemo } from 'react';
import { useDataProvider } from 'react-admin';

export const AmbientesContext = createContext();

export const valoresHoras = [
	'00:00:00',
	'01:00:00',
	'02:00:00',
	'03:00:00',
	'04:00:00',
	'05:00:00',
	'06:00:00',
	'07:00:00',
	'08:00:00',
	'09:00:00',
	'10:00:00',
	'11:00:00',
	'12:00:00',
	'13:00:00',
	'14:00:00',
	'15:00:00',
	'16:00:00',
	'17:00:00',
	'18:00:00',
	'19:00:00',
	'20:00:00',
	'21:00:00',
	'22:00:00',
	'23:00:00',
];

export const AmbientesContextProvider = ({ children, ...rest }) => {
	const [ambientes, setAmbientes] = useState([]);

	const idCondominio = rest.id;
	const dP = useDataProvider();

	useEffect(() => {
		dP.getList('ambiente_reserva', {
			pagination: { perPage: 10000, page: 1 },
			sort: { field: 'id', order: 'ASC' },
			filter: { id_condominio: idCondominio },
		}).then(({ data }) => setAmbientes(data));
	}, [dP, idCondominio]);

	const value = useMemo(
		() => ({ ambientes, setAmbientes, idCondominio, dP }),
		[ambientes, setAmbientes, idCondominio, dP]
	);

	return <AmbientesContext.Provider value={value}>{children}</AmbientesContext.Provider>;
};

export const AmbienteReservaContext = createContext();

export const AmbienteReservaContextProvider = ({ children, ...rest }) => {
	const [ambiente, setAmbiente] = useState(rest.ambiente);
	const value = useMemo(() => ({ ambiente, setAmbiente }), [ambiente, setAmbiente]);

	return <AmbienteReservaContext.Provider value={value}>{children}</AmbienteReservaContext.Provider>;
};
