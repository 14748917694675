import React, { useContext, useEffect, useState } from 'react';

import { Box, Tab, TextField } from '@material-ui/core';

import { TabPanel, TabContext, TabList } from '@material-ui/lab';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { NumberFormatBRL, sanitizeListNumberFormatBRLProps } from '../../common/CurrencyInput';

import { LancamentoContaPagarContext } from './LancamentoContaPagarContext';
import { ListContasPagarContext } from './ListContasPagarContext';
import ListDespesas from './despesas/ListDespesas';
import ListParcelas from './parcelas/ListParcelas';
import BoxInformacoesAdicionaisFields from './BoxInformacoesAdicionaisFields';
import BoxObservacoes from './BoxObservacoes';

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const CondominioSelect = () => {
	const { selectedCondominio, setSelectedCondominio, condominiosData, despesas, erros, setErros } =
		useContext(LancamentoContaPagarContext);
	const { setLastCondominioId } = useContext(ListContasPagarContext);

	const validate = () => {
		if (!selectedCondominio) {
			setErros((e) => ({
				...e,
				condominio: 'Selecione um condomínio',
			}));
		} else if (erros?.condominio) {
			setErros((e) => {
				const { condominio, ...rest } = e;
				return rest;
			});
		}
	};
	useEffect(validate, [selectedCondominio]);

	return (
		<Autocomplete
			variant='outlined'
			onChange={(_, newValue) => {
				setSelectedCondominio(newValue);
				setLastCondominioId(newValue?.id);
			}}
			style={{ marginRight: '1em' }}
			size='small'
			value={selectedCondominio}
			options={condominiosData}
			getOptionSelected={(o, v) => {
				return o === v;
			}}
			getOptionLabel={(o) => o?.nome || ''}
			renderInput={(params) => {
				return (
					<TextField
						{...params}
						variant='outlined'
						label='Condomínio'
						required={true}
						error={!!erros?.condominio}
						helperText={erros?.condominio}
					/>
				);
			}}
			disabled={Boolean(despesas?.length)}
			disableClearable
			fullWidth
		/>
	);
};

const FornecedorSelect = () => {
	const { fornecedoresData, fornecedor, setFornecedor, erros, setErros, record } =
		useContext(LancamentoContaPagarContext);
	const validate = () => {
		if (!fornecedor?.id) {
			setErros((e) => ({
				...e,
				fornecedor: 'Selecione um fornecedor',
			}));
		} else if (erros?.fornecedor) {
			setErros((e) => {
				const { fornecedor, ...rest } = e;
				return rest;
			});
		}
	};
	useEffect(validate, [fornecedor]);

	return (
		<Autocomplete
			variant='outlined'
			value={fornecedor}
			onChange={(_, newValue) => {
				setFornecedor(newValue);
			}}
			style={{ marginRight: '1em' }}
			size='small'
			options={fornecedoresData}
			getOptionLabel={(o) => o?.nome || ''}
			renderInput={(params) => {
				return (
					<TextField
						{...params}
						variant='outlined'
						label='Fornecedor'
						required={true}
						error={!!erros?.fornecedor}
						helperText={erros?.fornecedor}
					/>
				);
			}}
			disableClearable
			fullWidth
			disabled={record.retencao_imposto}
		/>
	);
};

const TopBoxFields = () => {
	const { valorTotal, selectedCondominio, condominiosData, fornecedoresData, valorTotalBruto } =
		useContext(LancamentoContaPagarContext);

	return condominiosData.length && selectedCondominio && fornecedoresData.length ? (
		<Box display='flex'>
			<CondominioSelect />
			<FornecedorSelect />
			<TextField
				label='Total Bruto'
				fullWidth
				size='small'
				value={valorTotalBruto}
				inputProps={sanitizeListNumberFormatBRLProps({
					prefix: 'R$ ',
				})}
				disabled={true}
				InputProps={{
					inputComponent: NumberFormatBRL,
				}}
				style={{ marginRight: '1em' }}
			/>
			<TextField
				label='Total Liquido'
				fullWidth
				size='small'
				value={valorTotal}
				inputProps={sanitizeListNumberFormatBRLProps({
					prefix: 'R$ ',
				})}
				disabled={true}
				InputProps={{
					inputComponent: NumberFormatBRL,
				}}
			/>
		</Box>
	) : (
		<></>
	);
};

const cssInvible = {
	width: 0,
	height: 0,
	position: 'absolute',
	top: 0,
	opacity: 0,
	userSelect: 'none',
	zIndex: -1000,
};

const NovaContaAPagarTabList = () => {
	const [aba, setAba] = useState('0');
	return (
		<TabContext value={aba}>
			<Box width={1}>
				<Box display='flex'>
					<TabList value={aba} onChange={(_, newValue) => setAba(newValue)} aria-label=''>
						<Tab {...a11yProps(0)} value='0' label='Despesa' />
						<Tab {...a11yProps(1)} value='1' label='Parcelamento' />
						<Tab {...a11yProps(2)} value='2' label='Infos. Adicionais' />
						<Tab {...a11yProps(3)} value='3' label='Observações' />
					</TabList>
				</Box>
				<TabPanel value={aba} index={aba}>
					<Box css={(aba !== '0' && cssInvible) || {}}>
						<ListDespesas />
					</Box>
					<Box css={(aba !== '1' && cssInvible) || {}}>
						<ListParcelas />
					</Box>
					<Box css={(aba !== '2' && cssInvible) || {}}>
						<BoxInformacoesAdicionaisFields />
					</Box>
					<Box css={(aba !== '3' && cssInvible) || {}}>
						<BoxObservacoes />
					</Box>
				</TabPanel>
			</Box>
		</TabContext>
	);
};

const FormNovaContaAPagar = () => (
	<Box display='flex'>
		<Box flex={1}>
			<TopBoxFields />
			<NovaContaAPagarTabList />
		</Box>
	</Box>
);

export default FormNovaContaAPagar;
