import {
	formatCedentePattern1,
	getDadosFormattingContaCorrente,
	parseCedentePattern1,
} from '../../../utils/formaters_parses';

import DefaultContaCorrenteProps, { defaultComponentesProps } from '../../default/ContaCorrenteProps';
import formatarInitialValuesPadrao from '../../default/ContaCorrenteProps/formatarInitialValues';

import formatadoresPayloadValues from './formatadoresPayloadValues';
import getOptions from './getOptions';
import TabInstrucoesCobrancaRemessa from './TabInstrucoesCobrancaRemessa';
import validacoes from './validacoes';

const formatarInitialValues = ({ instituicaoProps, options, ...initialDados }) => {
	const initialValues = formatarInitialValuesPadrao({ ...initialDados, instituicaoProps, options }) ?? {};
	if (!initialValues.instrucao_1?.id) initialValues.instrucao_1 = options.instrucao_1?.objects?.['10'] ?? null;
	if (!initialValues.instrucao_2?.id) initialValues.instrucao_2 = options.instrucao_2?.objects?.['67'] ?? null;
	return initialValues;
};

const ContaCorrenteProps = {
	...DefaultContaCorrenteProps,
	instituicaoCodigo: '341',
	props: {
		...defaultComponentesProps,
		conta_corrente: {
			...getDadosFormattingContaCorrente(6),
		},
		conta_cedente: {
			parse: parseCedentePattern1(20),
			format: formatCedentePattern1(20),
		},
		protesto_dias: { min: 5, max: 15 },
	},
	getOptions,
	validacoes,
	formatadoresPayloadValues,
	tabContents: {
		...DefaultContaCorrenteProps.tabContents,
		TabInstrucoesCobrancaRemessa,
	},
	formatarInitialValues,
};

export default ContaCorrenteProps;
